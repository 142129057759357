import { forwardRef } from 'react';
import {
    MenuButtonProps,
    MenuItemProps as AriakitMenuItemProps,
    MenuItem as AriakitMenuItem,
    Menu as AriakitMenu,
    MenuProvider,
    MenuButton,
    useMenuStore,
} from '@ariakit/react';
import cn from 'classnames';

import { CircularClose } from '../ui/icons/CircularClose';
import { CircularCaretDown } from '../ui/icons/CircularCaretDown';
import { typography } from '../../styles/typography.css';
import { Check } from '../ui/icons/Check';
import { lightThemeColors } from '../../styles/colors.css';

import * as css from './Menu.css';

export type MenuItemProps = AriakitMenuItemProps & { isSelected?:boolean };

export const MenuItem = forwardRef<HTMLDivElement, MenuItemProps>(
    function MenuItem(props, ref) {
        return (
            <AriakitMenuItem
                ref={ref}
                {...props}
                className={css.menuItem({ disabled: props.disabled })}
            >
                {props?.children}
                {props.isSelected && <Check width={18} />}
            </AriakitMenuItem>
        );
    },
);

export interface MenuProps extends MenuButtonProps<'div'> {
    label:string;
    align?:'left' | 'right';
    buttonSize?:number;
    buttonPadding?:number;
    menuType?:'main' | 'normal';
}

export const Menu = ({
    label,
    children,
    align = 'left',
    buttonSize = 24,
    buttonPadding = 0,
    menuType = 'normal',
    ...props
}:MenuProps) => {
    const menu = useMenuStore();

    const shift = buttonPadding - buttonSize;
    const gutter = -(buttonSize + 24 + buttonPadding);
    const buttonStyle = buttonPadding
        ? {
            padding: buttonPadding,
            margin: -buttonPadding,
            borderBottomLeftRadius: '50%',
        }
        : {};

    return (
        <MenuProvider store={menu}>
            <div className={css.button}>
                <MenuButton {...props} style={buttonStyle}>
                    <CircularCaretDown width={buttonSize} />
                </MenuButton>

                <AriakitMenu
                    className={cn([lightThemeColors, css.menu({ menuType })])}
                    portal
                    shift={shift}
                    gutter={gutter}
                >
                    {!menu.parent && (
                        <div
                            className={css.header({ hasLabel: label?.length > 0 })}
                            style={{ paddingInline: buttonSize }}
                        >
                            {align === 'right' && (
                                <button
                                    onClick={() => {
                                        menu.hide();
                                    }}
                                >
                                    <CircularClose width={buttonSize} />
                                </button>
                            )}
                            <p
                                className={
                                    menuType === 'normal'
                                        ? typography({
                                            size: 'h4',
                                            weight: 'bold',
                                            color: 'dark',
                                            case: 'uppercase',
                                            tracking: 'positiveLarge',
                                            lineHeight: '1',
                                        })
                                        : typography({
                                            size: 'h3',
                                            weight: 'bold',
                                            color: 'dark',
                                        })
                                }
                            >
                                {label}
                            </p>
                            {align === 'left' && (
                                <button
                                    onClick={() => {
                                        menu.hide();
                                    }}
                                >
                                    <CircularClose width={buttonSize} />
                                </button>
                            )}
                        </div>
                    )}
                    {children}
                </AriakitMenu>
            </div>
        </MenuProvider>
    );
};
