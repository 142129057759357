import _ from 'lodash';
import { Suspense,  useCallback,  useEffect, useId, useLayoutEffect, useMemo, useState } from 'react';
import { Canvas, useLoader, useThree } from '@react-three/fiber';
import { Flex, Box } from '@react-three/flex';
import { TextureLoader } from 'three';
import { Plane, Text, OrthographicCamera } from '@react-three/drei';
import useMeasure from 'react-use-measure';
import { CaretLeft as CaretLeftIcon, CaretRight as CaretRightIcon, Play as PlayIcon, Pause as PauseIcon } from '@phosphor-icons/react';

import { typography } from '@common/ui';
import { Analysis, Nil, PositionNumber, SWING_POSITIONS, Segmentation, localize } from '@common';
import ComparisonIcon from './comparison_icon.svg';
import { useVideoTexture } from '../../utils/hooks/useVideoTexture';
import { type CameraAngles, SimplifiedCameraConfig } from '../../utils/types/camera';
import PlaybackScrubber from './PlaybackScrubber/PlaybackScrubber';
import { useVideoPlaybackStore } from './VideoPlayback.state';
import { StatusPaneProps } from './VideoPlayback.types';
import { VIDEO_FPS } from '../../utils/consts';

import * as css from './VideoPlayback.css';

const StatusPane = ({ text = 'No swing selected', width, height }:StatusPaneProps) => {
    return (
        <mesh>
            <planeGeometry args={[width, height]} />
            <meshBasicMaterial color="#040810" />
            <Text
                fontWeight="bold"
                color="#94A3B8"
                fontSize={12}
            >
                {text}
            </Text>
        </mesh>
    );
};


type SceneProps = {
    textureUrl:string;
    maxDuration:number;
    cameraConfig:SimplifiedCameraConfig
    scale?:number;
    cameraAngle:CameraAngles;
    isComparison?:boolean;
    segmentationOffset:SegmentationOffset
}

function Scene({
    textureUrl,
    maxDuration,
    scale = 1,
    cameraAngle = 'face_on',
    cameraConfig,
    isComparison,
    segmentationOffset
}:SceneProps) {
    const { height: videoHeight, width: videoWidth } = cameraConfig.intrinsics[cameraAngle] ?? {};
    const videoTexture = useVideoTexture(textureUrl, {
        start: false,
        preload: 'auto',
        crossOrigin: 'anonymous',
    });

    const svgTexture = useLoader(TextureLoader, ComparisonIcon);

    useLayoutEffect(() => {
        const unsubscribe = useVideoPlaybackStore.subscribe(
            (state) => state.replayProgress,
            (progress) => {
                if(!videoTexture.source?.dataReady) {
                    return;
                }

                const offset = isComparison
                    ? segmentationOffset.difference / VIDEO_FPS
                    : 0;

                const seekTo = (progress * maxDuration) - offset;
                const duration = videoTexture.source.data.duration;

                requestAnimationFrame(() => {
                    if(seekTo < duration) {
                        videoTexture.source.data.currentTime = seekTo;
                    }
                });
            }
        );

        return () => {
            videoTexture.source.data.currentTime = 0;
            unsubscribe();
        };
    }, [videoTexture, isComparison, segmentationOffset, maxDuration]);

    return (
        <group scale={scale}>
            <Plane args={[videoWidth, videoHeight, 1, 1]}>
                <Text
                    fontWeight='bold'
                    color="#fff"
                    anchorX="left"
                    anchorY="top"
                    fontSize={55}
                    position={[-videoWidth / 2 + 100, videoHeight / 2 - 100, 0]}
                >
                    {isComparison
                        ? 'COMPARISON'
                        : 'CURRENT'
                    }
                </Text>
                <Text
                    fontWeight='bold'
                    color="#fff"
                    anchorX="left"
                    anchorY="top"
                    fontSize={80}
                    position={[-videoWidth / 2 + 100, videoHeight / 2 - 180, 0]}
                >
                    {localize(`camera_angle.${cameraAngle}`)}
                </Text>
                <meshBasicMaterial map={videoTexture} />
            </Plane>
            {/* Render the SVG icon */}
            {
                isComparison && (
                    <Plane args={[100, 100]} position={[videoWidth / 2 - 150, videoHeight / 2 - 150, 0]}>
                        <meshBasicMaterial map={svgTexture} />
                    </Plane>)
            }
        </group>
    );
}

type SegmentationOffset = {
    current:number;
    comparison:number;
    difference:number;
}

type RendererProps = {
    activeSwingAnalysis:Analysis;
    comparisonSwingAnalysis:Analysis | Nil;
    segmentationOffset:SegmentationOffset;
    activeCameraAngle:CameraAngles | null
    scale:number;
    cameraConfig:SimplifiedCameraConfig;
    showComparison:boolean;
};

function compareSegmentations(currentSegmentation:Segmentation, comparisonSegmentation?:Segmentation):Record<keyof Segmentation, SegmentationOffset> {
    // Filter out any positions where either value is null
    const validPositions = _.pickBy(currentSegmentation, (value, key) =>
        _.isNumber(value) && _.isNumber(comparisonSegmentation?.[key as keyof Segmentation])
    ) as Record<keyof Segmentation, number>;

    // Create the comparison object for valid positions
    return _.mapValues(validPositions, (currentValue, key) => {
        const comparisonValue = comparisonSegmentation![key as `P${PositionNumber}`];
        return {
            current: currentValue,
            comparison: comparisonValue,
            difference: (currentValue - comparisonValue)
        };
    });
}

function Renderer({
    activeSwingAnalysis,
    comparisonSwingAnalysis,
    segmentationOffset,
    activeCameraAngle,
    scale = 1,
    cameraConfig,
    showComparison,
}:RendererProps) {
    const id = useId()
    const { height: canvasHeight, width: canvasWidth } = useThree((state) => state.viewport);

    const cameraAngles:CameraAngles[] = showComparison
        ? ['face_on', 'face_on', 'down_the_line', 'down_the_line']
        : ['back', 'down_the_line', 'face_on', 'trail_front'];

    const videos = activeSwingAnalysis.data.videos!;
    const comparisonVideos = comparisonSwingAnalysis?.data.videos;
    const isFullscreen = Boolean(activeCameraAngle) && !showComparison;

    const maxTotalFrames = Math.max(
        ..._.compact(
            _.map(
                _.flatten([videos,
                    showComparison && comparisonVideos
                        ? comparisonVideos
                        : []]
                ),
                video => video.metadata.totalFrames
            )
        )
    );

    const effectiveCameraAngles = isFullscreen
        ? [activeCameraAngle]
        : cameraAngles;

    return (
        <Flex
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="space-evenly"
            alignItems="flex-start"
            height={canvasHeight}
            width={canvasWidth}
            position={[-canvasWidth / 2, canvasHeight / 2, -1000]}
            plane="xy"
        >
            {_.map(effectiveCameraAngles, (angle, index) => {
                const isComparison = showComparison && index % 2 === 1;
                const videosForAngle = isComparison
                    ? comparisonVideos
                    : videos;
                const isValid = Boolean(videosForAngle?.[index]);
                const textureUrl = _.find(videosForAngle!, (video) => video.name === angle)?.url ?? '';
                const paneWidth = isFullscreen
                    ? canvasWidth
                    : canvasWidth / 2;
                const paneHeight = isFullscreen
                    ? canvasHeight
                    : canvasHeight / 2;

                return (
                    <Box
                        key={`${id}_${index}`}
                        height={paneHeight}
                        width={paneWidth}
                        centerAnchor
                    >
                        <Suspense fallback={<StatusPane text="Loading video" height={paneHeight} width={paneWidth} />}>
                            {isValid
                                ? (
                                    <Scene
                                        textureUrl={textureUrl}
                                        maxDuration={maxTotalFrames / VIDEO_FPS}
                                        cameraAngle={angle!}
                                        cameraConfig={cameraConfig}
                                        scale={scale}
                                        isComparison={isComparison}
                                        segmentationOffset={segmentationOffset}
                                    />
                                )
                                : (
                                    <StatusPane text="No swing selected" height={paneHeight} width={paneWidth} />
                                )}
                        </Suspense>
                    </Box>
                );
            })}
        </Flex>
    );
}

const VIDEO_DIMENSIONS = {
    width: 510,
    height: 428,
    sourceWidth: 2464,
    sourceHeight: 2064,
};

type VideoPlaybackProps = {
  activeSwingAnalysis:Analysis;
  comparisonSwingAnalysis?:Analysis | Nil;
  cameraConfig?:SimplifiedCameraConfig;
  displayMode:'comparison' | 'playback';
}

export function VideoPlayback({
    activeSwingAnalysis,
    comparisonSwingAnalysis,
    cameraConfig,
    displayMode
}:VideoPlaybackProps) {
    const [syncedPosition, setSyncedPosition] = useState<keyof Segmentation>('P1');
    const [
        activeCameraAngle,
        autoPlaying,
        speed,
        travelDistance,
        handlerMotionValue,
    ] = useVideoPlaybackStore((state) => [
        state.activeCameraAngle,
        state.autoPlaying,
        state.playbackSpeed,
        state.travelDistance,
        state.handlerMotionValue,
    ]);
    const swingPositions = _.map(SWING_POSITIONS, pos => pos.toUpperCase());
    const isFullscreen = Boolean(activeCameraAngle) && displayMode === 'playback';

    const [ref, bounds] = useMeasure();
    const videosPerRow = isFullscreen
        ? 1
        : 2;

    const totalWidthNeeded = VIDEO_DIMENSIONS.sourceWidth * videosPerRow;

    const scale = bounds.width / totalWidthNeeded;

    const heightRatio = VIDEO_DIMENSIONS.sourceHeight / VIDEO_DIMENSIONS.sourceWidth;

    // TODO: Not good.
    // 73vh is the most height we can allow for the videos so the controls are still visible
    // width is calculated based on the video aspect ratio and the height of 73
    const domHeight = '73vh';
    const domWidth = `${heightRatio * 73}vw`;

    useEffect(() => {
        useVideoPlaybackStore.setState({
            replayProgress: 0,
            playbackSpeed: 1,
            autoPlaying: false
        });
        handlerMotionValue.set(0);
    }, [displayMode, handlerMotionValue]);

    const segmentationDiff = useMemo(
        () => compareSegmentations(activeSwingAnalysis.data.analysis!.segmentation, comparisonSwingAnalysis?.data.analysis?.segmentation),
        [activeSwingAnalysis, comparisonSwingAnalysis]
    );

    const validPositions = _.keys(segmentationDiff);
    const segmentationOffset = segmentationDiff[syncedPosition];

    const handleFrameByFrame = useCallback((direction:1|-1) => {
        useVideoPlaybackStore.setState(state => {
            const nextProgress = state.replayProgress + (0.01 * Math.sign(direction));
            const replayProgress = Math.min(Math.max(nextProgress, 0), 1);

            state.handlerMotionValue.set(replayProgress * state.travelDistance);

            return {
                autoPlaying: false,
                replayProgress
            };
        });
    }, []);


    const handleJumpToPosition = useCallback((position:keyof Segmentation) => {
        const segmentation = activeSwingAnalysis.data.analysis?.segmentation;
        const totalFrames = activeSwingAnalysis.data?.videos?.[0]?.metadata?.totalFrames;

        if(!segmentation || !segmentation[position]) {
            console.error(`Can't jump to position ${position}: Missing segmentation.`);
            return;
        }

        if(!totalFrames) {
            console.error(`Can't jump to position ${position}: Missing video metadata.`);
            return;
        }

        const progress = ~~segmentation[position] / totalFrames;

        // First stop auto-playing
        useVideoPlaybackStore.setState({ autoPlaying: false });

        // Make sure to set the motion value afterwards
        requestAnimationFrame(() => handlerMotionValue.set(progress * travelDistance));

    }, [activeSwingAnalysis, handlerMotionValue, travelDistance]);


    const handleTogglingAutoPlay = useCallback(() => {
        useVideoPlaybackStore.setState(prev => ({autoPlaying: !prev.autoPlaying}));
    }, []);


    if(!activeSwingAnalysis.data.videos || !cameraConfig) {
        return <div className={css.emptyState}></div>;
    }

    return (
        <>
            <Canvas
                ref={ref}
                onClick={handleTogglingAutoPlay}
                flat
                dpr={[1, 2]}
                shadows={false}
                style={{
                    borderRadius: 8,
                    position: 'relative',
                    width: `min(${domWidth}, ${travelDistance}px)`,
                    minWidth: travelDistance,
                    height: domHeight,
                }}
            >
                <OrthographicCamera makeDefault far={100000} near={0.00001} zoom={1}>
                    <Renderer
                        activeSwingAnalysis={activeSwingAnalysis}
                        comparisonSwingAnalysis={comparisonSwingAnalysis}
                        scale={scale}
                        segmentationOffset={segmentationOffset}
                        activeCameraAngle={activeCameraAngle}
                        cameraConfig={cameraConfig}
                        showComparison={displayMode === 'comparison'}
                    />
                </OrthographicCamera>
            </Canvas>
            <div className={css.controlsWrapper}>
                <div className={css.controls}>
                    <button
                        className={css.button({ isActive: false })}
                        onClick={() => handleFrameByFrame(-1)}
                    >
                        <CaretLeftIcon size={20} />
                    </button>
                    <button
                        className={css.button({ isActive: autoPlaying })}
                        onClick={handleTogglingAutoPlay}
                    >
                        {autoPlaying
                            ? <PauseIcon size={18} />
                            : <PlayIcon size={18} />}
                    </button>
                    <button
                        className={css.button({ isActive: false })}
                        onClick={() => handleFrameByFrame(1)}
                    >
                        <CaretRightIcon size={20} />
                    </button>
                </div>
                {displayMode === 'playback' && (
                    <div className={css.controls}>
                        {_.at(SWING_POSITIONS, [0, 1, 3, 6])
                            .map((pos) => pos.toUpperCase())
                            .map((pos) => {
                                return (
                                    <button
                                        key={pos}
                                        className={css.button({ isActive: false })}
                                        disabled={false}
                                        onClick={() =>
                                            handleJumpToPosition(pos as keyof Segmentation)
                                        }
                                    >
                                        <span className={typography({ variant: 'h3' })}>{pos}</span>
                                    </button>
                                );
                            })}
                    </div>
                )}
                {displayMode === 'comparison' && (
                    <div className={css.controls}>
                        {_.map(swingPositions, (pos) => {
                            const isValid = _.includes(validPositions, pos);
                            return (
                                <button
                                    key={pos}
                                    className={css.button({ isActive: pos === syncedPosition })}
                                    disabled={!isValid}
                                    onClick={() =>
                                        isValid && setSyncedPosition(pos as keyof Segmentation)
                                    }
                                >
                                    <span
                                        style={{
                                            opacity: isValid
                                                ? 1
                                                : 1 / 3,
                                        }}
                                        className={typography({ variant: 'h3' })}
                                    >
                                        {pos}
                                    </span>
                                </button>
                            );
                        })}
                    </div>
                )}
            </div>
            <PlaybackScrubber
                segmentationOffset={segmentationOffset}
                speed={speed}
                showComparison={displayMode === 'comparison'}
                activeSwingAnalysis={activeSwingAnalysis}
                comparisonSwingAnalysis={comparisonSwingAnalysis}
            />
        </>
    );
}
