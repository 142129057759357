import _ from 'lodash';

import {Segmentation, Analysis, SwingPosition, L10n, UIParameter, getLocalizedSwingPositionName } from '@common';
import { typography } from '@common/ui';
import { SimplifiedCameraConfig } from '../../../../utils/types/camera';
import { UIComponentProps } from '../../UIComponent.types';
import VideoWithSkeleton from '../../../VideoWithSkeleton';
import UIComponent from '../../UIComponent';


import * as css from './SwingFoundationsPositionRenderer.css';

interface SwingFoundationsPositionRendererProps {
    readonly position:SwingPosition;
    readonly analysis:Analysis|null;
    readonly parameterProps:UIComponentProps<UIParameter>[];
    readonly name:L10n|null;
    readonly cameraConfig?:SimplifiedCameraConfig|null;
    readonly size?:'small'|'medium'|'large';
}

export function SwingFoundationsPositionRenderer({
    cameraConfig,
    analysis,
    parameterProps,
    size,
    position,
}:SwingFoundationsPositionRendererProps) {

    // get parameters at the given position
    // this should work for any of the 10 positions, not just the swing foundations (p1, p2, p4 and p7).

    const videos = analysis?.data?.videos || [];
    const uppercasePosition:keyof Segmentation
        = position.toUpperCase() as Uppercase<SwingPosition>;
    const frame = analysis?.data.analysis?.segmentation[uppercasePosition] || 0;

    // layout and style
    const hasNoData = !parameterProps.length || _.isEmpty(analysis?.data);
    const showVideoFrame = !!cameraConfig;
    const isSmallGap
        = size === 'small'
        && (!showVideoFrame || hasNoData)
        && parameterProps?.length > 12;
    
    const rootFlexDirection
        = size === 'medium'
        || (showVideoFrame && !hasNoData)
            ? 'row'
            : 'column';
    const childFlexDirection
        = hasNoData
        && size === 'medium'
            ? 'column'
            : 'row';
        
    const cellsInARow = showVideoFrame && parameterProps.length <= 5
        ? 1
        : showVideoFrame
            ? 2
            : 3;

    const title = getLocalizedSwingPositionName(position);
    
    return (
        <div className={css.root({ size, flexDirection: rootFlexDirection, isSmallGap })}>
            <div className={css.left}>
                <div
                    className={css.headingsWrapper({
                        flexDirection: childFlexDirection
                    })}
                >
                    <div className={css.headings({ isTranslucent: hasNoData })}>
                        <p className={typography({variant: 'h2'})}>
                            {title}
                        </p>
                        <p className={typography({variant: 'h3'})}>
                            Swing Foundations
                        </p>
                    </div>
                    {hasNoData && (
                        <div className={css.noDataMessage}>
                            <p className={css.noDataMessageText({ size })}>
                                No data available
                            </p>
                        </div>
                    )}
                </div>
                {showVideoFrame && !hasNoData && (
                    <div className={css.videoLayout}>
                        <VideoWithSkeleton
                            key={position}
                            skeletonOverlay={false}
                            layout="stacked"
                            videos={videos}
                            cameraConfig={cameraConfig}
                            floorFrame={frame}
                            cameraAngles={
                                size === 'large'
                                    ? ['face_on', 'down_the_line']
                                    : position === 'p2'
                                        ? ['down_the_line']
                                        : ['face_on']
                            }
                        />
                    </div>
                )}
            </div>
            <div className={css.dataWrapper({ isTranslucent: hasNoData, cellsInARow })}>
                {
                    _(parameterProps)
                        .take(8)
                        .map(props => (
                            /** We need to properly unify how parameters are rendered before finishing this part */
                            <UIComponent
                                key={props.currentNode.id}
                                {...props}
                            />
                        ))
                        .value()
                }
            </div>
        </div>
    );
}
