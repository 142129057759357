import { PlusMinus } from '@phosphor-icons/react';
import React, { useMemo, type MouseEvent } from 'react';
import _ from 'lodash';

import type { CorridorParameterMargins, Nil, Unit } from '@common';
import { isFiniteNumber, unitConversionMap } from '@common';
import { SelectBox } from '../SelectBox/SelectBox';
import { CorridorRenderer, corridorRendererCSS } from './CorridorRenderer';
import { colors } from '../../colors';

import * as defaultCSS from './ParameterRenderer.css';

export const parameterRendererCSS:Partial<typeof defaultCSS> = defaultCSS;

export type ParameterRendererProps = {
    title:string;
    unit:Unit;
    value:number | Nil;
    comparisonValue?:number | Nil;
    corridorParameterMargins?:CorridorParameterMargins | Nil;
    statistics?:{ mean:number | Nil; std:number | Nil };
    isSelected?:boolean;
    isFixedWidth?:boolean;
    onClick?:(event:MouseEvent) => void;
    cssOverrides?:typeof parameterRendererCSS & {
        corridor?:typeof corridorRendererCSS;
    };
    rootStyle?:React.CSSProperties;
};

export function ParameterRenderer({
    title,
    unit,
    value,
    comparisonValue,
    corridorParameterMargins,
    statistics,
    isSelected,
    isFixedWidth,
    onClick,
    cssOverrides,
    rootStyle,
}:ParameterRendererProps) {
    const converter = unitConversionMap[unit];
    
    const converted = converter(value);
    const comparisonConverted = converter(comparisonValue);
    const displayValue = isFiniteNumber(converted.value)
        ? String(converted.value)
        : '— ';
    const displayUnit = converted.symbol;

    const comparisonDisplayValue = isFiniteNumber(comparisonConverted.value)
        ? String(comparisonConverted.value)
        : undefined;

    const statusColor = getStatusIndicatorColor(
        converted.value,
        corridorParameterMargins,
    );

    const isSelectable = typeof isSelected !== 'undefined'; // if isSelected is present, it is selectable
    const isClickable = typeof onClick === 'function'; // if onClick is a function, parameter is a clickable button

    const css = { ...defaultCSS, ...cssOverrides };

    const renderStatistics = useMemo(() => {
        const [convertedMean, convertedStd] = _.values(statistics).map((stat) => {
            const converted = converter(stat).value;
            return _.isNumber(converted)
                ? converted
                : '-';
        });

        return (
            <div className={css.stats({ isSelectable })}>
                <span className={css.statsText}>
                    {convertedMean}
                </span>
                <span className={css.std}>
                    <PlusMinus className={css.statsIcon} width={12} height={10} color={colors.bluegray[600]} />
                    <span className={css.statsText}>
                        {convertedStd}
                    </span>
                </span>
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statistics, isSelected]);

    const displayStyle = isFixedWidth
        ? getDynamicWidthStyle(displayValue, displayUnit, comparisonDisplayValue, css)
        : undefined;

    return (
        <div
            className={css.root({ isSelectable, isFixedWidth })}
            style={rootStyle}
            role={isClickable
                ? 'button'
                : undefined}
            onClick={(event) => onClick?.(event)}
        >
            <div className={css.cell({ isSelectable })}>
                {isSelectable && (
                    <div className={css.selectArea}>
                        <SelectBox selected={isSelected} />
                    </div>
                )}
                <div className={css.cellLayout}>
                    <div className={css.label}>
                        <span className={css.labelText}>{title}</span>
                        <div className={css.indicator({ status: statusColor })} />
                    </div>
                    <div className={css.content}>
                        <span
                            className={css.value({ status: statusColor })}
                            style={displayStyle}
                        >
                            <span className={css.displayValue}>{displayValue}</span>
                            <span className={css.displayUnit}>{displayUnit}</span>
                            { comparisonDisplayValue &&
                                <span className={css.comparisonDisplayValue}>
                                    {COMPARISON_VALUE_PREFIX}
                                    {comparisonDisplayValue}
                                    {COMPARISON_VALUE_POSTFIX}
                                    <span className={css.displayUnit}>{displayUnit}</span>
                                </span>
                            }
                        </span>

                        {statistics && renderStatistics}
                    </div>
                </div>
            </div>

            {corridorParameterMargins && (
                <CorridorRenderer
                    corridorParameterMargins={corridorParameterMargins}
                    value={converted.value}
                    cssOverrides={css.corridor}
                />
            )}
        </div>
    );
}
function getDynamicWidthStyle(displayValue:string, displayUnit:string, comparisonDisplayValue:string|undefined, css:typeof defaultCSS) {
    const displayWidth = getTextWidth(
        displayValue + displayUnit,
        {
            fontSize: css.DISPLAY_VALUE_FONT_SIZE,
            fontWeight: '600',
        }
    );
    const comparisonDisplayWidth = comparisonDisplayValue
        ? getTextWidth(
            COMPARISON_VALUE_PREFIX + comparisonDisplayValue + displayUnit + COMPARISON_VALUE_POSTFIX,
            {
                fontSize: css.COMPARISON_DISPLAY_UNIT_FONT_SIZE,
                fontWeight: '600',
            }
        )
        : 0;
    const fontSize = _.clamp(3000 / (displayWidth + comparisonDisplayWidth), 8, 30);
    
    return { fontSize };
}

function getTextWidth(
    text:string,
    style:{
        fontSize?:number;
        font?:string;
        fontWeight?:string;
    } = {},
) {
    // Move this function to some utilities folder if its needed elsewhere, DO NOT copy it to other files

    const element = document.createElement('p');
    element.style.fontSize = `${style?.fontSize ?? 16}px`;
    element.style.font = style.font ?? '"Inter", system-ui, sans-serif';
    element.style.fontWeight = style.fontWeight ?? 'normal';
    element.style.display = 'inline-block';
    element.style.position = 'fixed';
    element.style.visibility = 'hidden';
    element.innerText = text;
    document.body.appendChild(element);

    const width = element.clientWidth;

    element.remove();

    return width;
}

function getStatusIndicatorColor(
    value:number | Nil,
    corridorParameterMargins:CorridorParameterMargins | Nil,
):'red' | 'yellow' | 'green' | 'white' {
    if(!isFiniteNumber(value) || !corridorParameterMargins) return 'white';

    if(
        corridorParameterMargins.inner_min < value &&
        value < corridorParameterMargins.inner_max
    )
        return 'green';
    else if(
        corridorParameterMargins.outer_min < value &&
        value < corridorParameterMargins.outer_max
    )
        return 'yellow';
    else return 'red';
}

const COMPARISON_VALUE_PREFIX = '';
const COMPARISON_VALUE_POSTFIX = '';

