import { motion } from 'motion/react';
import { typography } from '../../styles/typography.css';
import * as css from './ClubTable.css';
import { Clubs } from '../../state/globalStore';
import _ from 'lodash';
import { Club } from '@common';

export function ClubTable({
    clubs,
    selectedClubs,
    onClubClick,
}:{
    clubs:Clubs;
    selectedClubs:Club[];
    onClubClick:(club:Club) => void;
}) {
    return (
        <motion.div
            className={css.table}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {_.map(clubs, (clubType) => (
                <div key={clubType.type_name} className={css.clubType}>
                    <p
                        className={typography({
                            size: 'h2',
                            weight: 'bold',
                            color: 'dark',
                        })}
                    >
                        {clubType.type_name}
                    </p>
                    <div className={css.clubs}> {
                        _(clubType?.clubs)
                            .sortBy(x => x.id)
                            .map(club => {
                                const isSelected = _.some(
                                    selectedClubs,
                                    (selectedClub) => selectedClub.id === club.id,
                                );

                                return (
                                    <button
                                        key={club.id}
                                        className={css.club({
                                            isSelected,
                                        })}
                                        onClick={() => onClubClick(club)}
                                    >
                                        <p
                                            className={typography({
                                                size: 'h3',
                                                color: 'dark',
                                                weight: 'bold',
                                            })}
                                        >
                                            {club.long_name}
                                        </p>
                                        <div
                                            className={css.short({
                                                isSelected,
                                            })}
                                        >
                                            <p
                                                className={typography({
                                                    size: 'h3',
                                                    weight: 'bold',
                                                })}
                                            >
                                                {club.short_name}
                                            </p>
                                        </div>
                                    </button>
                                );
                            })
                            .value()
                    }
                    </div>
                </div>
            ))}
        </motion.div>
    );
}
