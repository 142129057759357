export function ThreeD({
    width = 16,
    height,
    color = '#3B82F6',
}:{
    width?:number;
    height?:number;
    color?:string;
}) {
    const w = width;
    const h = height ?? width;
    return (
        <svg
            width={w}
            height={h}
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ color }}
        >
            <path
                d="M11.5 8.5a2 2 0 0 1-2 2H9v-4h.5a2 2 0 0 1 2 2Zm3-4.5v9a1 1 0 0 1-1 1h-11a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1ZM7 9.5a2 2 0 0 0-1.144-1.808L6.9 6.3a.5.5 0 0 0-.4-.8H4a.5.5 0 1 0 0 1h1.5l-.9 1.2a.5.5 0 0 0 .4.8 1 1 0 1 1-.666 1.746.5.5 0 1 0-.668.745A2 2 0 0 0 7 9.5Zm5.5-1a3.003 3.003 0 0 0-3-3h-1A.5.5 0 0 0 8 6v5a.5.5 0 0 0 .5.5h1a3.003 3.003 0 0 0 3-3Z"
                fill="currentColor"
            />
        </svg>
    );
}
