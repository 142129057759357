import {
    motion,
    useTransform,
    useMotionValueEvent,
    useDragControls,
} from 'motion/react';
import {
    useCallback,
    useLayoutEffect,
    useMemo,
    useRef,
    type PointerEvent,
} from 'react';
import _ from 'lodash';

import type { Analysis, Nil } from '@common';
import { useResizeObserver } from '../../../utils/hooks/useResizeObserver';
import { useVideoPlaybackStore } from '../../VideoPlayback/VideoPlayback.state';
import { ScrubberHandle } from '../../ui/icons/ScrubberHandle';
import { calculateFrameRatios } from '../../../utils/calculateFrameRatios';
import { CAMERA_FPS } from '../../../utils/consts';
import { generatePositionTicks, getSwingFramesInfo } from './helpers';

import * as css from './PlaybackScrubber.css';

type PlaybackScrubberProps = {
    speed:number;
    activeSwingAnalysis:Analysis;
    comparisonSwingAnalysis:Analysis|Nil;
    segmentationOffset:SegmentationOffset;
    showComparison:boolean;
}

type SegmentationOffset = {
    current:number;
    comparison:number;
    difference:number;
}

export default function PlaybackScrubber({
    speed,
    activeSwingAnalysis,
    comparisonSwingAnalysis,
    segmentationOffset,
    showComparison,
}:PlaybackScrubberProps) {
    const ref = useRef<HTMLDivElement>(null);
    const programmaticallyDragging = useRef(false);
    const raf = useRef<number>();
    const dragControls = useDragControls();
    const [handleX, travelDistance] = useVideoPlaybackStore(state => [state.handlerMotionValue, state.travelDistance]);
    
    const activeSwingInfo = getSwingFramesInfo(activeSwingAnalysis);
    const comparisonSwingInfo = getSwingFramesInfo(comparisonSwingAnalysis);

    const totalFrames = showComparison
        ? Math.max(activeSwingInfo.totalFrames ?? 0, comparisonSwingInfo.totalFrames ?? 0)
        : activeSwingInfo.totalFrames ?? 0;

    const playbackTrackProgress = useTransform(handleX, [0, travelDistance], [0, 1]);

    const [activeSwingFrameRatios, comparisonSwingFrameRatios] = useMemo(() => {
        const activeSegmentation = _.values(activeSwingInfo?.segmentation ?? {});
        const comparisonSegmentation = _.values(comparisonSwingInfo?.segmentation ?? {});

        return [
            totalFrames
                ? calculateFrameRatios(activeSegmentation, totalFrames)
                : {},
            comparisonSwingInfo && totalFrames && segmentationOffset
                ? calculateFrameRatios(comparisonSegmentation, totalFrames, segmentationOffset.difference)
                : {}
        ];
    }, [activeSwingInfo, comparisonSwingInfo, totalFrames, segmentationOffset]);

    /**
     * Colored ticks on the scrubber
     */
    const ticks = generatePositionTicks(
        activeSwingFrameRatios,
        showComparison
            ? comparisonSwingFrameRatios
            : null
    );

    useResizeObserver({
        ref,
        box: 'border-box',
        onResize(size) {
            useVideoPlaybackStore.setState({travelDistance: size.width});
        },
    });

    // Monitor when user scrubs the scrubber
    useMotionValueEvent(handleX, 'change', (latest) => {
        if(programmaticallyDragging.current) {
            return;
        }

        const progress = latest / travelDistance;
        const currentFrame = Math.floor(progress * totalFrames);

        requestAnimationFrame(() => {
            useVideoPlaybackStore.setState({replayProgress: ~~currentFrame / totalFrames});
        });
    });

    // This layout effect handles running the rAF loop when video is auto-playing.
    useLayoutEffect(() => {
        const VIDEO_DURATION = totalFrames / (CAMERA_FPS * speed);
        const INCREMENT_PER_FRAME = 1 / (60 * VIDEO_DURATION);

        const unsubscribe = useVideoPlaybackStore.subscribe(
            (state) => [state.autoPlaying],
            ([autoPlaying]) => {
                if(autoPlaying) {
                    programmaticallyDragging.current = true;
                    raf.current = requestAnimationFrame(() => {
                        useVideoPlaybackStore.setState(prev => {
                            const nextProgress = prev.replayProgress + INCREMENT_PER_FRAME;
                            // Make sure to loop the video
                            const replayProgress = nextProgress >= 1
                                ? 0
                                : nextProgress;

                            // Move the handle
                            handleX.set(replayProgress * travelDistance);

                            return {
                                replayProgress
                            };
                        });
                    });
                } else {
                    programmaticallyDragging.current = false;
                }
            },
        );

        return () => {
            if(raf.current) {
                cancelAnimationFrame(raf.current);
            }
            unsubscribe();
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [travelDistance, handleX, speed]);
    

    const handlePointerDown = useCallback(
        (e:PointerEvent<HTMLElement>) => {
            // Stop playing
            useVideoPlaybackStore.setState({autoPlaying: false});
            // Snap to cursor
            requestAnimationFrame(() => dragControls.start(e, { snapToCursor: true }));
        },
        [dragControls],
    );

    return (
        <div className={css.root}>
            <div className={css.scrubberWrap}>
                <motion.div
                    className={css.positioner}
                    ref={ref}
                    onPointerDown={handlePointerDown}
                >
                    <motion.div
                        className={css.handle}
                        drag="x"
                        dragConstraints={{ left: 0, right: travelDistance }}
                        dragElastic={false}
                        dragMomentum={false}
                        dragDirectionLock
                        dragControls={dragControls}
                        onDragStart={() => useVideoPlaybackStore.setState({autoPlaying: false})}
                        style={{ x: handleX }}
                    >
                        <ScrubberHandle />
                    </motion.div>
                    <div className={css.scrubber}>
                        <div className={css.scrubberContent}>
                            {ticks}
                        </div>
                    </div>
                    <div className={css.progressMask}>
                        <motion.div
                            className={css.progress}
                            style={{ scaleX: playbackTrackProgress }}
                        />
                    </div>
                </motion.div>
            </div>
        </div>
    );
}
