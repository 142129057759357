export function CircularPlus({
    width = 24,
    height,
    color = '#3B82F6',
}:{
    width?:number;
    height?:number;
    color?:string;
}) {
    const w = width;
    const h = height ?? width;

    return (
        <svg
            width={w}
            height={h}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 2.25A9.75 9.75 0 1 0 21.75 12 9.762 9.762 0 0 0 12 2.25Zm3.75 10.5h-3v3a.75.75 0 1 1-1.5 0v-3h-3a.75.75 0 1 1 0-1.5h3v-3a.75.75 0 1 1 1.5 0v3h3a.75.75 0 1 1 0 1.5Z"
                fill={color}
            />
        </svg>
    );
}
