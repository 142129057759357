import { SupabaseClient } from '@supabase/supabase-js';
import { UserSettingsStore } from '../../state/userSettingsStore';
import _ from 'lodash';


export const getUserSettingsStore = async(supabase:SupabaseClient) => {
    const {
        data: { user },
    } = await supabase.auth.getUser();

    const { data: settings, error } = await supabase
        .from('user_settings')
        .select('settings')
        .eq('user_id', user?.id)
        .single();

    if(error) throw error;

    return (settings?.settings ?? null) as UserSettingsStore|null;
};
