export type Frame = { [keypointId:string]:[number, number, number] };
export type Skeleton = Frame[];

export function transformSkeletonToFramesFirst(skeleton:{
    [keypointId:string]:[number[], number[], number[]];
}):Skeleton {
    const keypointIds = Object.keys(skeleton);
    const numFrames = skeleton[keypointIds[0]][0].length;

    const transformedSkeleton:Skeleton = [];

    for(let frameIndex = 0; frameIndex < numFrames; frameIndex++) {
        const frame:Frame = {};

        for(const keypointId of keypointIds) {
            const [xValues, yValues, zValues] = skeleton[keypointId];

            const x = xValues[frameIndex];
            const y = yValues[frameIndex];
            const z = zValues[frameIndex];

            frame[keypointId] = [x, y, z];
        }

        transformedSkeleton.push(frame);
    }

    return transformedSkeleton;
}
