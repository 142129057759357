import type { ReactNode } from 'react';
import type { ComponentPropsWithoutRef } from 'react';

import HStack from '../HStack';
import { typography } from '../../../styles/typography.css';

import { type ButtonVariants } from './Button.css';
import * as css from './Button.css';

interface ButtonGroupProps extends ComponentPropsWithoutRef<'button'> {
    children:ReactNode;
    variant?:ButtonVariants;
    leftSection?:ReactNode; // for item on left of text
    rightSection?:ReactNode; // for item on right of text
}

export default function Button({
    children,
    variant = 'primary',
    leftSection,
    rightSection,
    ...props
}:ButtonGroupProps) {
    return (
        <button className={css.button({ variant })} {...props}>
            <span
                className={typography({ size: 'h3', weight: 'bold' })}
                style={{ width: '100%' }}
            >
                <HStack className={css.container}>
                    {leftSection && (
                        <span className={css.leftSection}>{leftSection}</span>
                    )}
                    {children ?? 'Testing'}
                    {rightSection && (
                        <span className={css.rightSection}>{rightSection}</span>
                    )}
                </HStack>
            </span>
        </button>
    );
}
