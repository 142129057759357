import { PropTypes } from './TopBanner.types';
import * as css from './TopBanner.css';

const TopBanner = ({ message, isVisible }:PropTypes) => {
    const bannerClass = `${css.banner} ${isVisible
        ? css.bannerVisible
        : ''}`;
    return (
        <div className={bannerClass}>
            <span className={css.bannerText}>{message}</span>
        </div>
    );
};

export default TopBanner;
