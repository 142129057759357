import _ from 'lodash';
import UIComponent from '../../UIComponent';
import { ImplementationOf } from '../../UIComponent.types';
import * as css from './Page.css';

export const Page:ImplementationOf<'page'>
= ({ children, node: { metadata: { duration } } }) => {

    // TODO: do some css animation wizardry
    const durationInMilliseconds = Number(duration) > 0
        ? Number(duration)
        : 5000;

    const MAX_COMPONENTS_PER_PAGE = 4
    
    return (
        <div className={css.page}>
            {_.take(children, MAX_COMPONENTS_PER_PAGE).map(child => (
                <div key={child.currentNode.id} className={css.pageChild}>
                    <UIComponent {...child} />
                </div>
            ))}
        </div>
    );
};
