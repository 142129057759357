import { useContext } from 'react';
import { BoothSessionContext } from '../contexts/BoothSessionContext';

export const useBoothSession = () => {
    const context = useContext(BoothSessionContext);
    if(!context) {
        throw new Error('Missing BoothSessionContext.Provider');
    }

    return context;
};
