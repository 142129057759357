/* eslint-disable no-restricted-syntax */
export const formatDeg = (value:number):string => {
    const parsed = parseInt(String(value));
    return isNaN(parsed)
        ? '–'
        : `${parsed}°`;
};

export const thousandSep = (value:number):string => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export function lhsOfFirst(str:string, subString:string, returnWholeStringOnDefault = true):string {
    if(!str || !subString)
        return returnWholeStringOnDefault
            ? str
            : '';
    const index = str.indexOf(subString);
    return index === -1
        ? returnWholeStringOnDefault
            ? str
            : ''
        : str.slice(0, index);
}

export function lhsOfLast(str:string, subString:string, returnWholeStringOnDefault = true):string {
    if(!str || !subString)
        return returnWholeStringOnDefault
            ? str
            : '';
    
    const index = str.lastIndexOf(subString);
    return index === -1
        ? returnWholeStringOnDefault
            ? str
            : ''
        : str.slice(0, index);
}

export function rhsOfFirst(str:string, subString:string, returnWholeStringOnDefault = true):string {
    if(!str || !subString)
        return returnWholeStringOnDefault
            ? str
            : '';
    
    const index = str.indexOf(subString);
    return index === -1
        ? returnWholeStringOnDefault
            ? str
            : ''
        : str.slice(index + subString.length);
}

export function rhsOfLast(str:string, subString:string, returnWholeStringOnDefault = true):string {
    if(!str || !subString)
        return returnWholeStringOnDefault
            ? str
            : '';
    
    const index = str.lastIndexOf(subString);
    return index === -1
        ? returnWholeStringOnDefault
            ? str
            : ''
        : str.slice(index + subString.length);
}


export function splitInTwo(
    str:string,
    separator:string,
    options:SplitInTwoOptions = {
        byDefaultPutValueOn: 'neither_side',
        direction: 'left_to_right',
        startIndex: 0,
    },
):[lhs:string, rhs:string] {
    const index = options?.direction === 'right_to_left'
        ? str.lastIndexOf(separator, options?.startIndex ?? str.length)
        : str.indexOf(separator, options?.startIndex ?? 0);
    
    if(index === -1) {
        if(options?.default)
            return options.default;
        switch(options?.byDefaultPutValueOn) {
            case 'left_side':
                return [str, ''];
            case 'right_side':
                return ['', str];
            case 'both_sides':
                return [str, str];
            default:
                return ['', ''];
        }
    }
    return [
        str.slice(0, index),
        str.slice(index + separator.length),
    ];
}

export interface SplitInTwoOptions {
    readonly direction?:'left_to_right'|'right_to_left';
    readonly startIndex?:number;
    readonly byDefaultPutValueOn?:'left_side'|'right_side'|'both_sides'|'neither_side';
    readonly default?:[string, string];
}
