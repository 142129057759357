// cspell: words ndarray coeffs extrinsics

import _ from 'lodash';
import {
    CameraConfig,
    CamerasInWorld,
    Extrinsic,
    Intrinsics,
    SimplifiedCameraConfig,
} from '../../../utils/types/camera';

/**
 * Converts a detailed camera configuration into a simplified format where configurations
 * are accessed directly by camera names.
 */
export const convertToSimplifiedConfig = (
    configFromServer:CameraConfig,
):SimplifiedCameraConfig => {
    const cameraIDToName = configFromServer.camera_id_to_name;

    const intrinsics:{ [key:string]:Intrinsics } = {};
    _(configFromServer.intrinsics).keys().forEach(cameraID => {
        const cameraName = cameraIDToName[cameraID];
        const intr = configFromServer.intrinsics[cameraID];
        intrinsics[cameraName] = {
            fx: intr.fx,
            fy: intr.fy,
            cx: intr.cx,
            cy: intr.cy,
            width: intr.width,
            height: intr.height,
            distortion_coeffs: {
                __ndarray__: _.map(
                    intr.distortion_coeffs.__ndarray__,
                    x => parseFloat(x.toFixed(2)),
                ),
            },
            distortion_model: intr.distortion_model,
        };
    });

    const extrinsics:{ [key:string]:Extrinsic } = {};
    _(configFromServer.extrinsics).keys().forEach(cameraID => {
        const cameraName = cameraIDToName[cameraID];
        extrinsics[cameraName] = {
            __ndarray__: configFromServer.extrinsics[cameraID].__ndarray__,
        };
    });

    const camerasInWorld:{ [key:string]:CamerasInWorld } = {};
    _(configFromServer.cameras_in_world).keys().forEach(cameraID => {
        const cameraName = cameraIDToName[cameraID];
        camerasInWorld[cameraName] = {
            __ndarray__: configFromServer.cameras_in_world[cameraID].__ndarray__,
        };
    });

    return {
        ...configFromServer,
        intrinsics: intrinsics,
        extrinsics: extrinsics,
        cameras_in_world: camerasInWorld,
    };
};
