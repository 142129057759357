import { typography } from '../../../styles/typography.css';
import { ArrowCounterClockwise } from '../icons/ArrowCounterClockwise';
import { CameraIcon } from '../icons/Camera';
import { ClockCountdown } from '../icons/ClockCountdown';
import { Comparison } from '../icons/Comparison';
import { Layout } from '../icons/Layout';
import { PlayPause } from '../icons/PlayPause';
import { SwingInsight } from '../icons/SwingInsight';

import * as css from './ActionButton.css';

type ActionButtonProps = {
    text:string;
    secondaryText?:string;
    icon:| 'camera'
        | 'layout'
        | 'arrowCounterClockwise'
        | 'playPause'
        | 'swingInsight'
        | 'comparison'
        | 'clockCountdown';
    onClick?:() => void;
    disabled?:boolean;
};

function getIcon(icon:ActionButtonProps['icon']) {
    switch(icon) {
        case 'comparison': {
            return <Comparison />;
        }
        case 'camera': {
            return <CameraIcon />;
        }
        case 'layout': {
            return <Layout />;
        }
        case 'arrowCounterClockwise': {
            return <ArrowCounterClockwise />;
        }
        case 'playPause': {
            return <PlayPause />;
        }
        case 'swingInsight': {
            return <SwingInsight />;
        }
        case 'clockCountdown': {
            return <ClockCountdown />;
        }
        default: {
            return <CameraIcon />;
        }
    }
}

export default function ActionButton({
    text,
    secondaryText,
    onClick,
    icon = 'layout',
    disabled,
}:ActionButtonProps) {
    if(!text) {
        return null;
    }

    return (
        <button
            className={css.actionButton({ disabled })}
            onClick={onClick}
            disabled={disabled}
        >
            {getIcon(icon)}
            <span
                id="layout-preset"
                className={typography({
                    size: 'h3',
                    color: 'dark',
                    weight: 'bold',
                })}
            >
                {text}
            </span>
            {secondaryText && (
                <span
                    id="layout-preset"
                    className={typography({
                        size: 'h3',
                        color: 'dark',
                    })}
                >
                    {secondaryText}
                </span>
            )}
        </button>
    );
}
