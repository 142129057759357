import React, { forwardRef } from 'react';

import * as css from './Input.css';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?:string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
    ({ label, ...props }, ref) => {
        return (
            <div className={css.container}>
                {label && <span className={css.label}>{label}</span>}
                {/* Ensure all props are passed down to the input */}
                <input ref={ref} className={css.input} {...props} />
            </div>
        );
    },
);

export default Input;
