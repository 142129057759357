export function Waveform({
    width = 16,
    height,
    color = '#3B82F6',
}:{
    width?:number;
    height?:number;
    color?:string;
}) {
    const w = width;
    const h = height ?? width;

    return (
        <svg
            width={w}
            height={h}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.5 6v4a.5.5 0 0 1-1 0V6a.5.5 0 1 1 1 0Zm2-4.5A.5.5 0 0 0 5 2v12a.5.5 0 0 0 1 0V2a.5.5 0 0 0-.5-.5Zm2.5 2a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 1 0V4a.5.5 0 0 0-.5-.5Zm2.5 2a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 1 0V6a.5.5 0 0 0-.5-.5Zm2.5-1a.5.5 0 0 0-.5.5v6a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"
                fill={color}
            />
        </svg>
    );
}
