import React, { createContext, useEffect, useState } from 'react';
import { SupabaseClient } from '@supabase/supabase-js';

import { UserSettingsStoreState, UserSettingsStore } from '../../state/userSettingsStore';
import { getUserSettingsStore } from '../dbFunctions';
import { APIPlaceholder } from '../api-placeholder/api-placeholder';
import { Layout, getUsersTags } from '@common';
import { useGlobalStore, FavoriteSwing } from '../../state/globalStore';
import { useSession } from '../hooks/useSession';
import _ from 'lodash';

interface UserSettingsProviderProps {
    children:React.ReactNode;
    store:UserSettingsStoreState;
    supabaseClient:SupabaseClient;
}

interface UserSettingsContextType {
    settings:UserSettingsStoreState|null;
    getSettingsStore:() => void; // should be fetch (a get method with void return is confusing)
    setSettingsStore:(userSettingsStore:UserSettingsStore) => void;
    getFavoriteSwings:() => void; // should be fetch (a get method with void return is confusing)
    markSwingAsFavorite:(swing:FavoriteSwing) => void;
    getCorridors:() => void; // should be fetch (a get method with void return is confusing)
    getActiveLayout:() => void; // should be fetch (a get method with void return is confusing)
    updateUserLayout:(layout:Layout) => Promise<Layout>;
}

const UserSettingsContext = createContext<UserSettingsContextType>({
    settings: null,
    getSettingsStore: () => null,
    setSettingsStore: () => null,
    getFavoriteSwings:() => [],
    markSwingAsFavorite:() => null,
    getCorridors: () => null,
    getActiveLayout: () => null,
    updateUserLayout: (layout:Layout) => Promise.resolve(layout),
});

function UserSettingsProvider({
    children,
    store,
    supabaseClient,
}:UserSettingsProviderProps) {
    const [ api ] = useState(new APIPlaceholder(supabaseClient));
    const { user } = useSession();
    const [ swings, setCorridors, setFavoriteSwings]
        = useGlobalStore(s => [
            s.swings,
            s.actions.setCorridors,
            s.actions.setFavoriteSwings,
        ]);

    const markSwingAsFavorite = async(swing:FavoriteSwing) => {
        const favorite = !!swing.name;
        // Reflect in database
        const { error } = await supabaseClient
            .from('swings')
            .update({
                favorite,
                name: swing.name
            })
            .eq('id', swing.id);
        if(error) {
            console.error('Error updating swing favorite', error);
            return;
        }
        
        // Update locally in store
        const favorites = _(swings)
            .filter(s => s.isFavorite)
            .map(({ id, name }) => ({ id, name }) as FavoriteSwing)
            .value();
        if(favorite)
            favorites.push(swing);
        else
            _.remove(favorites, s => s.id === swing.id);

        setFavoriteSwings(favorites);
    };

    const getFavoriteSwings = async() => {
        const { data, error } = await supabaseClient
            .from('swings')
            .select('id, name')
            .eq('favorite', true);
        
        if(error) {
            console.error('Error fetching favorite swings', error);
            return;
        }
        setFavoriteSwings(data);
    };

    const getActiveLayout = async() => {
        if(!user)
            return [];

        const [layout] = await api.layouts.getUserLayouts(user.id);

        store.actions.setActiveLayout(_.cloneDeep(layout));

        return layout;
    };

    const getUINodes = async() => {
        const uiNodes = await api.uiNodes.getAllUINodes();

        store.actions.setUINodes(uiNodes);

        return uiNodes;
    };

    const updateUserLayout = async(layout:Layout) => {
        const activeLayoutID = store.activeLayout?.id;
        const [savedLayout] = user
            ? await api.layouts.updateUserLayouts(user.id, [layout])
            : [layout];

        // set the active layout to the saved layout
        // if it was the active layout before
        if(activeLayoutID === savedLayout.id)
            store.actions.setActiveLayout(_.cloneDeep(savedLayout));
        else
            await getActiveLayout();

        return savedLayout;
    };

    const getCorridors = async() => {
        const corridors = await api.corridors.getAllCorridors();
        if(!corridors)
            return [];

        // this feels wrong
        setCorridors(corridors);
        store.actions.setActiveCorridor(corridors[0]);

        return corridors;
    };

    // TODO: Nuke this method from orbit
    const getSettingsStore = async() => {
        // Fetch user tags and write to store
        const availableTags = await getUsersTags(supabaseClient);
        store.actions.setAvailableTags(availableTags);

        // Fetch user settings store
        const userSettingsStore = await getUserSettingsStore(supabaseClient);
        store.actions.setUserSettings(userSettingsStore?.userSettings ?? {});
    };

    // TODO: Nuke this method from orbit
    const setSettingsStore = async(userSettingsStore:UserSettingsStore) => {
        await supabaseClient
            .from('user_settings')
            .upsert({ user_id: user?.id, settings: userSettingsStore });
    };
    
    // Getting all initial data that the client needs.
    useEffect(() => {
        getUINodes();
        getSettingsStore();
        getFavoriteSwings();
        getCorridors();
        getActiveLayout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <UserSettingsContext.Provider
            value={{
                settings: store,
                getSettingsStore,
                setSettingsStore,
                getFavoriteSwings,
                markSwingAsFavorite,
                getCorridors,
                getActiveLayout,
                updateUserLayout,
            }}
        >
            {children}
        </UserSettingsContext.Provider>
    );
}

export { UserSettingsProvider, UserSettingsContext };
