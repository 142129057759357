export function LogOut({
    width = 24,
    height,
    color = '#3B82F6',
}:{
    width?:number;
    height?:number;
    color?:string;
}) {
    const w = width;
    const h = height ?? width;

    return (
        <svg
            width={w}
            height={h}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.25 20.25a.75.75 0 0 1-.75.75h-6a.75.75 0 0 1-.75-.75V3.75A.75.75 0 0 1 4.5 3h6a.75.75 0 1 1 0 1.5H5.25v15h5.25a.75.75 0 0 1 .75.75Zm10.28-8.78-3.75-3.75a.75.75 0 0 0-1.28.53v3h-6a.75.75 0 1 0 0 1.5h6v3a.75.75 0 0 0 1.28.53l3.75-3.75a.747.747 0 0 0 0-1.06Z"
                fill={color}
            />
        </svg>
    );
}
