import { typography } from '../../styles/typography.css';

import * as css from './Pill.css';

export default function Pill({ label }:{ label:string }) {
    return (
        <div className={css.pill}>
            <p
                className={typography({
                    size: 'floorParagraph',
                    color: 'floorLight',
                    weight: 'bold',
                })}
            >
                {label}
            </p>
        </div>
    );
}
