import { createContext, useEffect } from 'react';
import { GlobalContextProviderProps, GlobalContextType } from './GlobalContext.types';
import { useSession } from '../../hooks/useSession';
import { useGlobalStore } from '../../../state/globalStore';
import { useUserSettingsStore } from '../../../state/userSettingsStore';
import { useBoothSession } from '../../hooks/useBoothSession';
import { SupabaseClient } from '@supabase/supabase-js';
import { Club, Swing, fetchAllUserActivites, fetchAllUserFavoriteSwings as _fetchAllUserFavoriteSwings, createNewActivity, Tag, updateActivity, updateActivityClub, updateActivityTags } from '@common';

// Create GlobalContext with the correct type
export const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

export const GlobalContextProvider = ({ children, supabase }:GlobalContextProviderProps & { supabase:SupabaseClient }) => {
    const { user } = useSession();
    const { boothSessionDetails } = useBoothSession();
    const { activityId } = useUserSettingsStore();

    const {
        activityTags,
        activityClub,
        actions: { setActivityClub, setActivityId, setActivityTags },
    } = useUserSettingsStore((state) => state);

    const actions = useGlobalStore((state) => state.actions);

    const viewingActivityId = useGlobalStore(state => state.viewingActivityId);
    const swings = useGlobalStore(state => state.swings);

    const fetchActivities = async() => {
        actions.clearActivities();
        if(user?.id) {
            const data = await fetchAllUserActivites(supabase, user?.id);
            if(data) {
                actions.setActivities(data);
            }
        }
    };

    // Used to change the club
    const selectClub = async(club:Club) => {

        if(!user?.id || !boothSessionDetails?.id) {
            return;
        }

        // If current activity has no swings, re-use the activity, don't create a new one and leave the other one empty
        if(swings?.length <= 0) {
            if(activityId) {
                updateActivityClub(supabase, club, activityId);
            }
        } else {
            const activityId = await createNewActivity(
                supabase,
                user?.id,
                boothSessionDetails?.id,
                club,
                activityTags,
            );
    
            if(activityId) {
                setActivityId(activityId);
                actions.setViewingActivityID(activityId);
            }
        }
        
        setActivityClub(club);

        // Clear swings
        actions.resetSwings();
        
        // Re-fetch activities to udate activities list
        fetchActivities();
    };

    const changeTags = async(tags:Tag[]) => {
        if(user && boothSessionDetails?.id) {

            // If current activity has no swings, re-use the activity, don't create a new one and leave the other one empty
            if(swings?.length <= 0) {
                if(activityId) {
                    updateActivityTags(supabase, tags, activityId);
                }
            } else {
                const activityId = await createNewActivity(
                    supabase,
                    user.id,
                    boothSessionDetails?.id,
                    activityClub,
                    tags,
                );
    
                if(activityId) {
                    setActivityId(activityId);
                    actions.setViewingActivityID(activityId);
                }
            }

            setActivityTags(tags);
        }

        // Clear swings
        actions.resetSwings();
        
        // Re-fetch activities to get the one we just created
        fetchActivities();
    };

    const selectSwing = (swing:Swing) => {
        // Check if it's ready to be viewed
        if(swing.quickAnalysis || swing.fullAnalysis) {
            actions.setActiveSwingID(swing?.id);
        }
    };

    useEffect(() => {
        if(!user) {
            return;
        }
        const fetchFavoriteSwings = async() => {
            try {
                const swings = await _fetchAllUserFavoriteSwings(supabase, user?.id);
                actions.setAllUserFavoriteSwings(swings);
            } catch(e) {
                // no-op
            }

        };
        fetchFavoriteSwings();
    }, [viewingActivityId]);

    useEffect(() => {
        
        if(!user) {
            return;
        }
        
        const fetchFavoriteSwings = async() => {
            try {
                const swings = await _fetchAllUserFavoriteSwings(supabase, user?.id);
                actions.setAllUserFavoriteSwings(swings);
            } catch(e) {
                // no-op
            }

        };

        const fetchClubs = async() => {
            const { data: clubs, error } = await supabase.from('club_types').select('type_name, clubs(*)');

            if(error) {
                console.error('Failed to fetch clubs:', error);
                return;
            }

            actions.setClubs(clubs);
        };

        fetchClubs();
        fetchFavoriteSwings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions, user]);
 
    return (
        <GlobalContext.Provider
            value={{
                selectClub,
                changeTags,
                selectSwing
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};
