import { localize, UIGraphLine } from '@common';
import { ImplementationOf } from '../../UIComponent.types';

export const GraphLine:ImplementationOf<'graph_line'>
= () => {
    throw new Error('[UIComponent(\'graph_line\')]: should only be inside graphs');
};


export function inferGraphLineName({ name, time_series, axis }:UIGraphLine) {
    return name?.value
        ?? localize(
            `axis.${
                time_series?.unit?.startsWith('rad')
                    ? 'angle'
                    : 'position'
            }.${axis || 'w'}_long_name`
        );
}
