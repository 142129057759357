import { useContext, useState } from 'react';
import { BackNavigation } from '../../components/Navigation/BackNavigation';
import { CircularPlus } from '../../components/ui/icons/CircularPlus';
import { Pencil } from '../../components/ui/icons/Pencil';
import * as css from './TagSelection.css';
import { Tag, useUserSettingsStore } from '../../state/userSettingsStore';
import { TagModal } from './TagModal';
import { AnimatePresence } from 'motion/react';
import { GlobalContext } from '../../utils/contexts/GlobalContext';
import { PropTypes } from './TagSelection.types';

export default function TagSelection({supabase}:PropTypes) {
    const [editMode, setEditMode] = useState(false);
    const [modalOpen, setModalOpen] = useState<'add' | 'edit' | undefined>();
    const [editTag, setEditTag] = useState<Tag | undefined>();

    const globalContext = useContext(GlobalContext);

    const {
        availableTags,
        activityTags,
    } = useUserSettingsStore((state) => state);

    const [selectedTags, setSelectedTags] = useState<Tag[]>(activityTags ?? []);

    const handleSave = async() => {
        const activityTagIds = activityTags?.map((tag) => tag.id).sort();
        const selectedTagIds = selectedTags.map((tag) => tag.id).sort();
        const tagsChanged = JSON.stringify(activityTagIds) !== JSON.stringify(selectedTagIds);

        // When new tags are selected, a new activity should be created created
        if(tagsChanged) {
            globalContext?.changeTags(selectedTags);
        }
    };

    const onEditClick = (tag:Tag) => {
        setEditTag(tag);
        setModalOpen('edit');
    };

    return (
        <div className={css.root}>
            <BackNavigation
                screenTitle="Tags"
                navigateTo="goBack"
                onSave={handleSave}
            />
            <div className={css.content}>
                <div className={css.tags}>
                    {availableTags.map((tag) => (
                        <button
                            key={tag.id}
                            className={css.tag({
                                selected: selectedTags?.some((t) => t.id === tag.id),
                            })}
                            onClick={() => {
                                editMode
                                    ? onEditClick(tag)
                                    : setSelectedTags(() => {
                                        if(selectedTags.some((t) => t.id === tag.id)) {
                                            return selectedTags.filter((t) => t.id !== tag.id);
                                        }
                                        return [...selectedTags, tag];
                                    });
                            }}
                        >
                            {tag.name}
                            {editMode && <Pencil width={24} />}
                        </button>
                    ))}
                </div>

                <div className={css.buttons}>
                    <button className={css.button} onClick={() => setModalOpen('add')}>
                        <CircularPlus width={24} />
                        Add Tag
                    </button>
                    <button className={css.button} onClick={() => setEditMode((prev) => !prev)}>
                        <Pencil width={24} />
                        Edit Tags
                    </button>
                </div>
            </div>
            <AnimatePresence>
                {modalOpen && (
                    <TagModal
                        modalOpen={modalOpen}
                        setModalOpen={setModalOpen}
                        editTag={editTag}
                        setEditTag={setEditTag}
                        setSelectedTags={setSelectedTags}
                        selectedTags={selectedTags}
                        supabase={supabase}
                    />
                )}
            </AnimatePresence>
        </div>
    );
}
