import { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import {
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    ComposedChart,
    Legend,
    Line,
    Tooltip as RechartsTooltip,
    ReferenceLine,
} from 'recharts';

import { Device, Segmentation, Unit } from '@common';
import { useIntersectionObserver } from '@common/ui';
import { renderXAxis } from './helpers/renderXAxis';
import { useGlobalStore } from '../../state/globalStore';
import { renderLegend } from './helpers/renderLegend';
import { LegendPayload } from './types';
import { Tooltip } from './components/Tooltip';
import { formatValue } from './helpers/formatValue';
import { LinesPerUnit } from '../UIComponents/implementations/Graph';

import * as css from './DynamicGraph.css';

type DynamicGraphProps = {
    data:{
        dataPoints:Record<string, number | null>[];
        isQuickAnalysis:boolean;
    };
    lines:LinesPerUnit;
    segmentation:Segmentation;
    isInteractive?:boolean;
    device:Device;
};

const defaultColors = ['#1E88E5', '#F28E2B', '#59A14F', '#FF7043'];

export default function DynamicGraph({
    data,
    lines,
    segmentation,
    isInteractive = true,
    device
}:DynamicGraphProps) {
    const currentFrame = useGlobalStore((state) => state.currentFrame);
    const activePosition = useGlobalStore((state) => state.activePosition);
    const [filteredDataKeys, setFilteredDataKeys] = useState<string[]>([]);
    const [ref, isVisible] = useIntersectionObserver({threshold: 0.2});

    const legendClickCallback = useCallback((dataKey:string) => {
        if(!isInteractive) return;

        setFilteredDataKeys((prev) =>
            _.includes(prev, dataKey)
                ? _.filter(prev, (item) => item !== dataKey)
                : [...prev, dataKey],
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { dataPoints, isQuickAnalysis } = data;

    const { flattenedLines, units } = useMemo(() => {
        const flattenedLines = _.flatMap(lines, (lineArray, unit) =>
            _.map(lineArray, (line) =>
                ({ ...line, unit })
            ),
        );
        const units = _.keys(lines).slice(0, 2);

        return { flattenedLines, units };
    }, [lines]);


    const [defaultUnit] = units;

    if(!Array.isArray(dataPoints) || !dataPoints.length) {
        return <div className={css.root({ device })}>No data</div>;
    }

    // Skip expensive updates when possible
    const shouldUpdate = isInteractive && isVisible;

    return (
        <div className={css.root({ device })} ref={ref}>
            <div
                className={css.container}
                style={{
                    height: isInteractive
                        ? undefined
                        : '100%'
                }}
            >
                <ResponsiveContainer style={{ flex: 1, padding: 8 }}>
                    <ComposedChart
                        data={dataPoints}
                        defaultShowTooltip={false}
                        throttleDelay={60}
                        margin={{ top: 20, right: 16, bottom: 32, left: 16 }}
                    >
                        <CartesianGrid
                            strokeDasharray="3 3"
                            vertical={false}
                            stroke={
                                isInteractive
                                    ? '#cfdef9'
                                    : '#475569'
                            }
                        />

                        {shouldUpdate && <ReferenceLine yAxisId={defaultUnit} x={currentFrame} stroke="#3B82F6" />}

                        {renderXAxis({
                            isQuickAnalysis,
                            segmentation,
                            activePosition,
                            axisKey: defaultUnit,
                            isInteractive,
                        })}

                        <Legend
                            wrapperStyle={{ bottom: 32 }}
                            content={({ payload }) =>
                                payload &&
                                renderLegend(
                                    payload as LegendPayload[],
                                    legendClickCallback,
                                )
                            }
                        />

                        {_.map(flattenedLines, (line, index) => {
                            const axis = line?.axis || 'x';
                            const hidden = _.includes(
                                filteredDataKeys,
                                line.id as string,
                            );
                            const color =
                                line.metadata.color ?? defaultColors[index];
                            const name =
                                line.name
                                    ? line.name.value
                                    : axis
                                        ? axis.toUpperCase()
                                        : line.id;

                            const thickness = line.metadata.line_thickness ?? 1;

                            return (
                                <Line
                                    key={line.id}
                                    dataKey={line.id}
                                    yAxisId={line.unit}
                                    stroke={color}
                                    strokeWidth={thickness}
                                    name={name}
                                    activeDot={false}
                                    dot={false}
                                    isAnimationActive={false}
                                    type="monotone"
                                    connectNulls
                                    hide={hidden}
                                />
                            );
                        })}
                        {_.map(units, (u, index) => {
                            const unit = u as unknown as Unit;
                            const FZ = 12;
                            const orientation = index === 0
                                ? 'left'
                                : 'right';
                            const dx = index === 0
                                ? -FZ
                                : FZ;
                            return (
                                <YAxis
                                    key={unit}
                                    yAxisId={unit}
                                    orientation={orientation}
                                    tickCount={6}
                                    dx={dx}
                                    fontSize={FZ}
                                    fontWeight={500}
                                    tickLine={false}
                                    axisLine={false}
                                    domain={['dataMin', 'dataMax']}
                                    stroke={
                                        isInteractive
                                            ? '#475569'
                                            : '#94A3B8'
                                    }
                                    tickFormatter={(val:number) =>
                                        formatValue(val, unit)
                                    }
                                />
                            );
                        })}
                        {shouldUpdate && (
                            <RechartsTooltip
                                filterNull={false}
                                content={({ payload }) => {
                                    return (
                                        <Tooltip
                                            payload={payload}
                                            lines={lines}
                                            formatter={formatValue}
                                        />
                                    );
                                }}
                            />
                        )}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}
