/* eslint-disable no-restricted-syntax */
import { createContext, type PropsWithChildren, useEffect, useState } from 'react';
import type { SupabaseClient } from '@supabase/supabase-js';

import { endSession, loginBooth, loginUser, updateSession } from '../apiMethods';

type BoothSessionProviderProps = {
    supabaseBoothClient:SupabaseClient;
};

type BoothSessionDetails = {
    id:number;
    uid:string;
};

type BoothSessionUser = {
    id:number;
    userId:string;
    accessToken:string;
    refreshToken:string;
};

type BoothSessionContextType = {
    isLoading:boolean;
    login(email:string, password:string):Promise<void>;
    loginUser(email:string, password:string):ReturnType<typeof loginUser> | null;
    logout():void;
    boothId:number | null;
    boothSessionDetails:BoothSessionDetails | null;
    boothSessionUsers:BoothSessionUser[];
    endBoothSession():Promise<void>;
    updateUserSession(userId:string, accessToken:string, refreshToken:string):Promise<void>;
};

export const BoothSessionContext = createContext<BoothSessionContextType | null>({
    isLoading: false,
    login: async() => Promise.resolve(),
    loginUser: () => null,
    logout: async() => null,
    boothId: null,
    boothSessionDetails: null,
    boothSessionUsers: [],
    endBoothSession: async() => Promise.resolve(),
    updateUserSession: async() => Promise.resolve(),
});

export const BoothSessionProvider = ({ supabaseBoothClient, children }:PropsWithChildren<BoothSessionProviderProps>) => {
    const [isLoading, setIsLoading] = useState(false);
    const [boothId, setBoothId] = useState<number | null>(null);
    const [boothSessionDetails, setBoothSessionDetails] = useState<BoothSessionDetails | null>(null);
    const [boothSessionUsers, setBoothSessionUsers] = useState<BoothSessionUser[]>([]);

    const setInitialBoothSession = async() => {
        let boothId = null;

        try {
            const { data } = await supabaseBoothClient.auth.getSession();
            boothId = data.session?.user.user_metadata?.['booth_id'];
        } catch{
            // no-op
        }

        if(!boothId) {
            setBoothId(null);
            setIsLoading(false);
            return;
        }

        setBoothId(boothId);

        const { data: boothSessionData, error: boothSessionError } = await supabaseBoothClient
            .from('booth_sessions')
            .select('id,session_uid')
            .eq('booth_id', boothId)
            .is('ended_at', null)
            .order('created_at', { ascending: false })
            .limit(1)
            .single();

        if(!boothSessionError && boothSessionData) {
            const { id, session_uid: uid } = boothSessionData;
            setBoothSessionDetails({ id, uid });
        } else {
            return;
        }

        const { data: boothSessionUsersData, error: boothSessionUsersError } = await supabaseBoothClient
            .from('booth_session_users')
            .select('id,session_data,user_id')
            .eq('booth_session_id', boothSessionData.id)
            .order('created_at', { ascending: true });

        if(!boothSessionUsersError && boothSessionUsersData) {
            setBoothSessionUsers(
                boothSessionUsersData.map(({ id, user_id, session_data }) => ({
                    id,
                    userId: user_id,
                    accessToken: session_data?.access_token,
                    refreshToken: session_data?.refresh_token,
                })),
            );
        }
    };

    useEffect(() => {
        if(boothSessionDetails) {
            return;
        }

        const getBoothSession = async() => {
            setIsLoading(true);

            await setInitialBoothSession();

            setIsLoading(false);
        };

        void getBoothSession();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boothId]);

    useEffect(() => {
        const {
            data: { subscription },
        } = supabaseBoothClient.auth.onAuthStateChange(async(event) => {
            if(event === 'INITIAL_SESSION') {
                await setInitialBoothSession();
            }

            if(event === 'SIGNED_OUT') {
                setBoothId(null);
                setBoothSessionDetails(null);
                setBoothSessionUsers([]);
            }
        });

        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supabaseBoothClient.auth]);

    useEffect(() => {
        if(!boothSessionDetails) {
            return;
        }

        const userInsertSubscription = supabaseBoothClient
            .channel('booth_session_users')
            .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'booth_session_users' }, handleUserJoinedSession)
            .subscribe((status, err) => {
                if(err) {
                    console.error(`Subscription error (${status}):`, err);
                }
            });

        const userUpdateSubscription = supabaseBoothClient
            .channel('booth_session_users_UPDATE')
            .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'booth_session_users' }, handleUserSessionUpdate)
            .subscribe((status, err) => {
                if(err) {
                    console.error(`Subscription error (${status}):`, err);
                }
            });

        return () => {
            if(userUpdateSubscription) {
                void userUpdateSubscription.unsubscribe();
            }

            if(userInsertSubscription) {
                void userInsertSubscription.unsubscribe();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boothSessionDetails]);

    const handleUserJoinedSession = (payload:{ new:{ id:number; user_id:string; session_data:{ access_token:string; refresh_token:string } } }) => {
        const sessionData = payload.new?.session_data;
        if(sessionData) {
            const {
                id,
                user_id: userId,
                session_data: { access_token: accessToken, refresh_token: refreshToken },
            } = payload.new;
            setBoothSessionUsers((prev) => [...prev, { id, userId, accessToken, refreshToken }]);
        }
    };

    const handleUserSessionUpdate = (payload:{ new:{ id:number; session_data:{ access_token:string; refresh_token:string } } }) => {
        const sessionData = payload.new?.session_data;

        if(sessionData) {
            setBoothSessionUsers((prev) => {
                return prev.map((user) => {
                    if(user.id === payload.new.id) {
                        return { ...user, accessToken: sessionData.access_token, refreshToken: sessionData.refresh_token };
                    }

                    return user;
                });
            });
        } else {
            setBoothSessionUsers([]);
        }
    };

    const handleLogin = async(email:string, password:string) => {
        setIsLoading(true);

        const res = await loginBooth(email, password);

        const { success, data, error } = res;

        if(!success) {
            setIsLoading(false);
            throw error;
        }

        const { tokens, boothSession } = data;
        const { accessToken: access_token, refreshToken: refresh_token } = tokens;
        await supabaseBoothClient.auth.setSession({ access_token, refresh_token });

        setBoothId(boothSession.boothId);

        const { id, uid } = boothSession;
        setBoothSessionDetails({ id, uid });

        setIsLoading(false);
    };

    const handleLoginUser = async(email:string, password:string) => {
        const boothSession = await supabaseBoothClient.auth.getSession();
        return loginUser(email, password, boothSession?.data?.session?.access_token);
    };

    const handleLogout = async() => {
        await handleEndBoothSession();
        await supabaseBoothClient.auth.signOut();
    };

    const handleEndBoothSession = async() => {
        const boothSession = await supabaseBoothClient.auth.getSession();
        const boothSessionToken = boothSession?.data?.session?.access_token;
        if(boothSessionToken) {
            const { data } = await endSession(boothSessionToken);
            if(data) {
                const { newBoothSessionId: id, newBoothSessionUid: uid } = data;
                setBoothSessionDetails({ id, uid });
            }
        }
        setBoothSessionUsers([]);
    };

    const handleUpdateSession = async(userId:string, accessToken:string, refreshToken:string) => {
        const user = boothSessionUsers.find((u) => u.userId === userId);

        if(!user) {
            // user will have to log in again...
            return;
        }

        const boothSession = await supabaseBoothClient.auth.getSession();
        const boothSessionToken = boothSession?.data?.session?.access_token;
        if(boothSessionToken) {
            await updateSession(boothSessionToken, user.id, accessToken, refreshToken);
        }
    };

    return (
        <BoothSessionContext.Provider
            value={{
                isLoading,
                login: handleLogin,
                loginUser: handleLoginUser,
                logout: handleLogout,
                boothId,
                boothSessionDetails,
                boothSessionUsers,
                endBoothSession: handleEndBoothSession,
                updateUserSession: handleUpdateSession,
            }}
        >
            {children}
        </BoothSessionContext.Provider>
    );
};
