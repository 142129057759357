import { create } from 'zustand';
import useWebSocket, { type SendMessage } from 'react-use-websocket';
import type { WebSocketMessage } from 'react-use-websocket/dist/lib/types';
import { useEffect, useRef } from 'react';
import { SocketPayload } from '../syncSocketStore';

export const useCommunicationSocket = () => {
    const setSendMessage = useCommunicationSocketStore(
        (state) => state.actions.setSenderFn,
    );
    const onMessage = useCommunicationSocketStore(
        (state) => state.actions.onMessage,
    );
    const setIsConnected = useCommunicationSocketStore(
        (state) => state.actions.setConnected,
    );

    const onOpen = () => {
        setIsConnected(true);
        setSendMessage(sendJsonMessage);
    };

    const onClose = () => setIsConnected(false);

    // A simple queue to hold incoming websocket messages
    const messageQueue = useRef<MessageEvent[]>([]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            // If there are any items in the queue, we pop one!
            if(messageQueue.current.length > 0) {
                const message = messageQueue.current.shift(); // Remove the first message from the queue
                if(message) {
                    messageHandler(message);
                }
            }
        }, 600); // Pop one message from queue every 600ms

        return () => clearInterval(intervalId);
    }, []);

    const messageHandler = (message:MessageEvent) => {
        if(message.type === 'message' && typeof onMessage === 'function') {
            try {
                const data = JSON.parse(message.data) as SocketPayload;
                onMessage(data);
            } catch(e) {
                console.error('WebSocket data parsing error:', e);
                return;
            }
        }
    };

    const { sendJsonMessage } = useWebSocket(
        import.meta.env['VITE_PUBLIC_WEBSOCKET_SERVER'],
        {
            onOpen: onOpen,
            onClose: onClose,
            onMessage: (message:MessageEvent) => {
                messageQueue.current = [...messageQueue.current, message];
            },
            filter: (message) => {
                const status = JSON.parse(message.data).type;

                if(status === 'BEAST_STATUS') {
                    return false; // Not forwarding BEAST_STATUS messages for NOW.
                }

                return true;
            },
            // Should always reconnect
            shouldReconnect: () => true,
            reconnectAttempts: 1000, // ~ 30 minute reconnection window
            reconnectInterval: 2000, // Try reconnecting every two secs
        },
    );
};

export type BeastCommunicationState = {
    senderFn:SendMessage | null;
    isConnected:boolean;
    payload:SocketPayload | null;
    actions:{
        setSenderFn:(fn:SendMessage) => void;
        setConnected:(connected:boolean) => void;
        sendCommunicationMessage:(payload:SocketPayload) => void;
        onMessage:(payload:SocketPayload) => void;
    };
};

export const useCommunicationSocketStore = create<BeastCommunicationState>(
    (set, get) => {
        return {
            senderFn: null,
            reciverFn: () => void 0,
            isConnected: false,
            payload: null,
            actions: {
                setSenderFn: (fn) => {
                    set({ senderFn: fn });
                },

                setConnected: (connected) => set({ isConnected: connected }),
                sendCommunicationMessage: (payload) => {
                    const senderFn = get().senderFn;
                    if(typeof senderFn === 'function') {
                        senderFn(payload as unknown as WebSocketMessage);
                    }
                },
                onMessage: (payload) => {
                    set({ payload });
                },
            },
        };
    },
);
