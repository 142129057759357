import { Fragment, useEffect } from 'react';
import _ from 'lodash';

import TopBar from '../components/TopBar';
import { useUIComponentValues, useGlobalStore } from '../state/globalStore';
import { useSyncSocketStore } from '../state/syncSocketStore';
import { VideoReplay } from '../components/VideoReplay/VideoReplay.floor';
import { useUserSettingsStore } from '../state/userSettingsStore';
import { IS_DEV } from '../utils/consts';
import UIComponentsLayout from '../components/UIComponentsLayout';

import * as css from './FloorScreen.css';


export default function FloorScreen() {

    document.title = 'Floor';
    
    const userSettings = useUserSettingsStore((state) => [
        state.activeLayout,
        state.uiNodes,
        state.playbackSettings,
        state.actions.setUserSettings
    ] as const);

    const syncPayload = useSyncSocketStore((state) => [
        state.payload?.activeLayout,
        state.payload?.activityClub,
        state.payload?.activityTags,
        state.payload?.playbackSettings,
        state.payload?.userSettings
    ] as const);

    const [cloudLayout, uiNodeTree, userPlaybackSettings, setDeprecatedUserJSONSettings] = userSettings;
    const [layout, club, tags, playbackSettings, deprecatedUserJSONSettings] = syncPayload;

    const shouldInstantReplay = IS_DEV
        ? false
        : Boolean(playbackSettings?.instantReplay ?? userPlaybackSettings?.instantReplay);

    const instantReplay = useGlobalStore((state) => state.instantReplay); // TODO: stop using global store
    const values$ = useUIComponentValues();

    const activeLayout = layout || cloudLayout;

    useEffect(() => {
        // NOTE:
        // This effect syncs incoming `userSettings` websocket message with the local `userSettings` store.
        // That's not cool.
        // TODO:
        // Save `showCorridorOn`, `language`, `golfBag`, and such in a _user layout_.
        if(deprecatedUserJSONSettings) {
            setDeprecatedUserJSONSettings({showCorridorOn: deprecatedUserJSONSettings.showCorridorOn});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layout, deprecatedUserJSONSettings]);

    return (
        <Fragment key="floor-screen">
            {shouldInstantReplay && instantReplay.activeId && (
                <VideoReplay
                    videos={instantReplay.videos}
                    onReplayEnded={() => {
                        instantReplay.onReplayEnded();
                    }}
                />
            )}
            <div className={css.root}>
                <TopBar
                    club={club?.short_name || ''}
                    tags={_.map(tags, t => t.name) || []}
                    selectedLayout={layout?.name ?? 'No layout'}
                    progressBar={<> </>}
                />
                {uiNodeTree && activeLayout &&
                    <UIComponentsLayout
                        uiNodeTree={uiNodeTree}
                        layout={activeLayout}
                        currentDevice="floor"
                        values$={values$}
                    />
                }
            </div>
        </Fragment>
    );
}
