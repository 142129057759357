
import _ from 'lodash';
import * as css from './SwingFoundationsRenderer.css';

import { useGlobalStore } from '../../../../state/globalStore';
import { localize, Category, inferSwingPosition, L10n, positionNumberToSwingPosition, Analysis, SwingPosition, swingPositionToPositionNumber, UIParameter, ALL_DEVICES, getLocalizedSwingPositionName } from '@common';
import { SimplifiedCameraConfig } from '../../../../utils/types/camera';
import { useContext, useMemo, useState } from 'react';
import { ModuleBaseButton } from '../../../ModuleBase/ModuleBase.kiosk';
import { UIComponentProps } from '../../UIComponent.types';
import ModuleBase from '../../../ModuleBase';
import VideoWithSkeleton from '../../../VideoWithSkeleton';
import UIComponent from '../../UIComponent';
import { useUserSettingsStore } from '../../../../state/userSettingsStore/userSettingsStore';
import { UserSettingsContext } from '../../../../utils/contexts/UserSettingsContext';
import { UserSettingsStore } from '../../../../state/userSettingsStore';


export type SwingFoundationsRendererProps =
{
    readonly cameraConfig:SimplifiedCameraConfig;
    readonly swing:Analysis|null;
    readonly parameterProps:UIComponentProps<UIParameter>[];
    readonly name:L10n|null;
    readonly swingFoundationGroups:{
        [id in SwingFoundationGroupID]:Category<'swing_foundation_group'>
    };
}

type ParameterProps = UIComponentProps<UIParameter>;
type SwingFoundationGroupID = `swing_foundation_group.${string}${SwingPosition}${string}`;

export function SwingFoundationsRenderer(
    {
        cameraConfig,
        swing,
        name,
        parameterProps,
        swingFoundationGroups,
    }:SwingFoundationsRendererProps
) {
    //? I am NOT proud of this ↴
    const activePositionNumber = useGlobalStore((state) => state.activePosition);
    const showComparison = useGlobalStore((state) => state.showComparison);
    const actions = useGlobalStore((state) => state.actions);
    const [skeletonOverlay, setSkeletonOverlay] = useState(false);
    const { settings: settingsStore, setSettingsStore } = useContext(UserSettingsContext);
    const [corridor, userSettings, setUserSettings] = useUserSettingsStore((state) => [
        state.activeCorridor,
        state.userSettings,
        state.actions.setUserSettings
    ]);

    const buttons:ModuleBaseButton[] = useMemo(
        () => [
            {
                label: 'Comparison',
                icon: 'comparison',
                onClick: () => {
                    actions.setShowComparison(!showComparison);
                },
                isActive: showComparison,
            },
            {
                label: 'Skeleton',
                icon: '3d',
                onClick: () => {
                    setSkeletonOverlay(!skeletonOverlay);
                },
                isActive: skeletonOverlay,
            },
            {
                label: 'Corridors',
                icon: 'waveform',
                onClick: () => {
                    const newSettings = setUserSettings({
                        showCorridorOn: _.isEmpty(userSettings.showCorridorOn)
                            ? [...ALL_DEVICES]
                            : [],
                    });
                    const newSettingsStore = { ...settingsStore, userSettings: newSettings } as UserSettingsStore;
                    setSettingsStore(newSettingsStore);
                },
                isActive: !!corridor && !_.isEmpty(userSettings.showCorridorOn),
            },
        ],
        [
            actions,
            showComparison,
            skeletonOverlay,
            corridor,
            userSettings,
            setUserSettings,
            settingsStore,
            setSettingsStore,
        ],
    );


    const [parameterPropsByGroup, swingFoundationPositionNumbers] = useMemo(
        () => {
            const parameterPropsByGroup = _(parameterProps)
                .groupBy(prop => prop.currentNode.categories.swing_foundation_group ?? 'null')
                .defaults(_.mapValues(swingFoundationGroups, () => [] as ParameterProps[]))
                .map((props, id) => {
                    const groupID = id as SwingFoundationGroupID;
                    return {
                        group: swingFoundationGroups?.[groupID],
                        position: inferSwingPosition(groupID),
                        props,
                    };
                })
                .sortBy(({ group, position }) =>
                    [
                        position,
                        group?.display_order
                    ]
                )
                .value();
        
            // extract [1, 2, 4, 7] since they aren't hardcoded anymore
            const swingFoundationPositionNumbers = _(swingFoundationGroups)
                .map(group => swingPositionToPositionNumber(inferSwingPosition(group.id)))
                .compact()
                .uniq()
                .sortBy()
                .value();
            
            return [parameterPropsByGroup, swingFoundationPositionNumbers] as const;
        },
        [parameterProps, swingFoundationGroups]
    );

    // find if we should show parameters from p1, p2, p4 or p7
    const activeSwingFoundationPosition = useMemo(
        () => positionNumberToSwingPosition(
            _.findLast(
                swingFoundationPositionNumbers,
                pos => pos <= activePositionNumber
            )
        ),
        [activePositionNumber, swingFoundationPositionNumbers]
    );

    const renderParameters = useMemo(
        () => _(parameterPropsByGroup)
            .filter(({ group, position, props }) =>
                !!group?.id
                && position === activeSwingFoundationPosition
                && !_.isEmpty(props)
            )
            .map(({ group, props }) =>
                <div key={group.id} className={css.row}>
                    <div className={css.header}>
                        <p className={css.headerText}>
                            {group?.name?.value}
                        </p>
                    </div>
                    <div className={css.cells({ showComparison: false })}>
                        {_.map(props, (p) => (
                            <UIComponent
                                key={p.currentNode.id}
                                {...p}
                            />
                        ))}
                    </div>
                </div>
            )
            .value(),
        [activeSwingFoundationPosition, parameterPropsByGroup]
    );

    const title = getLocalizedSwingPositionName(activeSwingFoundationPosition);
    const subtitle = name?.value || 'Swing foundations'

    return (
        <ModuleBase
            title={title}
            subtitle={subtitle}
            buttons={buttons}
            isStatic
        >
            <div className={css.root}>
                <div className={css.videoLayout}>
                    <VideoWithSkeleton
                        skeletonOverlay={skeletonOverlay}
                        layout="stacked"
                        videos={swing?.data.videos}
                        cameraConfig={cameraConfig}
                    />
                </div>
                <div className={css.table}>
                    {renderParameters}
                </div>
            </div>
        </ModuleBase>
    );
}

