/* eslint-disable no-restricted-syntax */
import { memo } from 'react';
import { NameType, Payload, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { typography } from '../../../styles/typography.css';
import { LinesPerUnit } from '../../UIComponents/implementations/Graph';
import { Unit } from '@common';

import * as css from './Tooltip.css';

const findUnit = (dataKey:string, lines:LinesPerUnit) => {
    for(const [unit, lineArray] of Object.entries(lines)) {
        if(lineArray.some((line) => line.id === dataKey)) {
            return unit;
        }
    }
    return 'm';
};

const _Tooltip = ({
    payload,
    lines,
    formatter,
}:{
    payload?:Payload<ValueType, NameType>[];
    lines:LinesPerUnit;
    formatter:(value:number, unit:Unit) => string;
}) => {
    if(payload && payload.length) {
        const valuePayload = payload[0].payload;

        if(!valuePayload) {
            return null;
        }

        return (
            <div className={css.tooltip}>
                {Array.isArray(payload) &&
          payload.map((p) => {
              const hidden = typeof p.value !== 'number';
              const value = hidden
                  ? 0
                  : p.value;
              const unit = findUnit(p.dataKey as string, lines);

              const formattedValue = formatter(value as number, unit as UnitType);

              return (
                  <div
                      className={css.tooltipItem}
                      style={{
                          opacity: hidden
                              ? 0
                              : 1,
                      }}
                      key={p.name}
                  >
                      <div className={css.tooltipNumber}>
                          <p
                              className={typography({
                                  size: 'h3',
                              })}
                          >
                              <span
                                  className={typography({
                                      weight: 'semi',
                                      color: 'dark',
                                  })}
                              >
                                  {p.name}:{' '}
                              </span>
                              <span
                                  className={typography({
                                      weight: 'bold',
                                  })}
                                  style={{ color: p.color }}
                              >
                                  {formattedValue}
                              </span>
                          </p>
                      </div>
                  </div>
              );
          })}
            </div>
        );
    }

    return null;
};

export const Tooltip = memo(_Tooltip);
