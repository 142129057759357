export function Lego({
    width = 24,
    height,
}:{
    width?:number;
    height?:number;
}) {
    const w = width;
    const h = height ?? width;

    return (
        <svg
            width={w}
            height={h}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m22.836 6.829-4.34-2.17C17.956 3.68 16.613 3 15 3c-2.103 0-3.75 1.153-3.75 2.625 0 .208.034.415.1.612l-1.83.915A5.033 5.033 0 0 0 7.5 6.75c-2.103 0-3.75 1.153-3.75 2.625 0 .208.034.415.1.612L1.163 11.33A.75.75 0 0 0 .75 12v6a.75.75 0 0 0 .414.671l6 3a.75.75 0 0 0 .672 0l15-7.5a.75.75 0 0 0 .414-.671v-6a.75.75 0 0 0-.414-.671ZM7.5 14.162 3.177 12l1.604-.802C5.46 11.694 6.42 12 7.5 12c2.103 0 3.75-1.153 3.75-2.625a2.042 2.042 0 0 0-.408-1.207l1.44-.72c.678.496 1.638.802 2.718.802 1.723 0 3.14-.774 3.595-1.864L20.823 7.5 7.5 14.162ZM15 4.5c1.288 0 2.25.594 2.25 1.125 0 .53-.962 1.125-2.25 1.125s-2.25-.594-2.25-1.125c0-.53.962-1.125 2.25-1.125ZM7.5 8.25c1.288 0 2.25.594 2.25 1.125 0 .53-.962 1.125-2.25 1.125-.907 0-1.65-.294-2.013-.656a.778.778 0 0 0-.079-.087.62.62 0 0 1-.158-.382c0-.53.962-1.125 2.25-1.125Zm-5.25 4.963 4.5 2.25v4.324l-4.5-2.25v-4.324Zm6 6.574v-4.324l13.5-6.75v4.324l-13.5 6.75Z"
                fill="#3B82F6"
            />
        </svg>
    );
}
