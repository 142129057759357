export function CircularCaretDown({
    width = 24,
    height,
}:{
    width?:number;
    height?:number;
}) {
    const w = width;
    const h = height ?? width;

    return (
        <svg
            viewBox="0 0 24 24"
            width={w}
            height={h}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 2.25A9.75 9.75 0 1 0 21.75 12 9.76 9.76 0 0 0 12 2.25Zm4.28 8.78-3.75 3.75a.747.747 0 0 1-1.06 0l-3.75-3.75a.75.75 0 1 1 1.06-1.06L12 13.19l3.22-3.22a.751.751 0 0 1 1.06 1.06Z"
                fill="#3B82F6"
            />
        </svg>
    );
}
