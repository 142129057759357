export function CaretLeft({
    color = '#475569',
}:{
    color?:string;
}
) {
    return (
        <svg
            viewBox="0 0 25 25"
            height={24}
            width={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.28 19.47a.751.751 0 0 1-1.062 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.061 1.06L9.31 12.5l6.97 6.97Z"
                fill={color}
            />
        </svg>
    );
}
