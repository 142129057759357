export function ArrowsClockwise({
    width = 24,
    height,
    color = '#3B82F6',
}:{
    width?:number;
    height?:number;
    color?:string;
}) {
    const w = width;
    const h = height ?? width;

    return (
        <svg
            width={w}
            height={h}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21 4.5V9a.75.75 0 0 1-.75.75h-4.5a.75.75 0 0 1-.53-1.28l1.716-1.72a7.451 7.451 0 0 0-5.13-2.07h-.042a7.455 7.455 0 0 0-5.24 2.137.75.75 0 0 1-1.048-1.073A9 9 0 0 1 18 5.691l1.721-1.721A.75.75 0 0 1 21 4.5Zm-3.524 12.684a7.5 7.5 0 0 1-10.412.066l1.717-1.716a.75.75 0 0 0-.531-1.284h-4.5A.75.75 0 0 0 3 15v4.5a.75.75 0 0 0 1.28.53L6 18.31a8.946 8.946 0 0 0 6.188 2.508h.05a8.94 8.94 0 0 0 6.287-2.562.75.75 0 0 0-1.048-1.072h-.001Z"
                fill={color}
            />
        </svg>
    );
}
