import { type PropsWithChildren } from 'react';

import Button from '../Button';
import * as css from './Form.css';

type FormProps = {
    title:string;
    subtitle?:string;
    actionText:string;
    onSubmit():void;
    isSubmitting?:boolean;
    error?:string;
};

const Form = ({
    title,
    subtitle,
    actionText,
    onSubmit,
    isSubmitting = false,
    error,
    children,
}:PropsWithChildren<FormProps>) => {
    return (
        <form onSubmit={onSubmit}>
            <div className={css.formContainer}>
                <h2>{title}</h2>
                {subtitle && <p>{subtitle}</p>}

                {error && <div className={css.error}>{error}</div>}

                {children}

                <Button type="submit" style={{ width: '100%' }}>
                    {isSubmitting
                        ? (
                            <div className={css.spinner} />
                        )
                        : (
                            <span style={{ fontSize: '0.8em' }}>{actionText}</span>
                        )}
                </Button>
            </div>
        </form>
    );
};

export default Form;
