import { useForm } from 'react-hook-form';
import { useState } from 'react';

import Container from '../../components/ui/Container';
import Form from '../../components/ui/Form';
import Input from '../../components/ui/Input';
import { useSession } from '../../utils/hooks/useSession';
import Card from '../../components/ui/Card';

interface ChangePasswordFormValues {
    newPassword:string;
    confirmPassword:string;
}

type ChangePasswordScreenProps = {
    subtitle?:string;
    onPasswordChanged?():void;
};

export const ChangePasswordScreen = ({
    subtitle,
    onPasswordChanged,
}:ChangePasswordScreenProps) => {
    const { isChangingPassword, changePassword } = useSession();
    const { register, handleSubmit } = useForm<ChangePasswordFormValues>();

    const [error, setError] = useState<string | undefined>(undefined);

    const handleChangePassword = async(values:ChangePasswordFormValues) => {
        const { newPassword, confirmPassword } = values;
        if(newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            await changePassword(newPassword);
            onPasswordChanged?.();
        } catch(error) {
            setError((error as Error).message);
        }
    };

    return (
        <Container>
            <Card>
                <Form
                    onSubmit={handleSubmit(handleChangePassword)}
                    title="Change your password"
                    actionText="Change password"
                    subtitle={subtitle}
                    isSubmitting={isChangingPassword}
                    error={error}
                >
                    <Input
                        type="password"
                        label="New password"
                        {...register('newPassword')}
                        placeholder="Enter your new password"
                    />
                    <Input
                        type="password"
                        label="Confirm password"
                        {...register('confirmPassword')}
                        placeholder="Confirm your new password"
                    />
                </Form>
            </Card>
        </Container>
    );
};
