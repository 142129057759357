import _ from 'lodash';
import { SupabaseService, TableInsert } from './services/supabase.service';
import { Mutable, UINodeRelation, Layout, RedefineProperty, ALL_DEVICES, LayoutCustomization, DEFAULT_KEY_PARAMETER_ID } from '@common';
import { HttpException, HttpStatus } from './http-exception';

export class LayoutsEndpointPlaceholder {

    constructor(
        private readonly _supabase:SupabaseService
    ) { }

    /**
     * Endpoint for getting the user's components and customizations
     */
    async getUserLayouts(
        userID:string,
    ):Promise<Layout[]> {

        if(!userID) {
            console.error('Invalid user ID');
            return [];
        }

        // fetch all user's layouts
        const { data: layoutsFromDB, error: layoutsError } = await this._supabase
            .from('layouts')
            .select('*, layout_customizations(*)')
            .eq('owner_user_id', userID);

        if(layoutsError) {
            console.error('error fetching user layouts:', layoutsError);
            return [];
        }

        // convert the fetched layouts to the Layout type
        const layouts = _.map(
            layoutsFromDB,
            l => ({
                id: l.id,
                owner_user_id: l.owner_user_id,
                name: l.name,
                customizations: _.map(
                    l.layout_customizations,
                    c => _.omit(c, 'id', 'layout_id') as UINodeRelation
                ),
            }) as Layout
        );

        // make sure default stuff is present
        this.enforceDefaults(layouts, userID);

        return layouts;
    }

    /**
    * Endpoint for updating user's components and customizations
    */
    async updateUserLayouts(
        userID:string,
        layouts:Layout[],
    ):Promise<Layout[]> {

        try {
            // check if layouts is an arrays
            if(!_.isArray(layouts))
                throw new HttpException('Invalid user layouts', HttpStatus.BAD_REQUEST);

            // enforce owner and correct ids
            for(const l of layouts) {
                const layout = l as RedefineProperty<Mutable<Layout>, 'id', number | undefined>;
                layout.owner_user_id = userID;
                if(!_.isFinite(layout.id) || Number(layout.id) < 0)
                    layout.id = undefined; // create a new layout since the id is invalid
            }

            // check for invalid customizations
            const { data: uiNodes, error: uiNodesError }
                = await this._supabase.withLimit(
                    10_000,
                    this._supabase
                        .from('ui_nodes')
                        .select('id')
                );
            if(uiNodesError)
                throw new HttpException('error fetching ui_nodes:' + JSON.stringify(uiNodesError), HttpStatus.INTERNAL_SERVER_ERROR);

            const legitUINodeIDs = _(uiNodes)
                .keyBy(c => c.id)
                .mapValues(() => true)
                .value();

            const invalidCustomizations = _(layouts)
                .flatMap(l => l.customizations)
                .filter(
                    // parent must be null or reference existing node
                    r => r.parent_ui_node_id && !legitUINodeIDs[r.parent_ui_node_id]
                    // child must reference existing node
                    || !legitUINodeIDs[r.child_ui_node_id]
                    // child_display_order must be null, undefined, or a finite positive number
                    || !(_.isNil(r.child_display_order) || _.isFinite(r.child_display_order) && Number(r.child_display_order) >= 0)
                    // child_metadata must be an object
                    || !_.isObject(r.child_metadata)
                )
                .value();
            if(!_.isEmpty(invalidCustomizations))
                throw new HttpException('Invalid layout customizations: ' + JSON.stringify(invalidCustomizations), HttpStatus.BAD_REQUEST);

            // fetch existing layouts
            const { data: existingLayouts, error: existingLayoutsError } = await this._supabase
                .from('layouts')
                .select('id, layout_customizations(id, layout_id, parent_ui_node_id, child_ui_node_id)')
                .eq('owner_user_id', userID);

            if(existingLayoutsError)
                throw new HttpException('error fetching existing layouts:' + JSON.stringify(existingLayoutsError), HttpStatus.INTERNAL_SERVER_ERROR);

            // full join layouts and existingLayouts on id
            const newLayoutsWithNoID = _.filter(layouts, l => !(l.id > 0));
            const layoutsByID = _(layouts).without(...newLayoutsWithNoID).keyBy(l => l.id).value();
            const existingLayoutsByID = _.keyBy(existingLayouts, l => l.id);
            const allLayoutIDs
                = _.union(
                    _.keys(layoutsByID),
                    _.keys(existingLayoutsByID)
                );

            // group layouts by delete and upsert
            const { layoutsToUpsert, layoutsToDelete } = _(allLayoutIDs)
                .map(id =>
                    ({
                        layout: layoutsByID[id],
                        existingLayout: existingLayoutsByID[id]
                    })
                )
                .concat(
                    _.map(newLayoutsWithNoID, layout =>
                        ({
                            layout,
                            existingLayout: null as unknown as (typeof existingLayouts[0])
                        })
                    )
                )
                .groupBy(({ layout }) => layout
                    ? 'layoutsToUpsert'
                    : 'layoutsToDelete'
                )
                .value();

            // delete layouts
            if(!_.isEmpty(layoutsToDelete)) {
                const idsToDelete = _.map(layoutsToDelete, d => d.existingLayout.id);
                const { error: deleteError } = await this._supabase
                    .from('layouts')
                    .delete()
                    .in('id', idsToDelete);

                if(deleteError)
                    throw new HttpException('error deleting layouts:' + JSON.stringify(deleteError), HttpStatus.INTERNAL_SERVER_ERROR);
            }

            // upsert layouts
            if(!_.isEmpty(layoutsToUpsert)) {
                const layoutsToSave = _.map(
                    layoutsToUpsert,
                    ({ layout: { id, owner_user_id, name } }) =>
                        id > 0
                            ? { id, owner_user_id, name }
                            : { owner_user_id, name }
                );
                const { data: savedLayouts, error: upsertError } = await this._supabase
                    .from('layouts')
                    .upsert(layoutsToSave, { defaultToNull: false })
                    .select();
                
                if(upsertError)
                    throw new HttpException('error inserting/updating layouts:' + JSON.stringify(upsertError), HttpStatus.INTERNAL_SERVER_ERROR);

                // apply new ids to new layouts
                for(const [before, after] of _.zip(layoutsToSave, savedLayouts))
                    if(before && after)
                        before.id = after.id; // hope this works GIFLENS-https://media3.giphy.com/media/JULfVYQH3XkCxMV0QP/200.gif
            }

            // assign layout ids to customizations
            const customizationsChanges
                = _.map(
                    layoutsToUpsert,
                    l => ({
                        fromClient: _.map(l.layout.customizations, c => ({ layout_id: l.layout.id, ...c })),
                        fromDB: l.existingLayout?.layout_customizations ?? []
                    })
                );


            // full join customizations and existingCustomizations on id
            const uniqueCustomizations = _(customizationsChanges)
                .flatMap(l => l.fromClient)
                .keyBy(c => `${c.layout_id}/${c.parent_ui_node_id}/${c.child_ui_node_id}`)
                .value();
            const uniqueExistingCustomizations = _(customizationsChanges)
                .flatMap(l => l.fromDB)
                .keyBy(c => `${c.layout_id}/${c.parent_ui_node_id}/${c.child_ui_node_id}`)
                .value();

            const allParentChildCombinations
                = _.union(
                    _.keys(uniqueCustomizations),
                    _.keys(uniqueExistingCustomizations)
                );

            // group customizations by delete and upsert
            const { customizationsToUpsert, customizationsToDelete } = _(allParentChildCombinations)
                .map(key =>
                    ({
                        fromClient: uniqueCustomizations[key],
                        fromDB: uniqueExistingCustomizations[key]
                    })
                )
                .groupBy(({ fromClient }) => fromClient
                    ? 'customizationsToUpsert'
                    : 'customizationsToDelete'
                )
                .value();

            // delete customizations
            if(!_.isEmpty(customizationsToDelete)) {
                const idsToDelete = _.map(customizationsToDelete, d => d.fromDB.id);
                const { error: deleteError } = await this._supabase
                    .from('layout_customizations')
                    .delete()
                    .in('id', idsToDelete);

                if(deleteError)
                    throw new HttpException('error deleting customizations:' + JSON.stringify(deleteError), HttpStatus.INTERNAL_SERVER_ERROR);
            }

            // upsert customizations
            if(!_.isEmpty(customizationsToUpsert)) {
                const customizations
                    = _.map(
                        customizationsToUpsert,
                        ({
                            fromDB,
                            fromClient: {
                                layout_id,
                                parent_ui_node_id,
                                child_ui_node_id,
                                child_display_order,
                                child_metadata,
                                show_child_on
                            }
                        }) => {
                            const dataToUpsert:TableInsert<'layout_customizations'> = {
                                id: fromDB?.id,
                                layout_id,
                                parent_ui_node_id,
                                child_ui_node_id,
                                child_display_order: _.isFinite(child_display_order)
                                    ? (Number(child_display_order) | 0)
                                    : null,
                                child_metadata: this.toObject(child_metadata),
                                show_child_on,
                            };
                            if(!dataToUpsert.id || !(dataToUpsert.id > 0))
                                delete dataToUpsert.id;
                            return dataToUpsert;
                        }
                    );
                const { error: upsertError } = await this._supabase
                    .from('layout_customizations')
                    .upsert(customizations, { defaultToNull: false });

                if(upsertError)
                    throw new HttpException('error inserting/updating customizations:' + JSON.stringify(upsertError), HttpStatus.INTERNAL_SERVER_ERROR);
            }

            return this.getUserLayouts(userID);
        } catch(error) {
            console.error(error);
            return layouts;
        }
    }

    private enforceDefaults(layouts:Layout[], userID:string) {
        if(_.isEmpty(layouts))
            layouts.push({
                id: -1,
                owner_user_id: userID,
                name: 'Default layout',
                customizations: [],
            });
        
        for(const layout of layouts) {
            const hadNoCustomizationsBefore = _.isEmpty(layout.customizations);
            // enforce the presence of activity_navigation on both kiosk and floor
            const activityNavigations = _.filter(layout.customizations, c => _.startsWith(c.child_ui_node_id, 'activity_navigation.'));
            let [kiosk, floor] = _.map(
                ['kiosk', 'floor'],
                device => _.find(
                    activityNavigations,
                    nav => _.includes(nav.show_child_on, device))
            );
            if(!kiosk && !floor) // if neither is present
                layout.customizations.push(kiosk = floor =
                {
                    parent_ui_node_id: null,
                    child_ui_node_id: 'activity_navigation.default',
                    child_display_order: null,
                    show_child_on: ALL_DEVICES,
                    child_metadata: {},
                } as LayoutCustomization);

            if(!kiosk)
                layout.customizations.push(kiosk =
                {
                    parent_ui_node_id: null,
                    child_ui_node_id: 'activity_navigation.kiosk',
                    child_display_order: null,
                    show_child_on: ['kiosk'],
                    child_metadata: {},
                } as LayoutCustomization);

            if(!floor)
                layout.customizations.push(floor =
                {
                    parent_ui_node_id: null,
                    child_ui_node_id: 'activity_navigation.floor',
                    child_display_order: null,
                    show_child_on: ['floor'],
                    child_metadata: {},
                } as LayoutCustomization);
            
            // enforce at least one parameter on activity_navigation
            for(const nav of [kiosk, floor]) {
                const parameterOnDevice = _.find(
                    layout.customizations,
                    c => c.parent_ui_node_id === nav.child_ui_node_id
                    && _.startsWith(c.child_ui_node_id, 'parameter.')
                );
                if(!parameterOnDevice)
                    // this only gets executed twice if kiosk and floor are different activity_navigations
                    layout.customizations.push({
                        parent_ui_node_id: nav.child_ui_node_id,
                        child_ui_node_id: `parameter.${DEFAULT_KEY_PARAMETER_ID}`,
                        child_display_order: null,
                        show_child_on: null,
                        child_metadata: {},
                    } as LayoutCustomization);
            }

            // enforce presence of swing_foundations on kiosk
            const swingFoundationOnKiosk = _.find(
                layout.customizations,
                x => x.child_ui_node_id === 'swing_foundations.default_kiosk'
            );
            if(!swingFoundationOnKiosk)
                layout.customizations.push({
                    parent_ui_node_id: null,
                    child_ui_node_id: 'swing_foundations.default_kiosk',
                    child_display_order: 0,
                    show_child_on: null,
                    child_metadata: {}
                } as LayoutCustomization);

            // enforce presence of the first custom module on kiosk
            // or else the layout editor wont show custom modules at all
            const customModuleOnKiosk = _.find(
                layout.customizations,
                x => x.child_ui_node_id === 'module.custom_0001'
            );
            if(!customModuleOnKiosk)
                layout.customizations.push({
                    parent_ui_node_id: null,
                    child_ui_node_id: 'module.custom_0001',
                    child_display_order: 0,
                    show_child_on: [],
                    child_metadata: {
                        title: 'My Custom Module',
                    }
                } as LayoutCustomization);

            // enforce presence of swing_foundations on floor if there were customizations yet on this layout
            if(hadNoCustomizationsBefore) {
                layout.customizations.push(
                    {
                        parent_ui_node_id: null,
                        child_ui_node_id: 'page.floor_0001',
                        child_display_order: 0,
                        show_child_on: ['floor'],
                        child_metadata: {}
                    } as LayoutCustomization,
                    {
                        parent_ui_node_id: 'page.floor_0001',
                        child_ui_node_id: 'swing_foundations.default_floor_p1',
                        child_display_order: 0,
                        show_child_on: ['floor'],
                        child_metadata: {}
                    } as LayoutCustomization,
                    {
                        parent_ui_node_id: 'page.floor_0001',
                        child_ui_node_id: 'swing_foundations.default_floor_p2',
                        child_display_order: 1,
                        show_child_on: ['floor'],
                        child_metadata: {}
                    } as LayoutCustomization,
                    {
                        parent_ui_node_id: 'page.floor_0001',
                        child_ui_node_id: 'swing_foundations.default_floor_p4',
                        child_display_order: 2,
                        show_child_on: ['floor'],
                        child_metadata: {}
                    } as LayoutCustomization,
                    {
                        parent_ui_node_id: 'page.floor_0001',
                        child_ui_node_id: 'swing_foundations.default_floor_p7',
                        child_display_order: 3,
                        show_child_on: ['floor'],
                        child_metadata: {}
                    } as LayoutCustomization,
                );
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private toObject(value:any):_.Dictionary<any> {
        return _.isObject(value)
            ? value
            : {};
    }
}
