import { useNavigate } from 'react-router-dom';
import { motion } from 'motion/react';
import _ from 'lodash';
import { Observable } from 'rxjs';

import { localize, isType,  unitConversionMap, Nil, Unit, deepComparison, DEFAULT_KEY_PARAMETER_ID, Swing } from '@common';
import { ImplementationOf, UIComponentValues } from '../../UIComponent.types';
import { useObservable } from '../../../../utils/hooks/useObservable';
import { map, distinctUntilChanged } from 'rxjs/operators';
import ActivityNavigationItem from './ActivityNavigationItem';

import * as css from './ActivityNavigation.css';
import { useGlobalStore } from '../../../../state/globalStore';
import { useContext, useState } from 'react';
import { UserSettingsContext } from '../../../../utils/contexts/UserSettingsContext';
import SwingNameModal from '../../../SwingNameModal';

export const ActivityNavigation:ImplementationOf<'activity_navigation'>
= ({ currentDevice, children, values$ }) => {
    
    const parameterNode = _(children)
        .map(x => x.currentNode)
        .find(x => isType(x, 'parameter'));
    
    const parameterID = parameterNode?.parameter?.id ?? DEFAULT_KEY_PARAMETER_ID;

    const parameterName
        = parameterNode?.name?.value //! disgusting hack because we don't localize anything yet
        ?? _.startCase(parameterID); //! disgusting hack because we don't localize anything yet
    
    const parameterUnit = parameterNode?.parameter?.unit ?? 'm';

    const props:Props = {
        parameterName,
        parameterID,
        parameterUnit,
        values$
    };

    switch(currentDevice) {
        case 'kiosk':
            return <Kiosk {...props} />;
        case 'floor':
            return <Floor {...props} />;
        default:
            return <div></div>;
    }
};

const getDisplayValue = (parameterID:string, parameterUnit:Unit, swing:Swing|Nil) => {
    const parameter
        = swing?.fullAnalysis?.data?.analysis?.parameter_values?.[parameterID]
        ?? swing?.quickAnalysis?.data?.analysis?.parameter_values?.[parameterID];

    if(parameter) {
        const p = unitConversionMap[parameter.unit](parameter.value);
        return `${p.value}${p.symbol}`;
    }

    return '— ' + unitConversionMap[parameterUnit](undefined).symbol;
};

const useAllSwings = (parameterID:string, parameterUnit:Unit, values$:Observable<UIComponentValues>) =>
    useObservable(
        () => values$.pipe(
            map(values => _(values.allSwings)
                .compact() // shallow copy with nil values removed
                .orderBy(s => s.createdAt, 'desc') // latest swing appears on top
                .map((swing, index, list) => ({

                    // the number value to display
                    value: getDisplayValue(parameterID, parameterUnit, swing),

                    // the header value to display
                    header: (list.length - index) + '',

                    // rest of the properties of the swing
                    ...swing,
                    
                    // original swing object (for assigning to comparison swing)
                    original: swing
                }))
                .value()
            ),
            distinctUntilChanged((a, b) => // re-render is only triggered if this returns false
                a.length === b.length
                && _(a).zip(b)
                    .every(([x, y]) =>
                        // pairwise, compare only properties that can trigger a re-render
                        x?.id === y?.id
                        && x?.value === y?.value
                        && x?.isFavorite === y?.isFavorite
                    )
            )
        ),
        [values$, parameterID, parameterUnit]
    );


interface Props {
    readonly parameterName:string;
    readonly parameterID:string;
    readonly parameterUnit:Unit;
    readonly values$:Observable<UIComponentValues>;
}

function Kiosk({ parameterName, parameterID, parameterUnit, values$ }:Props) {
    const navigate = useNavigate();
    const allSwings = useAllSwings(parameterID, parameterUnit, values$); // ← please do not remove this
    const { markSwingAsFavorite } = useContext(UserSettingsContext);
    const setComparisonSwingID = useGlobalStore(state => state.actions.setComparisonSwingID);
    const [showSwingNameModal, setShowSwingNameModal] = useState(false);
    const [tempSwingId, setTempSwingId] = useState<number|null>(null);
    const toggleSwingFavorite = useGlobalStore(state => state.actions.toggleSwingFavorite);
    
    const [selectedSwingAnalysisID, comparisonSwingAnalysisID, setValues] = useObservable(
        () => values$.pipe(
            map(values => [
                values.swing?.id,
                values.comparisonSwing?.id,
                values.setValues
            ] as const),
            distinctUntilChanged(deepComparison)
        ),
        [values$]
    );

    const onToggleComparison = (val:boolean, id:number) => {
        const swing = _.find(allSwings, s => s.id === id)?.original;

        if(!val)
            setComparisonSwingID(null);

        setValues?.({
            comparisonSwing: comparisonSwingAnalysisID === id
                ? undefined
                : swing
        });
    };
    
    const onToggleFavorite = (val:boolean, id:number) => {
        if(val) {
            setTempSwingId(id);
            setShowSwingNameModal(true);
        } else {
            toggleSwingFavorite(false, id, null);
            markSwingAsFavorite({ id, name: null });
        }
    };
    
    const onSwingNameSubmit = (name:string) => {
        if(tempSwingId) {
            toggleSwingFavorite(true, tempSwingId, name);
            markSwingAsFavorite({ id: tempSwingId, name });
            
            // We don't want to mark the selected swing as comparison
            // If there is only one swing in the activity then that swing is always selected
            if(allSwings.length > 1) {
                onToggleComparison(true, tempSwingId);
            }
            
            setTempSwingId(null);
        }
    };
    
    const onSelect = (swing:Swing, id:number) => {
        setValues?.({ swing });
        // Comparison swing cannot also be the selected swing
        if(comparisonSwingAnalysisID === id) {
            onToggleComparison(false, id);
        }
    };

    return (
        <div className={css.kiosk.root}>
            <SwingNameModal
                isOpen={showSwingNameModal}
                setIsOpen={(val) => setShowSwingNameModal(val)}
                onSubmit={(name) => onSwingNameSubmit(name)}
            />
            <div className={css.kiosk.header}>
                <h3 className={css.kiosk.headerText}>
                    {localize( 'activity_navigation.header' )}
                </h3>
                <button
                    onClick={() => {
                        // Navigate to LayoutEditor page and open the key-parameter modal.
                        navigate('/kiosk/layout-editor', {state: {isEditingKeyParameter: true}});
                    }}
                    className={css.kiosk.activityNavigationParameter}
                >
                    <h4 className={css.kiosk.activityNavigationParameterText}>
                        {parameterName}
                    </h4>
                </button>
                <div className={css.kiosk.subheader}>
                    <p className={css.kiosk.subheaderText}>
                        {localize('activity_navigation.subheader')}
                    </p>
                </div>
            </div>
            <div className={css.kiosk.sidebar}>
                <motion.ul className={css.kiosk.list}>{
                    _.map(allSwings, swing =>
                        <ActivityNavigationItem
                            key={swing.id}
                            header={swing.header}
                            swingID={swing.id}
                            label={getDisplayValue(parameterID, parameterUnit, swing)}
                            status={swing.fullAnalysis
                                ? 'ready'
                                : 'analyzing'
                            }
                            isSelected={selectedSwingAnalysisID === swing.id}
                            isComparison={comparisonSwingAnalysisID === swing.id}
                            isFavorite={swing.isFavorite}
                            onSelect={() => {
                                onSelect(swing, swing.id);
                            }}
                            onToggleComparison={(val) => onToggleComparison(val, swing.id)}
                            onToggleFavorite={(val) => onToggleFavorite(val, swing.id)}
                        />
                    )
                }</motion.ul>
            </div>
        </div>
    );
}

function Floor({ parameterName, parameterID, parameterUnit, values$ }:Props) {

    const defaultDisplayValue = getDisplayValue(parameterID, parameterUnit, null);
    const allSwings = useAllSwings(parameterID, parameterUnit, values$); // ← please do not remove this
    const latestSwing = allSwings[0];

    // GIFLENS-https://media4.giphy.com/media/Ty9Sg8oHghPWg/200.gif
    const isAnalyzing = useGlobalStore((state) => state.lastBeastStatus === 'ANALYZING_SWING');

    return (
        <div className={css.floor.root}>
            <div className={css.floor.header}>
                <p className={css.floor.headerText}>
                    {localize('activity_navigation.header')}
                </p>
                <p className={css.floor.parameterName}>
                    {parameterName}
                </p>
            </div>
            <ul className={css.floor.list}>
                {(isAnalyzing || !latestSwing?.id) && !!latestSwing && (
                    <li>
                        <span
                            className={css.floor.item({ isAnalyzing })}
                        >
                            <div className={css.floor.itemNumber}>
                                <span className={css.floor.itemNumberText} >
                                    {allSwings.length + 1}
                                </span>
                            </div>
                            <div className={css.floor.itemValue}>
                                <p className={css.floor.itemValueText}>
                                    {defaultDisplayValue}
                                </p>
                            </div>
                        </span>
                    </li>
                )}
                {_.map(allSwings, ({ value, id, header }, index) => {
                    const latestAnalysisID = latestSwing?.id ?? -1;
                    return (
                        <li key={index}>
                            <span
                                className={css.floor.item({ isAnalyzing: id === latestAnalysisID })}
                            >
                                <div className={css.floor.itemNumber}>
                                    <span className={css.floor.itemNumberText}>
                                        {header}
                                    </span>
                                </div>
                                <div className={css.floor.itemValue}>
                                    <p className={css.floor.itemValueText}>
                                        {value}
                                    </p>
                                </div>
                            </span>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
