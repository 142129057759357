import { Activity } from './activity.types';
import { Club } from './club.types';
import { Swing } from './swing.types';
import { SupabaseClient } from '@supabase/supabase-js';
import { Tag } from './tag.types';
import _ from 'lodash';
export const fetchAllSwingsForActivity = async(supabase:SupabaseClient, activityId:number):Promise<Swing[]> => {
    const { data, error } = await supabase
        .rpc('get_user_swings_for_activity', {
            activity_id_param: activityId,
        }).returns<Swing[]>();

    if(error) {
        throw error;
    }

    return data;
};

export const fetchAllUserFavoriteSwings = async(supabase:SupabaseClient, userId:string) => {

    const { data, error } = await supabase
        .rpc('get_user_favorite_swings', {
            user_id_param: userId
        });

    if(error) {
        throw error;
    }
    
    return data;
};

export const fetchAllUserActivites = async(supabase:SupabaseClient, userId:string):Promise<Activity[]> => {
    const { data, error } = await supabase.rpc('get_user_activities', {
        p_user_id: userId,
        p_limit: 200,
        p_offset: 0
    }).returns<Activity[]>();

    if(error) {
        throw error;
    }

    return data;
};

export const updateActivityClub = async(supabase:SupabaseClient, club:Club, activityId:number) => {
    const { error: activityUpdateError } = await supabase
        .from('activities')
        .update({ club_id: club.id })
        .eq('id', activityId);

    if(activityUpdateError) {
        console.error('Error updating activity:', activityUpdateError);
        return;
    }
};

export const updateActivityTags = async(supabase:SupabaseClient, tags:Tag[], activityId:number) => {

    const { error: tagsDeleteError } = await supabase
        .from('activity_tag_link')
        .delete()
        .eq('activity_id', activityId);

    if(tagsDeleteError) {
        console.error('Error clearing activity tags:', tagsDeleteError);
        return;
    }

    const tagsToInsert = tags.map((tag) => ({
        tag_id: tag.id,
        activity_id: activityId,
    }));

    const { error: tagsInsertError } = await supabase
        .from('activity_tag_link')
        .insert(tagsToInsert);

    if(tagsInsertError) {
        console.error('Error inserting activity tags:', tagsInsertError);
        return;
    }
};

/**
 * Creates a new activity in the database and returns the id of the newly created activity.
 */
export const createNewActivity = async(
    client:SupabaseClient,
    userId:string,
    boothSessionId:number,
    club?:Club,
    tags?:Tag[],
):Promise<number | null> => {
    const { error, data } = await client
        .from('activities')
        .insert({
            booth_session_id: boothSessionId,
            user_id: userId,
            club_id: club
                ? club.id
                : 17, // fallback to club_id 17: 8 Iron
        })
        .select('id')
        .single();

    if(tags?.length && data) {
        const tagIds = _.map(tags, (tag) => tag.id);
        await client
            .from('activity_tag_link')
            .insert(_.map(tagIds, (tagId) => ({ activity_id: data.id, tag_id: tagId })));
    }

    if(error) {
        console.log('ERROR CREATING ACTIVITY', error);
        throw error;
    }

    if(!data) {
        return null;
    }

    return data.id;
};

export const getActivityClub = async(
    supabase:SupabaseClient,
    activityId:number,
) => {
    const { data: club } = await supabase
        .from('activities')
        .select('*')
        .eq('id', activityId)
        .select('club:clubs(*)')
        .single<{ club:Club }>();

    return club?.club;
};

export const createNewTag = async(supabase:SupabaseClient, tag:string) => {
    const {
        data: { user },
    } = await supabase.auth.getUser();

    const { data: allUserTags } = await supabase
        .from('activity_tags')
        .select('*')
        .eq('user_id', user?.id);

    // if a tag with the same name has existed and been deleted before, it is marked as deleted but not deleted from the database
    const newTagExists = _.find(allUserTags, (userTag) => userTag.name === tag);
    if(newTagExists) {
        const { data: newTag, error } = await supabase
            .from('activity_tags')
            .update({ deleted: false, deleted_at: null })
            .eq('id', newTagExists.id)
            .select();

        if(error) throw error;
        return newTag[0];
    }

    const { data, error } = await supabase
        .from('activity_tags')
        .insert({
            user_id: user?.id,
            name: tag,
        })
        .select();

    if(error) throw error;

    return data[0];
};

export const getUsersTags = async(supabase:SupabaseClient) => {
    const {
        data: { user },
    } = await supabase.auth.getUser();

    const { data: tags, error } = await supabase
        .from('activity_tags')
        .select('*')
        .eq('user_id', user?.id);

    if(error) throw error;

    return _.filter(tags, (tag) => !tag.deleted);
};

export const getActivityTags = async(
    supabase:SupabaseClient,
    activityId:number,
) => {
    const { data, error } = await supabase
        .from('activity_tag_link')
        .select('*')
        .eq('activity_id', activityId)
        .select('tag:activity_tags(*)')
        .returns<{ tag:Tag }[]>();

    if(error) throw error;

    const tags:Tag[] = _(data)
        .map(({ tag }) => tag)
        .filter(({ deleted }) => !deleted)
        .value();

    return tags;
};

export const editTag = async(
    supabase:SupabaseClient,
    id:number,
    newName:string,
) => {
    const { error } = await supabase
        .from('activity_tags')
        .update({ name: newName })
        .eq('id', id);

    if(error) throw error;
};

export const deleteTag = async(supabase:SupabaseClient, id:number) => {
    const { error } = await supabase
        .from('activity_tags')
        .update({ deleted: true })
        .eq('id', id);

    if(error) throw error;
};