import { ActivityNavigation } from './ActivityNavigation/ActivityNavigation';
import { Column } from './Column';
import { Graph } from './Graph';
import { GraphLine } from './Graph/GraphLine';
import { Grid } from './Grid/Grid';
import { Module } from './Module/Module';
import { Page } from './Page/Page';
import { Parameter } from './Parameter/Parameter';
import { Row } from './Row';
import { SwingFoundations } from './SwingFoundations/SwingFoundations';

import { UIComponentImplementationProps as PropsFor } from '../UIComponent.types';

export function createImplementation(props:PropsFor):JSX.Element {
    switch(props.node?.type) {
        case 'activity_navigation': return <ActivityNavigation {...props as PropsFor<'activity_navigation'>} />;
        case 'column':              return <Column             {...props as PropsFor<'column'>             } />;
        case 'graph':               return <Graph              {...props as PropsFor<'graph'>              } />;
        case 'graph_line':          return <GraphLine          {...props as PropsFor<'graph_line'>         } />;
        case 'grid':                return <Grid               {...props as PropsFor<'grid'>               } />;
        case 'module':              return <Module             {...props as PropsFor<'module'>             } />;
        case 'page':                return <Page               {...props as PropsFor<'page'>               } />;
        case 'parameter':           return <Parameter          {...props as PropsFor<'parameter'>          } />;
        case 'row':                 return <Row                {...props as PropsFor<'row'>                } />;
        case 'swing_foundations':   return <SwingFoundations   {...props as PropsFor<'swing_foundations'>  } />;
        default:                    return <> </>;
    }
}
