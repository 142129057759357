export function CameraIcon({
    width = 22,
    height,
    color = '#3B82F6',
}:{
    width?:number;
    height?:number;
    color?:string;
}) {
    const w = width;
    const h = height ?? width;

    return (
        <svg
            width={w}
            height={h}
            viewBox="0 0 23 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17 1.75V12.25C17 12.6478 16.842 13.0294 16.5607 13.3107C16.2794 13.592 15.8978 13.75 15.5 13.75H2C1.60218 13.75 1.22064 13.592 0.93934 13.3107C0.658035 13.0294 0.5 12.6478 0.5 12.25V1.75C0.5 1.35218 0.658035 0.970644 0.93934 0.68934C1.22064 0.408035 1.60218 0.25 2 0.25H15.5C15.8978 0.25 16.2794 0.408035 16.5607 0.68934C16.842 0.970644 17 1.35218 17 1.75ZM22.4375 1.77344C22.3317 1.74755 22.2216 1.74435 22.1145 1.76406C22.0073 1.78378 21.9056 1.82595 21.8159 1.88781L18.6669 3.98688C18.6155 4.02115 18.5734 4.06758 18.5443 4.12204C18.5152 4.1765 18.5 4.23731 18.5 4.29906V9.70094C18.5 9.76269 18.5152 9.8235 18.5443 9.87796C18.5734 9.93242 18.6155 9.97885 18.6669 10.0131L21.8337 12.1244C21.9523 12.2034 22.0909 12.2471 22.2333 12.2503C22.3758 12.2534 22.5162 12.2159 22.6381 12.1422C22.7509 12.0705 22.8434 11.971 22.9068 11.8533C22.9702 11.7356 23.0022 11.6036 23 11.47V2.5C23.0001 2.33366 22.9449 2.17202 22.843 2.04049C22.7412 1.90897 22.5986 1.81502 22.4375 1.77344Z"
                fill={color}
            />
        </svg>
    );
}
