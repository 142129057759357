import { PositionNumber, SwingPosition } from './analysis.types';
import { localize } from './l10n.placeholder';
import { Nil } from './type-utils';



export function inferSwingPosition<T extends SwingPosition>(value:`${T}_${string}`|Nil):T|null;
export function inferSwingPosition<T extends SwingPosition>(value:`${string}_${T}_${string}`|Nil):T|null;
export function inferSwingPosition<T extends SwingPosition>(value:`${string}${T}${string}`|Nil):T|null;
export function inferSwingPosition<T extends SwingPosition>(value:`${string}_${T}`|Nil):T|null;
export function inferSwingPosition(value:string|Nil):SwingPosition|null;
export function inferSwingPosition(value:string|Nil):SwingPosition|null {
    // find 'p' followed by digits that is surrounded by non-word characters or start/end of the string
    return value
        && /(?<=^|[^a-z0-9])p\d+(?=[^a-z0-9]|$)/i.exec(value)?.[0].toLowerCase() as SwingPosition
        || null;
}

export const SWING_POSITIONS:SwingPosition[]
    = ['p1', 'p2', 'p3', 'p4', 'p5', 'p6', 'p7', 'p8', 'p9', 'p10'];

export function swingPositionToPositionNumber(position:SwingPosition|Nil):PositionNumber|null {
    const index = SWING_POSITIONS.indexOf(position as SwingPosition);
    return index === -1
        ? null
        : index + 1 as PositionNumber;
}

export function positionNumberToSwingPosition(position:PositionNumber|Nil):SwingPosition|null {
    return typeof(position) === 'number' && position > 0 && position < 11
        && `p${position}` as SwingPosition
        || null;
}

/** Removes 'p1' ... 'p10' from the text.
 * Works for both snake cased IDs and human readable text. */
export function removeSwingPosition(text:string) {
    return text?.replace(
        /(^|\W|_)p\d+(\W|_|$)/gi,
        (x, lhs, rhs) => lhs && !' _'.includes(lhs)
            ? lhs
            : rhs && !' _'.includes(rhs)
                ? rhs
                : lhs && rhs
                    ? lhs
                    : ''
    );
}

export function getLocalizedSwingPositionName(position:SwingPosition|Nil):string {
    return position
        ? localize(
            `swing_position.${position}`,
            position?.toUpperCase() ?? ''
        )
        : '';
}
