import type { ReactNode } from 'react';

import { typography } from '../../typography';

import * as css from './ToolPanel.css';

// Simple UI primitive for a common pattern

interface IToolPanelProps {
	title:string;
	children:ReactNode;
}

export function ToolPanel({ title, children }:IToolPanelProps) {
    return (
        <div className={css.toolPanel}>
            <div className={css.header}>
                <h3 className={typography({ variant: 'h3' })}>{title}</h3>
            </div>
            <div className={css.content}>{children}</div>
        </div>
    );
}
