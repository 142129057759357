import * as css from './Logo.css';

export default function ElvaLogo({ width = 100 }:{ width?:number }) {
    return (
        <div className={css.logo}>
            <svg
                className={css.svg}
                style={{ width }}
                viewBox="0 0 114 84"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M67.409.249c-2.44.19-4.92.498-7.416.917l-2.195.397a93.567 93.567 0 0 0-6.436 1.485l-3.276.942a94.704 94.704 0 0 0-5.638 1.925l-3.512 1.407a87.624 87.624 0 0 0-5.456 2.54l-2.798 1.49a75.048 75.048 0 0 0-5.505 3.386L23.46 15.94c-.573.414-1.136.836-1.69 1.264a76.477 76.477 0 0 0-3.689 3.04l-.765.682a67.388 67.388 0 0 0-5.063 5.08l-.381.43a56.322 56.322 0 0 0-4.451 5.769l-.086.128a45.573 45.573 0 0 0-3.5 6.233l-.1.22c-.975 2.158-1.721 4.317-2.227 6.455l-.144.642a28.37 28.37 0 0 0-.62 4.826c-.02.515-.026 1.028-.015 1.538l11.486-3.47-11.42 4.84a23.176 23.176 0 0 0 1.221 5.9l21.153-8.965-20.214 11.25a24.076 24.076 0 0 0 3.188 4.99l27.444-15.275L9.105 69.873a34.805 34.805 0 0 0 4.945 3.678l32.896-24.665-26.552 27.916a57 57 0 0 0 2.631 1.035c1.134.415 2.276.806 3.424 1.173l32.41-34.074-21.964 36.739c2.08.404 4.176.74 6.281 1.009L68.83 39.772l-9.8 43.724c2.043-.045 4.085-.145 6.123-.3l10.683-47.663 8.218 44.702c1.928-.46 3.84-.963 5.73-1.51l-8.526-46.378 26.747 39.51a114.28 114.28 0 0 0 5.318-2.685L88.465 32.454l2.354-1.552c2.47-1.853 4.824-4.028 6.423-6.712 1.6-2.683 3.057-5.988 2.816-9.76-.149-2.328-1.17-4.453-2.592-6.299-1.408-1.742-3.36-3.272-5.785-4.516l-1.754-.813c-2.411-1.01-5.205-1.767-8.328-2.213l-3.458-.38a67.23 67.23 0 0 0-8.658-.094L67.41.249Zm16.685 23.512-.836.999c-3.218 3.566-8.775 6.95-15.525 9.08-11.898 3.754-22.996 2.193-24.788-3.487-1.792-5.68 6.4-13.328 18.297-17.082a47.224 47.224 0 0 1 3.703-1.007l.407-.09c10.245-2.238 19.093-.435 20.677 4.583.681 2.158-.079 4.6-1.935 7.004Z"
                    fill="url(#a)"
                />
                <defs>
                    <radialGradient
                        id="a"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="matrix(26.1059 38.04702 -52.52303 36.03859 80.553 20.977)"
                    >
                        <stop stopColor="#D3FFB8" />
                        <stop offset="1" stopColor="#61FF00" />
                    </radialGradient>
                </defs>
            </svg>
        </div>
    );
}
