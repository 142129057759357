import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Container from '../../components/ui/Container';
import VStack from '../../components/ui/VStack';
import HStack from '../../components/ui/HStack';
import { colorVars } from '../../styles/colors.css';
import ElvaLogoName from '../../components/ui/Logo/ElvaLogoName';
import Divider from '../../components/ui/Divider';
import Input from '../../components/ui/Input';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import * as css from '../ConnectionPendingScreen/ConnectionPendingScreen.css';
import { useBoothSession } from '../../utils/hooks/useBoothSession';

type FormValues = {
    email:string;
    password:string;
};

type BoothLoginScreenProps = {
    returnTo:string;
};

export const BoothLoginScreen = ({ returnTo }:BoothLoginScreenProps) => {
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const { login } = useBoothSession();
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm<FormValues>();

    const handleLogin = async({ email, password }:FormValues) => {
        setIsLoading(true);
        try {
            await login(email, password);
            navigate(returnTo);
        } catch(error) {
            setError(error as string);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container>
            <Card>
                <VStack spacing={20}>
                    <HStack center style={{ justifyContent: 'space-between' }}>
                        <VStack spacing={5}>
                            <h4>Device login</h4>
                            <p style={{ fontSize: '0.8em', color: colorVars.gray }}>Authenticate your device to continue</p>
                        </VStack>
                        <ElvaLogoName width={90} />
                    </HStack>
                    <Divider />
                    <form onSubmit={handleSubmit(handleLogin)}>
                        <HStack center spacing={60}>
                            <VStack spacing={20} className={css.inputsContainer}>
                                {error && <span className={css.errorMessage}>{error}</span>}
                                <Input {...register('email')} required type="email" label="Device email address" placeholder="booth1@elva.golf" />
                                <Input {...register('password')} required type="password" label="Password" placeholder="Enter your password" />
                                <Button type="submit" disabled={isLoading} color="#007AFF">
                                    {isLoading
                                        ? <div className={css.spinner} />
                                        : <span style={{ fontSize: '0.8em' }}>Sign in</span>}
                                </Button>
                            </VStack>
                        </HStack>
                    </form>
                </VStack>
            </Card>
        </Container>
    );
};
