import {
    type IconWeight,
    ChartLine as ChartLineIcon,
    Plus as PlusIcon,
    Pencil as PencilIcon,
    PlusCircle as PlusCircleIcon,
} from '@phosphor-icons/react';
import { type ReactNode } from 'react';

import { typography } from '../../../typography';

import { type ButtonVariants } from './Button.css';
import * as css from './Button.css';

interface IButtonProps {
    children:ReactNode;
    variant?:ButtonVariants['variant'];
    size?:ButtonVariants['size'];
    onClick?:() => void;
    icon?:keyof typeof availableIcons;
    isDisabled?:boolean;
    iconWeight?:IconWeight;
    iconSide?:'before' | 'after';
    iconSize?:number;
    iconColor?:string;
}

const availableIcons = {
    'chart-line':  { component: ChartLineIcon,  defaultSize: 14 },
    'plus':        { component: PlusIcon,       defaultSize: 14 },
    'plus-circle': { component: PlusCircleIcon, defaultSize: 18 },
    'pencil':      { component: PencilIcon,     defaultSize: 18 },
};

const fontSizes:Record<NonNullable<ButtonVariants['size']>, string> = {
    icon: typography({ variant: 'h4'}),
    tiny: typography({ variant: 'h4'}),
    small: typography({ variant: 'h4' }),
    medium: typography({ variant: 'h3' }),
    large: typography({ variant: 'h2' }),
};

export function Button({
    children,
    variant,
    size = 'medium',
    isDisabled = false,
    icon,
    iconWeight,
    iconSide = 'before',
    iconSize,
    iconColor = 'currentColor',
    onClick = () => { },
}:IButtonProps) {
    const Icon = icon && availableIcons[icon].component;

    // Force `primary` variant into `secondary` when it's disabled.
    const effectiveVariant = variant === 'primary' && isDisabled
        ? 'secondary'
        : variant;

    return (
        <button
            className={css.button({ variant: effectiveVariant, size, isDisabled })}
            onClick={onClick}
            disabled={isDisabled}
        >
            <span className={css.content}>
                <span className={fontSizes[size]}>{children}</span>
            </span>

            {Icon && (
                <span className={css.icon({ side: iconSide })}>
                    <Icon
                        weight={iconWeight}
                        color={iconColor}
                        width={iconSize ?? availableIcons[icon].defaultSize}
                        height={iconSize ?? availableIcons[icon].defaultSize}
                    />
                </span>
            )}
        </button>
    );
}
