import { FaArrowLeft, FaRegClock } from 'react-icons/fa6';
import * as css from './OldActivityTopBar.css';
import { useNavigate } from 'react-router-dom';
import { Activity, Nil } from '@common';

type PropTypes = {
    activity?:Activity|Nil;
};

const OldActivityTopBar = ({ activity }:PropTypes) => {
    const navigate = useNavigate();

    const navigateBack = () => {
        navigate('/kiosk/activity-overview');
    };

    const formatDate = (date:string | undefined) => {
        if(!date) return 'N/A';
        return date.split('T')[0].replace(/-/g, '/');
    };

    const formatTime = (date:string | undefined) => {
        if(!date) return 'N/A';
        const timeSplit = date.split('T')[1]?.split(':');
        return timeSplit
            ? `${timeSplit[0]}:${timeSplit[1]}`
            : 'N/A';
    };

    return (
        <div className={css.bar}>
            <div className={css.content}>
                <div>
                    <button onClick={navigateBack} className={css.group}>
                        <FaArrowLeft />
                        <span>Back</span>
                    </button>
                </div>
                <span>{formatDate(activity?.start_time)}</span>
                <div className={css.group}>
                    <FaRegClock />
                    <span>{formatTime(activity?.start_time)}</span>
                </div>
            </div>
        </div>
    );
};

export default OldActivityTopBar;