export function Search({
    width = 24,
    height,
    color = '#3B82F6',
}:{
    width?:number;
    height?:number;
    color?:string;
}) {
    const w = width;
    const h = height ?? width;

    return (
        <svg
            viewBox="0 0 24 24"
            width={w}
            height={h}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m21.53 20.47-4.694-4.693a8.26 8.26 0 1 0-1.06 1.06l4.693 4.694a.75.75 0 1 0 1.06-1.061ZM3.75 10.5a6.75 6.75 0 1 1 6.75 6.75 6.758 6.758 0 0 1-6.75-6.75Z"
                fill={color}
            />
        </svg>
    );
}
