export function User({
    width = 24,
    height,
    color = '#3B82F6',
}:{
    width?:number;
    height?:number;
    color?:string;
}) {
    const w = width;
    const h = height ?? width;

    return (
        <svg
            width={w}
            height={h}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.125 11.25a4.125 4.125 0 1 1-8.25 0 4.125 4.125 0 0 1 8.25 0Zm5.625.75A9.75 9.75 0 1 1 12 2.25 9.76 9.76 0 0 1 21.75 12Zm-1.5 0a8.258 8.258 0 0 0-8.575-8.243c-4.416.17-7.937 3.848-7.925 8.266a8.217 8.217 0 0 0 2.085 5.453 7.475 7.475 0 0 1 2.04-1.998.375.375 0 0 1 .453.03 5.609 5.609 0 0 0 7.339 0 .374.374 0 0 1 .452-.03 7.474 7.474 0 0 1 2.043 1.998A8.214 8.214 0 0 0 20.25 12Z"
                fill={color}
            />
        </svg>
    );
}
