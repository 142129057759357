import { useContext } from 'react';
import { SessionContext } from '../contexts/SessionContext';

export const useSession = () => {
    const context = useContext(SessionContext);
    if(!context) {
        throw new Error('Missing SessionContext.Provider');
    }

    return context;
};
