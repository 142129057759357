import axios, { type AxiosResponse } from 'axios';

type Tokens = {
    accessToken:string;
    refreshToken:string;
};

type LoginBoothRequest = {
    email:string;
    password:string;
};

type LoginRequest = LoginBoothRequest & {
    boothSessionUid?:string;
};

type LoginBoothResponse =
    | {
          success:true;
          data:{
              boothSession:{
                  id:number;
                  uid:string;
                  boothId:number;
              };
              tokens:Tokens;
          };
          error:never;
      }
    | { success:false; error:string; data:never };

type LoginResponse =
    | {
          success:true;
          data:{
              boothSessionUserId?:number;
              tokens:Tokens;
          };
          error:never;
      }
    | { success:false; error:string; data:never };

type EndSessionResponse =
    | {
          success:true;
          data:{
              newBoothSessionId:number;
              newBoothSessionUid:string;
          };
      }
    | { success:false; error:string; data:never };

export const loginBooth = async(email:string, password:string) => {
    const supabaseUrl = import.meta.env['VITE_PUBLIC_SUPABASE_URL'];
    const anonKey = import.meta.env['VITE_PUBLIC_SUPABASE_ANON_KEY'];
    const config = { headers: { Authorization: `Bearer ${anonKey}` } };
    const bodyParameters = {
        email: email,
        password: password,
    };

    const res = await axios.post<LoginBoothRequest, AxiosResponse<LoginBoothResponse>>(`${supabaseUrl}/functions/v1/login-booth`, bodyParameters, {
        ...config,
        validateStatus: (status) => status !== 500,
    });

    return res.data;
};

const login = async(email:string, password:string, boothAccessToken?:string, boothSessionUid?:string) => {
    const supabaseUrl = import.meta.env['VITE_PUBLIC_SUPABASE_URL'];
    const anonKey = import.meta.env['VITE_PUBLIC_SUPABASE_ANON_KEY'];
    const config = {
        headers: { Authorization: `Bearer ${boothAccessToken ?? anonKey}` },
    };
    const bodyParameters = {
        email: email,
        password: password,
        boothSessionUid,
    };

    const res = await axios.post<LoginRequest, AxiosResponse<LoginResponse>>(`${supabaseUrl}/functions/v1/login`, bodyParameters, { ...config, validateStatus: (status) => status !== 500 });

    return res.data;
};

export const loginUser = async(email:string, password:string, boothAccessToken?:string) => {
    return login(email, password, boothAccessToken);
};

export const loginWithQrCode = async(email:string, password:string, boothSessionUid:string) => {
    return login(email, password, undefined, boothSessionUid);
};

export const endSession = async(boothAccessToken:string) => {
    const supabaseUrl = import.meta.env['VITE_PUBLIC_SUPABASE_URL'];
    const config = {
        headers: { Authorization: `Bearer ${boothAccessToken}` },
    };

    const res = await axios.post<unknown, AxiosResponse<EndSessionResponse>>(`${supabaseUrl}/functions/v1/end-session`, {}, { ...config, validateStatus: (status) => status !== 500 });

    return res.data;
};

export const updateSession = async(boothAccessToken:string, boothSessionUserId:number, accessToken:string, refreshToken:string) => {
    const supabaseUrl = import.meta.env['VITE_PUBLIC_SUPABASE_URL'];
    const config = {
        headers: { Authorization: `Bearer ${boothAccessToken}` },
    };

    const res = await axios.post<unknown, AxiosResponse<EndSessionResponse>>(
        `${supabaseUrl}/functions/v1/update-session`,
        { boothSessionUserId, accessToken, refreshToken },
        {
            ...config,
            validateStatus: (status) => status !== 500,
        },
    );

    return res.data;
};
