/* eslint-disable no-restricted-syntax */
import { useMemo, useState } from 'react';
import { typography } from '../../styles/typography.css';
import { Switch } from '../Switch';
import { Club } from '../ui/icons/Club';
import Spinner from '../../components/Spinner/Spinner';
import * as css from './ComparisonSidebar.css';
import { colorVars } from '../../styles/colors.css';
import { Comparison } from '../ui/icons/Comparison';
import { SearchInput } from '../SearchInput/SearchInput';
import { formatDateFromEpoch } from '@common';
import { ComparisonSidebarProps, SwingCardProps, SwingsListSectionProps } from './ComparisonSidebar.types';
import { useGlobalStore } from '../../state/globalStore';

export const SwingsListSection = ({
    title,
    swings,
    activities,
    selectedComparisonSwingId,
    onNewSwingSelected,
    isLoading = false,
    currentActivityId
}:SwingsListSectionProps) => {
    return (
        <>
            <p
                className={typography({
                    size: 'h4',
                    color: 'dark',
                    weight: 'bold',
                    lineHeight: '1.2',
                    case: 'uppercase',
                    tracking: 'positiveLarge',
                })}
            >
                {title}
            </p>
            <div className={css.list}>
                {isLoading && <Spinner size='s' />}
                {swings?.map((s, index) => {
                    const club = activities?.find(a => a.id === s.activityID)?.club?.short_name;
                    const tags = activities?.find(a => a.id === s.activityID)?.tags?.map(t => t.tag.name);
                    const title = s?.name || `Swing #${s?.id}`;
                    const isDisabled = currentActivityId === s.id;
                    const isSelected = s.id === selectedComparisonSwingId;

                    return (
                        <SwingCard
                            key={index}
                            disabled={isDisabled}
                            date={s.createdAt}
                            title={title}
                            club={club}
                            tags={tags}
                            isSelected={isSelected}
                            onClick={() => onNewSwingSelected(s.id)}
                        />
                    );
                })}
            </div>
        </>
    );
};

function SwingCard({
    date,
    title,
    club,
    tags,
    isSelected,
    onClick,
    disabled
}:SwingCardProps) {
    const formattedDate = formatDateFromEpoch(date);

    return (
        <button
            onClick={() => {
                if(!disabled) {
                    onClick();
                }
            }}>
            <div className={css.swingCard({ isSelected })}>
                <div className={css.swingCardTop}>
                    <div>
                        <p
                            className={typography({
                                size: 'h4',
                                color: 'dark',
                                weight: 'bold',
                                lineHeight: '1.2',
                                tracking: 'positiveLarge',
                            })}
                        >
                            {formattedDate}
                        </p>
                        <p
                            className={typography({
                                size: 'h3',
                                color: 'dark',
                                weight: 'bold',
                                lineHeight: '1.4',
                            })}
                        >
                            {title}
                        </p>
                    </div>
                    <Comparison color={isSelected
                        ? colorVars.orange
                        : colorVars.blueGray500} />
                </div>
                <div className={css.swingCardTags}>
                    {club && <div className={css.swingCardTag}>{club}</div>}
                    {tags?.map((tag) => (
                        <div key={tag} className={css.swingCardTag}>
                            {tag}
                        </div>
                    ))}
                </div>
            </div>
        </button>
    );
}

export function ComparisonSidebar({
    activities,
    currentActivityId,
    currentActivityFavoriteSwings,
    otherActivitiesFavoriteSwings,
    onNewSwingSelected,
    initialSelectedComparisonSwingId = -1,
}:ComparisonSidebarProps) {
    const [activeSwingList, setActiveSwingList] = useState<'my-swings' | 'elva-swings'>('my-swings');
    const [filter, setFilter] = useState<string>('');
    const [selectedComparisonSwingId, setSelectedSwingId] = useState(initialSelectedComparisonSwingId);
    const numberOfFavoriteSwings = useGlobalStore(state => state.actions.getNumberOfFavoriteSwings());
    
    const filteredCurrentActivitySwings = useMemo(() => (
        currentActivityFavoriteSwings?.filter(swing =>
            swing?.name?.toLowerCase().includes(filter.toLowerCase())
        ) || []
    ), [filter, currentActivityFavoriteSwings]);

    const filteredOtherActivitySwings = useMemo(() => (
        otherActivitiesFavoriteSwings?.filter(swing =>
            swing?.name?.toLowerCase().includes(filter.toLowerCase())
        ) || []
    ), [filter, otherActivitiesFavoriteSwings]);


    return (
        <div className={css.root}>
            <div className={css.filterBox}>
                <p
                    className={typography({
                        size: 'h2',
                        weight: 'bold',
                        color: 'dark',
                        lineHeight: '1.2',
                    })}
                >
                    Comparison swing
                </p>
                <div className={css.filter}>
                    <p
                        className={typography({
                            size: 'h4',
                            color: 'dark',
                            weight: 'bold',
                            lineHeight: '1.2',
                            case: 'uppercase',
                            tracking: 'positiveLarge',
                        })}
                    >
                        Filter swings
                    </p>
                    <div className={css.fields}>
                        <SearchInput value={filter} onChange={setFilter} />
                        <button className={css.clubButton}>
                            <Club />
                            Club
                        </button>
                    </div>
                </div>
            </div>
            <div className={css.swingPicker}>
                <div className={css.swingPickerItem}>
                    <Switch
                        switchKey="swing-list-switch"
                        items={[
                            {
                                title: 'My swings',
                                onClick: () => setActiveSwingList('my-swings'),
                                isSelected: activeSwingList === 'my-swings',
                            },
                            {
                                title: 'Elva swings',
                                onClick: () => setActiveSwingList('elva-swings'),
                                isSelected: activeSwingList === 'elva-swings',
                            },
                        ]}
                        backgroundColor={colorVars.blueGray50}
                    />
                    <div className={css.listInfo}>
                        <p
                            className={typography({
                                size: 'paragraph',
                                color: 'dark',
                                lineHeight: '1.2',
                            })}
                        >
                            {numberOfFavoriteSwings} swings
                        </p>
                        <p
                            className={typography({
                                size: 'paragraph',
                                color: 'dark',
                                lineHeight: '1.2',
                            })}
                        >
                            All clubs
                        </p>
                    </div>
                </div>
                <div className={css.swingPickerItem}>
                    <SwingsListSection
                        currentActivityId={currentActivityId}
                        title={'Current activity'}
                        swings={filteredCurrentActivitySwings}
                        activities={activities}
                        selectedComparisonSwingId={selectedComparisonSwingId}
                        onNewSwingSelected={(id) => {
                            setSelectedSwingId(id);
                            onNewSwingSelected(id);
                        }}
                    />
                </div>
                <div className={css.swingPickerItem}>
                    <SwingsListSection
                        currentActivityId={currentActivityId}
                        title={'Your Swings'}
                        swings={filteredOtherActivitySwings}
                        activities={activities}
                        selectedComparisonSwingId={selectedComparisonSwingId}
                        onNewSwingSelected={(id) => {
                            setSelectedSwingId(id);
                            onNewSwingSelected(id);
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
