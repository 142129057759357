import _ from 'lodash';
import UIComponent from '../UIComponent';
import HStack from '../../ui/HStack';
import { MetadataOf, UIRow } from '@common';
import { ImplementationOf } from '../UIComponent.types';


const defaults:MetadataOf<UIRow> = {
    spacing: '10px',
    alignment: 'start',
    direction: 'normal',
};

export const Row:ImplementationOf<'row'>
= ({ node: { metadata }, children }) => {
    console.log('Row children', children);
    const { spacing, alignment, direction } = _.defaults(metadata, defaults);
    return (
        <HStack
            spacing={spacing}
            alignment={alignment}
            direction={direction}
        >
            {_.map(children, (props) => (
                <UIComponent key={props.currentNode.id} {...props} />
            ))}
        </HStack>
    );
};
