import { useState, useCallback } from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import cn from 'classnames';
import {
    type IconWeight,
    ChartLine as ChartLineIcon,
    Person as PersonIcon,
} from '@phosphor-icons/react';

import { typography } from '../../../typography';

import type { ToggleButtonVariants } from './ToggleButton.css';
import * as css from './ToggleButton.css';

const availableIcons = {
    'chart-line': { component: ChartLineIcon, size: 14 },
    person: { component: PersonIcon, size: 14 },
};

interface IToggleButtonProps {
    children:string;
    variant?:ToggleButtonVariants['variant'];
    isActive?:boolean;
    isDisabled?:boolean;
    icon?:keyof typeof availableIcons;
    iconWeight?:IconWeight;
    maxLetters?:number;
    onChange?:(isActive:boolean) => void;
}

export function ToggleButton({
    children,
    variant = 'lighter',
    isActive: controlledIsActive,
    isDisabled,
    icon,
    iconWeight = 'fill',
    maxLetters,
    onChange,
}:IToggleButtonProps) {
    const [internalIsActive, setInternalIsActive] = useState(false);
    const isControlled = controlledIsActive !== undefined;
    const isActive = isControlled
        ? controlledIsActive
        : internalIsActive;
    const showEllipsis = typeof maxLetters !== 'undefined';

    const handleClick = useCallback(() => {
        const newState = !isActive;
        if(!isControlled) {
            setInternalIsActive(newState);
        }
        onChange?.(newState);
    }, [isControlled, isActive, onChange]);

    const Icon = icon && availableIcons[icon].component;

    return (
        <button
            disabled={isDisabled}
            className={css.toggleButton({
                status: isDisabled
                    ? 'disabled'
                    : isActive
                        ? 'active'
                        : 'inactive',
                variant,
            })}
            style={assignInlineVars({ [css.maxLetters]: `${maxLetters ?? 0}` })}
            onClick={handleClick}
        >
            {Icon && (
                <span className={css.icon}>
                    <Icon
                        weight={iconWeight}
                        width={availableIcons[icon].size}
                        height={availableIcons[icon].size}
                    />
                </span>
            )}
            <span
                title={showEllipsis
                    ? children
                    : undefined}
                className={cn([typography({variant: 'h4'}), css.content({ ellipsis: showEllipsis })])}
            >
                {children}
            </span>
        </button>
    );
}
