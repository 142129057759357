import { ReactNode } from 'react';

import { typography } from '@common/ui';

import * as css from './ModuleBase.floor.css';

// This file shouldn't even exist, it should be part of UIComponents/implementations/Module.tsx file
export default function ModuleBase({ title, subtitle, children }:{ title?:string; subtitle?:string; children:ReactNode }) {
    return (
        <div className={css.root}>
            <div className={css.top}>
                <h3
                    className={typography({
                        variant: 'h2'
                    })}
                >
                    {title ?? 'Module'}
                </h3>
                {subtitle && (
                    <p className={typography({variant: 'h3'})}>{subtitle}</p>
                )}
            </div>
            <div className={css.body}>{children}</div>
        </div>
    );
}
