import { useMemo } from 'react';

import {
    Tabs,
    Button,
    ToggleButton,
    typography,
    DisplayOrderEditor,
    displayOrderEditorCSS,
} from '@common/ui';
import {
    localize,
    applyDisplayOrder,
    deferOnChange,
    getDisplayOrder,
    getTogglerTitle,
    isToggledOn,
    isType,
    setToggle,
    toggleOff,
    UINodeToggler,
    UINodeTree,
    isToggledOnByDefault,
    ALL_DEVICES,
} from '@common';

import * as css from './LayoutEditorTabs.css';
import _ from 'lodash';
import { inferGraphLineName } from '../../../components/UIComponents/implementations/Graph/GraphLine';

interface LayoutEditorTabsProps {
    tabs:string[];
    selectedTab:string;
    onSelectTab:(tab:string) => void;
    getTabLabel:(tab:string) => string;
    togglers:UINodeToggler[];
    maxLength:number;
    uiNodeTree:UINodeTree;
}

export function LayoutEditorTabs({
    tabs,
    selectedTab,
    onSelectTab,
    getTabLabel,
    togglers = [],
    maxLength,
    uiNodeTree,
}:LayoutEditorTabsProps) {
    const currentLength = togglers?.length ?? 0;

    const formattedCountString = useMemo(() => {
        if(Number.isFinite(maxLength)) {
            return `${currentLength}/${maxLength}`;
        }

        return `${maxLength}`;
    }, [currentLength, maxLength]);

    const clear = () => {
        deferOnChange(togglers, (t) => {
            if(!isToggledOnByDefault(t))
                toggleOff(t);
        });
    };

    return (
        <div className={css.root}>
            <Tabs
                items={tabs}
                activeItem={selectedTab}
                onClick={onSelectTab}
                getTabLabel={getTabLabel}
            />
            <div className={css.toolbar}>
                <div className={css.toolbarPartition}>
                    <ToggleButton>{localize('layout_editor.sequences')}</ToggleButton>
                </div>
                <div className={css.toolbarPartition}>
                    {togglers && (
                        <div
                            className={css.counter({
                                valid: currentLength <= maxLength,
                            })}
                        >
                            <span className={typography({ variant: 'h3' })}>
                                {formattedCountString}
                            </span>
                        </div>
                    )}
                    <Button variant="secondary" size="small" onClick={clear}>
                        {localize('layout_editor.clear_button')}
                    </Button>
                </div>
            </div>

            <DisplayOrderEditor<UINodeToggler>
                items={togglers}
                onReorder={(newOrder) => applyDisplayOrder(newOrder)}
                onDeleteItem={(t) =>
                    deferOnChange(togglers, () => {
                        toggleOff(t);
                        const newOrder = _.filter(togglers, (o) => o !== t);
                        applyDisplayOrder(newOrder);
                    })
                }
                getItemProps={(t) => {
                    // add togglers for graph line nodes (children of graph nodes)
                    const childTogglers =
                        t.childNode.type === 'graph'
                        && _.includes(t.currentDevices, 'floor')
                            ? _(uiNodeTree.relations)
                                .filter((r) => r.parent_ui_node_id === t.childNode.id)
                                .map((r) =>
                                    _.find(
                                        uiNodeTree.nodes,
                                        (n) => n.id === r.child_ui_node_id,
                                    ),
                                )
                                .compact()
                                .map(
                                    n =>
                                        ({
                                            layout: t.layout,
                                            currentDevices: t.currentDevices,
                                            defaultDevices: ALL_DEVICES, // show graph lines on all devices by default
                                            onChange: t.onChange,
                                            parentNode: t.childNode,
                                            childNode: n,
                                        }) as UINodeToggler,
                                )
                                .value()
                            : null;

                    const [device] = t.currentDevices;
                    return {
                        displayOrder: getDisplayOrder(t) ?? 0,
                        id: `${device}_${t.childNode.id}`,
                        isRemoveDisabled: isToggledOnByDefault(t),
                        content: (
                            <>
                                <span className={typography({ variant: 'h3' })}>
                                    {getTogglerTitle(t, uiNodeTree)}
                                </span>
                                {_.size(childTogglers) > 1 && (
                                    <div className={displayOrderEditorCSS.buttons}>
                                        {_.map(childTogglers, child => {

                                            const name = isType(child.childNode, 'graph_line')
                                                ? inferGraphLineName(child.childNode)
                                                : child.childNode.short_name?.value
                                                    || child.childNode.name?.value
                                                    || _.startCase(child.childNode.id);

                                            return <ToggleButton
                                                key={child.childNode.id}
                                                isActive={isToggledOn(child)}
                                                onChange={newState => setToggle(child, newState)}
                                                maxLetters={_.size(childTogglers) > 2
                                                    ? 10
                                                    : 40
                                                }
                                            >
                                                {name}
                                            </ToggleButton>;
                                        })}
                                    </div>
                                )}
                            </>
                        ),
                    };
                }}
            />
        </div>
    );
}
