import { typography } from '../../../styles/typography.css';

import * as css from './Tag.css';

type TagProps = {
    value:string;
    variant?:'default' | 'pending';
};

const fontSize = {
    default: 'h3',
    pending: 'h4',
} as const;

const cases = {
    default: undefined,
    pending: 'uppercase',
} as const;

const Pending = () => {
    return <div className={css.pending}></div>;
};

export default function Tag({ value, variant = 'default' }:TagProps) {
    return (
        <div className={css.root({ variant })}>
            {variant === 'pending' && <Pending />}
            <span
                className={typography({
                    size: fontSize[variant],
                    weight: 'bold',
                    color: 'dark',
                    lineHeight: '1.2',
                    case: cases[variant],
                    textOverflow: variant === 'default'
                        ? 'ellipsis'
                        : undefined,
                })}
            >
                {value}
            </span>
        </div>
    );
}
