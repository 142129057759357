export function ArrowCounterClockwise({
    width = 24,
    height,
}:{
    width?:number;
    height?:number;
}) {
    const w = width;
    const h = height ?? width;
    return (
        <svg
            viewBox="0 0 25 25"
            width={w}
            height={h}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.5 12.5a9 9 0 0 1-8.88 9h-.12a8.941 8.941 0 0 1-6.178-2.456.75.75 0 0 1 1.031-1.09 7.5 7.5 0 1 0-.18-10.738L4.68 9.5h2.57a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 1 1.5 0v2.794l2.648-2.419A9 9 0 0 1 21.5 12.5Z"
                fill="#475569"
            />
        </svg>
    );
}
