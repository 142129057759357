import { CorridorParameterMargins, Nil } from '@common';

import * as defaultCSS from './CorridorRenderer.css';
import { isFiniteNumber } from '@common';
import _ from 'lodash';
import { CSSProperties } from 'react';

const DOT_SIZE = 8;

export const corridorRendererCSS:Partial<typeof defaultCSS> = defaultCSS;

export interface CorridorRendererProps {
    corridorParameterMargins:CorridorParameterMargins;
    value?:number|Nil;
    backgroundColor?:CSSProperties['backgroundColor'];
    cssOverrides?:Partial<typeof defaultCSS>;
}

export function CorridorRenderer({
    corridorParameterMargins:
    {
        outer_min,
        outer_max,
    },
    value,
    backgroundColor,
    cssOverrides,
}:CorridorRendererProps) {

    const corridorLength = outer_max - outer_min;
    const normalizedValue = isFiniteNumber(value)
        ? (value - outer_min) / corridorLength
        : null;
    const clampedNormalizedValue = _.clamp(normalizedValue ?? 0, 0, 1);

    // Convert to percentage, flip if corridor is inverted

    const style = {
        bottom: `clamp(0%, ${clampedNormalizedValue * 100}% - ${DOT_SIZE / 2}px, 100% - ${DOT_SIZE}px)`
    };

    const css = { ...defaultCSS, ...cssOverrides };

    return (
        <div
            className={css.root}
            style={{ backgroundColor }}
        >
            <p className={css.maxNumber}>
                {outer_max ?? '-'}
            </p>
            <div className={css.line}>
                <div className={css.dot} style={style} />
            </div>
            <p className={css.minNumber}>
                {outer_min ?? '-'}
            </p>
        </div>
    );
}
