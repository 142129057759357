export const SCRUBBER_HEIGHT = 50;
export const NAVIGATION_HEIGHT = 84;
export const BACK_NAV_HEIGHT = 60;

// Limit the use of zIndex to these.
export const ZINDEX = {
    ABOVE1: 1,
    ABOVE2: 2,
    ABOVE3: 3,
};

// High speed cameras
export const CAMERA_FPS = 255;
// Playback fps of video files
export const VIDEO_FPS = 25;

const isUsingLocalhost = String(import.meta.env['VITE_PUBLIC_SUPABASE_URL']).includes('127.0.0.1') || String(import.meta.env['VITE_PUBLIC_SUPABASE_URL']).includes('localhost');

export const IS_DEV = typeof import.meta.env['VITE_PUBLIC_DEBUG_MODE'] !== 'undefined'
    // Check if the value is explicitly 'true', otherwise it's false
    ? String(import.meta.env['VITE_PUBLIC_DEBUG_MODE']) === 'true'
    // If DEBUG_MODE is undefined, then fallback to localhost check
    : isUsingLocalhost;
