import { createWithEqualityFn } from 'zustand/traditional';
import { subscribeWithSelector } from 'zustand/middleware';
import { UserSettingsStoreState } from './userSettingsStore.types';
import { Device, Layout, UINodeID, UINodeTree } from '@common';
import _ from 'lodash';


export const useUserSettingsStore = createWithEqualityFn<UserSettingsStoreState>()(
    
    subscribeWithSelector((set, get) => ({
        userSettings: {
            language: 'English',
            golfBag: [],
        },

        availableTags: [],

        playbackSettings: {
            playbackAngle: undefined,
            skeletonOverlay: false,
            instantReplay: true,
            playbackSpeed: 1,
        },

        activeCorridor: undefined,

        //Layout
        activeLayout: undefined,
        uiNodes: undefined,

        actions: {
            setUserSettings: (userSettingsToUpdate) => {
                const merged:UserSettingsStoreState['userSettings']
                    = { ...get().userSettings, ...userSettingsToUpdate };
                set({ userSettings: merged });
                return merged;
            },
            setAvailableTags: (availableTags) => set({ availableTags }),
            setPlaybackSettings: (playbackSettings) =>
                set({ playbackSettings }),

            // Activity
            setActivityId: (activityId) => set({ activityId }),
            setActivityClub: (activityClub) => set({ activityClub }),
            setActivityTags: (activityTags) => set({ activityTags }),

            // Corridor
            setActiveCorridor: (corridor) => set({ activeCorridor: corridor }),

            // Layout
            setUINodes(uiNodes?:UINodeTree) {
                set({ uiNodes });
            },
            setActiveLayout: (layout?:Layout) => {
                if(!layout) {
                    return;
                }

                set({
                    activeLayout: layout,
                });
            },
            toggleLayoutModule: (
                moduleId:UINodeID,
                deviceType:Device,
                index:number,
            ) => {
                return set((prev) => {
                    const existingCustomizationIndex =
                        _.findIndex(
                            prev.activeLayout?.customizations,
                            (c) => c.child_ui_node_id === moduleId,
                        );

                    const newCustomizations = [
                        ...(prev.activeLayout?.customizations || []),
                    ];

                    if(existingCustomizationIndex !== -1) {
                        // Module already exists, update it
                        const existingCustomization =
                            newCustomizations[existingCustomizationIndex];
                        const updatedShowChildOn =
                            existingCustomization.show_child_on === null
                                ? []
                                : _.includes(
                                    existingCustomization.show_child_on,
                                    deviceType,
                                )
                                    ? _.filter(
                                        existingCustomization.show_child_on,
                                        (type) => type !== deviceType,
                                    )
                                    : [
                                        ...existingCustomization.show_child_on,
                                        deviceType,
                                    ];

                        if(updatedShowChildOn.length === 0) {
                            // Remove the module if show_child_on is empty
                            newCustomizations.splice(
                                existingCustomizationIndex,
                                1,
                            );
                        } else {
                            // Update the existing customization
                            newCustomizations[existingCustomizationIndex] = {
                                ...existingCustomization,
                                show_child_on: updatedShowChildOn,
                            };
                        }
                    } else {
                        // Add new module
                        newCustomizations.push({
                            parent_ui_node_id: null,
                            child_ui_node_id: moduleId,
                            child_display_order: index,
                            child_metadata: {},
                            show_child_on: [deviceType],
                        });
                    }

                    const newUserLayout = {
                        ...prev.activeLayout,
                        customizations: newCustomizations,
                    } as Layout;

                    return {
                        activeLayout: newUserLayout,
                    };
                });
            },
        },

    })),
);
