import { useContext, useState } from 'react';
import { useUserSettingsStore } from '../../state/userSettingsStore';
import { typography } from '../../styles/typography.css';
import * as css from './ClubSelection.css';
import { BACK_NAV_HEIGHT } from '../../utils/consts';
import cn from 'classnames';
import { AnimatePresence, motion } from 'motion/react';
import Button from '../../components/ui/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ClubTable } from '../../components/ClubTable';
import { useGlobalStore } from '../../state/globalStore';
import { GlobalContext } from '../../utils/contexts/GlobalContext';
import _ from 'lodash';
import { Club } from '@common';

export default function ClubSelection() {
    const navigate = useNavigate();
    const clubs = useGlobalStore((state) => state.clubs);

    const [searchParams, setSearchParams] = useSearchParams();

    const displayMode = searchParams.get('display') ?? ('all' as 'all' | 'bag');

    const globalContext = useContext(GlobalContext);

    const {
        userSettings,
        activityClub,
    } = useUserSettingsStore((state) => state);

    const [selectedClub, setSelectedClub] = useState<Club>(activityClub || clubs[0]?.clubs?.[0]);

    const bagClubs = _.map(clubs, (clubType) => {
        return {
            type_name: clubType.type_name,
            clubs: _.filter(clubType.clubs, (club) => _.find(userSettings.golfBag, (c) => c.id === club.id)) as Club[],
        };
    });

    const onSave = async() => {
        globalContext?.selectClub(selectedClub);
    };

    return (
        <div className={css.root}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: BACK_NAV_HEIGHT,
                }}
            >
                <p
                    className={typography({
                        size: 'h2',
                        weight: 'bold',
                        color: 'dark',
                        lineHeight: '1.2',
                    })}
                >
                    Clubs
                </p>
                <div className={css.toggle}>
                    <button
                        className={css.toggleItem}
                        onClick={() => {
                            setSearchParams({ display: 'all' });
                        }}
                        key="all"
                    >
                        <p
                            className={cn([
                                typography({
                                    size: 'h3',
                                    weight: 'bold',
                                    color: displayMode === 'all'
                                        ? 'blue'
                                        : 'dark',
                                }),
                                css.toggleText,
                            ])}
                        >
                            All clubs
                        </p>
                        {displayMode === 'all'
                            ? (
                                <motion.div className={css.toggleIndicator} layoutId="active" />
                            )
                            : null}
                    </button>
                    <button
                        className={css.toggleItem}
                        onClick={() => {
                            setSearchParams({ display: 'bag' });
                        }}
                        key="bag"
                    >
                        <p
                            className={cn([
                                typography({
                                    size: 'h3',
                                    weight: 'bold',
                                    color: displayMode === 'bag'
                                        ? 'blue'
                                        : 'dark',
                                }),
                                css.toggleText,
                            ])}
                        >
                            My golf bag
                        </p>
                        {displayMode === 'bag'
                            ? (
                                <motion.div className={css.toggleIndicator} layoutId="active" />
                            )
                            : null}
                    </button>
                </div>
                <div className={css.buttons}>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            navigate('/kiosk');
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            onSave?.();
                            navigate('/kiosk');
                        }}
                    >
                        Save
                    </Button>
                </div>
            </div>
            <div className={css.contentWrapper}>
                <AnimatePresence mode="wait">
                    {displayMode === 'bag'
                        ? (
                            <ClubTable
                                key="bag"
                                clubs={bagClubs}
                                selectedClubs={[selectedClub]}
                                onClubClick={setSelectedClub}
                            />
                        )
                        : (
                            <ClubTable
                                key="all"
                                clubs={clubs}
                                selectedClubs={[selectedClub]}
                                onClubClick={setSelectedClub}
                            />
                        )}
                </AnimatePresence>
            </div>
        </div>
    );
}
