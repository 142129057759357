import { Dialog, DialogDismiss, DialogHeading } from '@ariakit/react';
import { type ReactNode } from 'react';
import { XCircle as XCircleIcon } from '@phosphor-icons/react';
import { colors } from '../../colors';
import * as css from './Modal.css';
import { motion } from 'motion/react';

export interface ModalProps {
    title:string;
    children:ReactNode;
    footer:{ primary?:ReactNode, secondary?:ReactNode } | null;
    onDismiss:() => void;

    /** If true, the modal appears inside its parent element instead of at the root of the DOM tree */
    inline?:boolean;
}

export function Modal({ title, children, footer, onDismiss, inline }:ModalProps) {
    const modal = (
        <div className={css.modal}>
            { inline
                ? (
                    <div className={css.header}>
                        <h3 className={css.title}>{title}</h3>
                        <button className={css.dismiss} title="Dismiss" onClick={onDismiss}>
                            <XCircleIcon width={20} height={20} weight="fill" color={colors.blue[500]} />
                        </button>
                    </div>
                )
                : (
                    <div className={css.header}>
                        <DialogHeading className={css.title}>
                            {title}
                        </DialogHeading>
                        <DialogDismiss className={css.dismiss}>
                            <XCircleIcon width={20} height={20} weight="fill" color={colors.blue[500]} />
                        </DialogDismiss>
                    </div>
                )
            }
            <div className={css.body}>
                {children}
            </div>
            <div className={css.footer}>
                <div className={css.footerPartition} >
                    {footer?.secondary}
                </div>
                <div className={css.footerPartition}>
                    {footer?.primary}
                </div>
            </div>
        </div>
    );

    if(inline)
        return modal;

    return (
        <Dialog
            open
            onClose={onDismiss}
            className={css.modal}
            backdrop={
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{
                        backgroundColor: 'rgb(0,0,0,0.3)',
                    }}
                />
            }
            render={
                <motion.div
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.95 }}
                />
            }
        >
            {modal}
        </Dialog>
    );
}
