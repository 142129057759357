import { useForm } from 'react-hook-form';
import { SupabaseClient } from '@supabase/supabase-js';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import Container from '../../components/ui/Container';
import Button from '../../components/ui/Button';
import Input from '../../components/ui/Input';

import * as css from './ResetPasswordScreen.css';
import Card from '../../components/ui/Card';

interface FormValues {
    email:string;
}

const ResetPasswordScreen = ({ supabase }:{ supabase:SupabaseClient }) => {
    const { register, handleSubmit } = useForm<FormValues>();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async(formValues:FormValues) => {
        setIsLoading(true);
        const { error } = await supabase.auth.resetPasswordForEmail(
            formValues.email,
            {
                redirectTo: import.meta.env['VITE_PUBLIC_KIOSK_PASSWORD_RESET_URL'],
            },
        );
        if(error) {
            console.log(error.message);
        } else {
            navigate('/email-sent');
        }
    };

    return (
        <Container>
            <Card>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={css.formContainer}>
                        <h2>Reset your password</h2>
                        <p>
              Please enter your email address, and we will send you instructions
              to reset your password.
                        </p>
                        <Input
                            label="Your email"
                            type="email"
                            {...register('email', { required: 'Email is required' })}
                            placeholder="Enter your email"
                        />
                        <Button type="submit" style={{ width: '100%' }}>
                            {isLoading
                                ? (
                                    <div className={css.spinner} />
                                )
                                : (
                                    <span style={{ fontSize: '0.8em' }}>Send reset link</span>
                                )}
                        </Button>
                    </div>
                </form>
            </Card>
        </Container>
    );
};

export default ResetPasswordScreen;
