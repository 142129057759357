export function SwingInsight({
    width = 24,
    height,
}:{
    width?:number;
    height?:number;
}) {
    const w = width;
    const h = height ?? width;
    return (
        <svg
            width={w}
            height={h}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM18.45 12.6L13.95 15.975C13.8202 16.0724 13.6623 16.125 13.5 16.125C13.3011 16.125 13.1103 16.046 12.9697 15.9053C12.829 15.7647 12.75 15.5739 12.75 15.375V12C12.75 12.1164 12.7229 12.2313 12.6708 12.3354C12.6188 12.4396 12.5432 12.5301 12.45 12.6L7.95 15.975C7.82018 16.0724 7.66228 16.125 7.5 16.125C7.30109 16.125 7.11033 16.046 6.96967 15.9053C6.82902 15.7647 6.75 15.5739 6.75 15.375V8.625C6.75 8.48572 6.78879 8.34918 6.86202 8.2307C6.93524 8.11222 7.04002 8.01647 7.16459 7.95418C7.28917 7.89189 7.42864 7.86552 7.56736 7.87803C7.70608 7.89054 7.83858 7.94143 7.95 8.025L12.45 11.4C12.5432 11.4699 12.6188 11.5604 12.6708 11.6646C12.7229 11.7687 12.75 11.8836 12.75 12V8.625C12.75 8.48572 12.7888 8.34918 12.862 8.2307C12.9352 8.11222 13.04 8.01647 13.1646 7.95418C13.2892 7.89189 13.4286 7.86552 13.5674 7.87803C13.7061 7.89054 13.8386 7.94143 13.95 8.025L18.45 11.4C18.5432 11.4699 18.6188 11.5604 18.6708 11.6646C18.7229 11.7687 18.75 11.8836 18.75 12C18.75 12.1164 18.7229 12.2313 18.6708 12.3354C18.6188 12.4396 18.5432 12.5301 18.45 12.6Z"
                fill="#3B82F6"
            />
        </svg>
    );
}
