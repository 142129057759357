export function ClockCountdown({
    width = 24,
    height,
}:{
    width?:number;
    height?:number;
}) {
    const w = width;
    const h = height ?? width;

    return (
        <svg
            width={w}
            height={h}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.5 9a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Zm-1.125-2.25a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25ZM21.062 12a.75.75 0 0 0-.81.685 8.261 8.261 0 1 1-8.94-8.935.753.753 0 1 0-.124-1.5A9.761 9.761 0 1 0 21.75 12.812a.749.749 0 0 0-.688-.812ZM12 5.25A6.75 6.75 0 1 1 5.25 12 6.757 6.757 0 0 1 12 5.25ZM11.25 12a.75.75 0 0 0 .75.75h4.5a.75.75 0 1 0 0-1.5h-3.75V7.5a.75.75 0 1 0-1.5 0V12ZM15 4.5a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"
                fill="#3B82F6"
            />
        </svg>
    );
}
