import { useMemo } from 'react';
import { useGlobalStore } from '../../state/globalStore';
import { typography } from '../../styles/typography.css';
import Pill from '../Pill/Pill';

import * as css from './TopBar.floor.css';
import { getStatus } from '../../utils/getStatus';
import { useSyncSocketStore } from '../../state/syncSocketStore';
import { useCommunicationSocketStore } from '../../state/communicationSocketStore';
import { PropTypes } from './TopBar.floor.types';
import { useSession } from '../../utils/hooks/useSession';
import _ from 'lodash';

export default function TopBar({ club, tags, selectedLayout, progressBar }:PropTypes) {

    const lastBeastStatus = useGlobalStore((state) => state.lastBeastStatus);
    const { user } = useSession();

    // Have connection status of both beast and relay to display „Disconnected“ message if either one disconnects.
    const syncServerConnected = useSyncSocketStore((state) => state.isConnected);
    const beastConnected = useCommunicationSocketStore((state) => state.isConnected);

    const status = useMemo(() => {
        switch(lastBeastStatus) {
            case 'BALL_STEADY':
                return 'ready';
            case 'SWING_ANALYSIS_FAILED':
                return 'error';
            default:
                return 'waiting';
        }
    }, [lastBeastStatus]);

    const determineStatusMessage = () => {
        if(!beastConnected || !syncServerConnected) return 'Disconnected';
        return getStatus(lastBeastStatus);
    };

    return (
        <div className={css.root}>
            <div className={css.tagGroups}>
                {progressBar && <div>{progressBar}</div>}

                {user && (
                    <div className={css.tagGroup}>
                        <p
                            className={typography({
                                size: 'floorParagraphXSmall',
                                tracking: 'positiveLarge',
                                color: 'floorDark',
                                weight: 'bold',
                            })}
                        >
                            USER
                        </p>
                        <Pill label={user?.name ?? ''} />
                    </div>
                )}

                {selectedLayout && (
                    <div className={css.tagGroup}>
                        <p
                            className={typography({
                                size: 'floorParagraphXSmall',
                                tracking: 'positiveLarge',
                                color: 'floorDark',
                                weight: 'bold',
                            })}
                        >
                            LAYOUT
                        </p>
                        <Pill label={selectedLayout} />
                    </div>
                )}

                {club && (
                    <div className={css.tagGroup}>
                        <p
                            className={typography({
                                size: 'floorParagraphXSmall',
                                tracking: 'positiveLarge',
                                color: 'floorDark',
                                weight: 'bold',
                            })}
                        >
                            CLUB
                        </p>
                        <Pill label={club} />
                    </div>
                )}

                {tags.length && (
                    <div className={css.tagGroup}>
                        <p
                            className={typography({
                                size: 'floorParagraphXSmall',
                                tracking: 'positiveLarge',
                                color: 'floorDark',
                                weight: 'bold',
                            })}
                        >
                            TAGS
                        </p>
                        {_.map(tags, (tag) => <Pill key={tag} label={tag} />)}
                    </div>
                )}
            </div>

            <div className={css.status}>
                <p
                    className={typography({
                        size: 'floorParagraph',
                        weight: 'bold',
                        color: status === 'error'
                            ? 'red'
                            : 'floorLight',
                    })}
                >
                    {determineStatusMessage()}
                </p>
                <div
                    className={css.statusDot({
                        status
                    })}
                />
            </div>
        </div>
    );
}
