import { motion } from 'motion/react';
import { typography } from '../../styles/typography.css';
import cn from 'classnames';
import * as css from './Switch.css';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import _ from 'lodash';

type SwitchProps = {
    switchKey:string;
    items:{ title:string; onClick:() => void; isSelected:boolean }[];
    backgroundColor?:string;
};

export function Switch({ switchKey, items, backgroundColor }:SwitchProps) {
    return (
        <div
            key={switchKey}
            className={css.root}
            style={assignInlineVars({
                [css.backgroundColor]: backgroundColor,
            })}
        >
            {_.map(items, (item) => (
                <button
                    className={css.switchItem}
                    onClick={item.onClick}
                    key={item.title}
                >
                    <p
                        className={cn([
                            typography({
                                size: 'h3',
                                weight: 'bold',
                                color: item.isSelected
                                    ? 'blue'
                                    : 'dark',
                            }),
                            css.switchText,
                        ])}
                    >
                        {item.title}
                    </p>
                    {item.isSelected
                        ? (
                            <motion.div
                                className={css.switchIndicator}
                                layoutId={`${switchKey}-active`}
                            />
                        )
                        : null}
                </button>
            ))}
        </div>
    );
}
