import _ from 'lodash';

export const calculateFrameRatios = (
    segmentation:number[],
    totalFrames:number,
    offset = 0
):{ [key:string]:number } =>
    _.reduce(
        segmentation,
        (acc, value, index) => {
            if(value === null) {
                acc[index] = -1;
            } else {
                const ratio = (value + offset) / (totalFrames);
                // Clamp the ratio between 0 and 1 to prevent visual glitch where lines going outside of scrubber
                acc[index] = Math.min(Math.max(ratio, 0), 1);
            }
            return acc;
        },
        {} as { [index:string]:number },
    );
