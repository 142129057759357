import { useEffect, useRef } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { resetGlobalState } from 'react-use-websocket';

import BoothApp from './app/app';
import { useCommunicationSocket } from './state/communicationSocketStore';
import { useSyncSocket } from './state/syncSocketStore';

function InitSocketConnection() {
    const communicationSocket = useRef<unknown | null>(useCommunicationSocket());
    const syncSocket = useRef<unknown | null>(useSyncSocket());

    useEffect(() => {
        return () => {
            communicationSocket.current = null;
            syncSocket.current = null;
            resetGlobalState(import.meta.env['VITE_PUBLIC_WEBSOCKET_SERVER']);
            resetGlobalState(import.meta.env['VITE_PUBLIC_WEBSOCKET_SYNC_SERVER']);
        };
    }, []);

    return null;
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <>
        <BrowserRouter>
            <BoothApp />
        </BrowserRouter>
        <InitSocketConnection />
    </>,
);
