import * as css from './Logo.css';

export default function ElvaLogoName({
    width = 115,
    textColor = '#1E293B',
}:{
    width?:number;
    textColor?:string;
}) {
    return (
        <div className={css.logo}>
            <svg
                className={css.svg}
                style={{ width }}
                viewBox="0 0 116 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M115.449 21.248h-3.498L110.3 17.99h-9.949L98.7 21.25h-3.498l8.212-15.425h3.823l8.212 15.424Zm-11.905-9.515-2.02 3.954h7.603l-2.02-3.954c-.891-1.673-1.477-2.954-1.76-3.823h-.043c-.283.869-.869 2.15-1.76 3.823ZM89.359 21.248h-3.824L77.65 5.824h3.627l5.605 11.231c.239.522.435 1 .543 1.456h.065c.109-.456.305-.934.543-1.456l5.605-11.23h3.628l-7.907 15.423ZM80.184 21.248H65.78V5.824h3.258V18.62h11.145v2.63ZM49.967 14.536v4.279h11.949v2.433H46.709V5.824h15.054v2.433H49.967v3.976h11.427v2.303H49.967Z"
                    fill={textColor}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.909.965a29.13 29.13 0 0 0-2.558.317l-.758.137c-.74.143-1.481.314-2.22.512l-1.13.325c-.654.2-1.304.422-1.945.664l-1.211.485c-.64.272-1.27.565-1.883.877l-.965.514c-.656.366-1.291.756-1.899 1.168l-.592.414a26.38 26.38 0 0 0-1.855 1.485l-.264.235c-.63.57-1.213 1.155-1.747 1.752L4.751 10a19.434 19.434 0 0 0-1.536 1.99l-.03.044c-.473.711-.877 1.43-1.207 2.15l-.034.076c-.337.745-.594 1.49-.768 2.227l-.05.221a9.788 9.788 0 0 0-.214 1.665 8.875 8.875 0 0 0-.005.53l3.962-1.196-3.94 1.67c.053.696.192 1.377.422 2.035l7.297-3.093-6.973 3.88a8.305 8.305 0 0 0 1.1 1.722l9.467-5.27-8.446 6.333c.499.448 1.066.873 1.706 1.269l11.348-8.508-9.16 9.63c.293.123.595.242.908.357.391.143.785.277 1.181.404l11.18-11.754-7.576 12.674c.717.139 1.44.255 2.167.348l8.85-14.804-3.381 15.084c.704-.016 1.409-.05 2.112-.104l3.685-16.442 2.835 15.42a42.957 42.957 0 0 0 1.977-.52l-2.942-16 9.227 13.63a39.307 39.307 0 0 0 1.835-.926l-8.575-12.666.812-.535c.852-.64 1.664-1.39 2.215-2.316.552-.926 1.055-2.066.972-3.367-.051-.803-.404-1.536-.894-2.173-.486-.6-1.16-1.129-1.996-1.557l-.605-.281c-.832-.349-1.795-.61-2.873-.764l-1.193-.13c-.96-.076-1.96-.085-2.986-.033l-.716.046Zm5.756 8.111-.289.345c-1.11 1.23-3.027 2.397-5.355 3.132-4.105 1.295-7.933.757-8.551-1.203-.619-1.96 2.207-4.597 6.311-5.893.43-.135.857-.25 1.278-.347l.14-.031c3.535-.772 6.587-.15 7.133 1.581.235.744-.027 1.587-.667 2.416Z"
                    fill="url(#a)"
                />
                <defs>
                    <radialGradient
                        id="a"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="matrix(13.01134 19.06247 -26.3153 17.96185 27.59 6.862)"
                    >
                        <stop stopColor="#A3FC6B" />
                        <stop offset="1" stopColor="#65D71F" />
                    </radialGradient>
                </defs>
            </svg>
        </div>
    );
}
