import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { MotionValue } from 'motion/react';

import { CameraAngles } from '../../utils/types/camera';

// Initial render (before measure) should be iPad Pro's playback scrubber width.
const BEST_GUESS_TRAVEL_DISTANCE = 920;

type PlaybackState = {
    replayProgress:number;
    autoPlaying:boolean;
    activeCameraAngle:CameraAngles | null;
    playbackSpeed:number;
    handlerMotionValue:MotionValue<number>;
    travelDistance:number;
}

export const useVideoPlaybackStore = create<PlaybackState>()(
    subscribeWithSelector((get, set) => ({
        replayProgress: 0,
        autoPlaying: false,
        activeCameraAngle: null,
        playbackSpeed: 1,
        handlerMotionValue: new MotionValue(),
        travelDistance: BEST_GUESS_TRAVEL_DISTANCE,
    })),
);
