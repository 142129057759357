export function Dots({
    width = 16,
    height,
    color = '#3B82F6',
}:{
    width?:number;
    height?:number;
    color?:string;
}) {
    const w = width;
    const h = height ?? width;
    return (
        <svg
            width={w}
            height={h}
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 6.5a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 3a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm-5-3a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 3a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm10-3a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 3a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
                fill={color}
            />
        </svg>
    );
}
