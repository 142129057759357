import { useCallback, useContext, useMemo } from 'react';
import { useForceUpdate } from 'motion/react';
import _ from 'lodash';

import { BackNavigation } from '../../components/Navigation/BackNavigation';
import { Lego as LegoIcon } from '../../components/ui/icons/Lego';
import { Button, typography } from '@common/ui';
import { useUserSettingsStore } from '../../state/userSettingsStore';
import { useUIComponentValues } from '../../state/globalStore';
import { deferOnChange, inferSwingPosition, isToggledOn, setToggle, SwingPosition, swingPositionToPositionNumber, UINodeRelation, UINodeToggler, UIParameter, UISwingFoundations, getLocalizedSwingPositionName, localize } from '@common';
import { Parameter } from '../../components/UIComponents/implementations/Parameter/Parameter';
import { UserSettingsContext } from '../../utils/contexts';

import * as css from './SwingFoundationsEditor.css';

//! REFACTOR functionality shared between SwingFoundationsEditor, CustomModuleEditor and KeyParameterEditor

type Toggler = UINodeToggler;
interface ParameterToggler {
    readonly node:UIParameter & { position:string };
    readonly togglers:Toggler[];
}

export default function SwingFoundationsEditor() {
    const [forceUpdate] = useForceUpdate();
    const [uiNodeTree, originalLayout]
        = useUserSettingsStore(state => [state.uiNodes, state.activeLayout]);
    const { updateUserLayout } = useContext(UserSettingsContext);
    
    const [layoutBeingEdited, parameters, positionGroups] = useMemo(() => {
        const layout = _.cloneDeep(originalLayout);
        if(!layout) return [null, null] as const;

        const swingFoundationNodes = _(uiNodeTree?.nodes ?? [])
            .filter(c => c.type === 'swing_foundations')
            .keyBy(n => inferSwingPosition(n.id) || 'all')
            .value() as { [key in SwingPosition|'all']:UISwingFoundations };
        
        const parameters = _(uiNodeTree?.relations ?? [])
            .concat((layout?.customizations as UINodeRelation[]) ?? [])
            .filter(r => _.some(swingFoundationNodes, n => n.id === r.parent_ui_node_id))
            .uniqBy(r => r.child_ui_node_id)
            .map(r => _.find(uiNodeTree?.nodes, n => n.id === r.child_ui_node_id))
            .filter(n => n?.type === 'parameter')
            .map(node => {
                const position = inferSwingPosition(node?.id) ?? 'p1';
                const togglers = _(
                    [
                        swingFoundationNodes[position],
                        swingFoundationNodes.all,
                    ])
                    .compact()
                    .map(parentNode =>
                        ({
                            layout,
                            parentNode,
                            childNode: node,
                            currentDevices: [...parentNode.show_on],
                            defaultDevices: [...parentNode.show_on],
                            defaultChildMetadata: {},
                            onChange: forceUpdate,
                        }) as Toggler
                    )
                    .value();

                return togglers.length
                    ? {
                        node: { ...node, position } as UIParameter & { position:string },
                        togglers
                    } as ParameterToggler
                    : null;
            })
            .compact()
            .value();

        // Group by position
        const groups = _(parameters)
            .groupBy(param => param.node.position)
            .map((positionParameters, position) => ({
                position,
                positionNumber: swingPositionToPositionNumber(position as SwingPosition),
                parameters: _(positionParameters)
                    .sortBy(param => param.node.name?.value)
                    .value()
            }))
            .sortBy(p => p.positionNumber)
            .value();

        return [layout, parameters, groups] as const;
    }, [originalLayout?.id]); // eslint-disable-line react-hooks/exhaustive-deps


    const getCategorizedParameters = (
        parameters:ParameterToggler[],
        groupBy:keyof ParameterToggler['node']['categories'],
    ) => _(parameters)
        .groupBy(toggler => toggler.node.categories[groupBy])
        .map((params, categoryID) => ({
            category: _.find(uiNodeTree?.categories, c => c.id === categoryID) || null,
            parameters: params
        }))
        .sortBy(group => group.category?.display_order ?? Infinity)
        .value();

    const values$ = useUIComponentValues();

    const setTogglers = useCallback(
        (togglers:Toggler[], newValue:boolean) => {
            deferOnChange(togglers, () => {
                for(const t of togglers)
                    setToggle(t, newValue);
            });
        },
        []
    );

    const selectAll = () => {
        const togglers = _.flatMap(parameters, param => _.map(param.togglers, toggler => toggler));
        setTogglers(togglers, true);
    };
    
    return (
        <div className={css.root}>
            <BackNavigation
                screenTitle={localize('swing_foundations_editor.title')}
                onSave={() => layoutBeingEdited && updateUserLayout(layoutBeingEdited) || Promise.resolve()}
                buttons={[
                    {
                        label: localize('swing_foundations_editor.select_all_button'),
                        onClick: selectAll,
                    }
                ]}
            />
            {positionGroups && _.map(positionGroups, ({ position, parameters }) => {
                const categorizedParameters = getCategorizedParameters(parameters, 'swing_foundation_group');
                const togglers = _.flatMap(parameters, param => _.map(param.togglers, toggler => toggler));
                const toggleAll = (on:boolean) => setTogglers(togglers, on);
                const toggledOnCount = _.filter(togglers, (toggler => isToggledOn(toggler))).length;
                const totalCount = togglers.length;
                const canClearAll = Boolean(toggledOnCount);
                const canSelectAll = toggledOnCount !== totalCount;

                return (
                    <div key={position} className={css.position}>
                        <div className={css.positionTop}>
                            <div className={css.positionTitle}>
                                <LegoIcon />
                                <p className={typography({variant: 'h3'})}>
                                    {getLocalizedSwingPositionName(position as SwingPosition)}
                                </p>
                            </div>
                            <div className={css.buttons}>
                                <Button variant="secondary" size="small" isDisabled={!canClearAll} onClick={() => toggleAll(false)}>
                                    {localize('swing_foundations_editor.clear_all_button')}
                                </Button>
                                <Button variant="primary" size="small" isDisabled={!canSelectAll} onClick={() => toggleAll(true)}>
                                    {localize('swing_foundations_editor.select_all_button')}
                                </Button>
                            </div>
                        </div>
                        <div className={css.groupContainer}>
                            {_.map(categorizedParameters, ({category, parameters}) => {
                                return (
                                    <div key={category?.id || 'other'} className={css.group}>
                                        <h4 className={typography({variant:'h3'})}>
                                            {category?.name?.value || 'Other'}
                                        </h4>
                                        <div className={css.parameters}>
                                            {_.map(parameters, ({togglers, node}) => {
                                                const isOn = _.every(togglers, isToggledOn);
                                                return uiNodeTree && (
                                                    <Parameter
                                                        key={node.id}
                                                        node={node}
                                                        values$={values$}
                                                        currentDevice="kiosk"
                                                        children={[]}
                                                        uiNodeTree={uiNodeTree}
                                                        onClick={() => setTogglers(togglers, !isOn)}
                                                        isSelected={isOn}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

