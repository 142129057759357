import styled from 'styled-components';

type LogoProps = {
    width?:number;
    height?:number;
    color?:string;
};

export const StyledLogo = styled.svg<LogoProps>`
    path {
        fill: ${({ theme, color }) => (color
        ? color
        : theme['colors'].blue[500])};
    }
`;

export function Logo({ ...props }:LogoProps) {
    return (
        <StyledLogo
            fillRule="evenodd"
            clipRule="evenodd"
            {...props}
            viewBox="0 0 89 27"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="m70.762 10.12 7.723 16.809h9.538L75.416.305l-4.654 9.814ZM75.01.07H65.87l-.499 1.07-7.719 16.3-7.98-17.37h-9.538l12.718 26.858h9.435l.498-1.07 2.097-4.428a.227.227 0 0 1 .011-.029l1.533-3.232 4.078-8.611.003.006L75.01.071Z" />
            <path d="M13.354.071h9.426l8.482 17.912v8.946H25.96L17.918 9.423 9.763 26.929H.636L13.354.071ZM31.73 26.929h8.491V.071H31.73V26.93Z" />
        </StyledLogo>
    );
}
