import _ from 'lodash';
import UIComponent from '../UIComponent';
import VStack from '../../ui/VStack';
import { MetadataOf } from '@common';
import { ImplementationOf } from '../UIComponent.types';

const defaults:MetadataOf<'column'> = {
    spacing: '10px',
    alignment: 'start',
    direction: 'normal',
};

export const Column:ImplementationOf<'column'>
= ({ node: { id, metadata }, children }) => {
    const { spacing, alignment, direction } = _.defaults(metadata, defaults);
    return (
        <VStack
            key={id}
            spacing={spacing}
            alignment={alignment}
            direction={direction}
        >
            {_.map(children, (props) => (
                <UIComponent key={props.currentNode.id} {...props} />
            ))}
        </VStack>
    );
};
