import { assignInlineVars } from '@vanilla-extract/dynamic';
import { colorVars } from '../../../styles/colors.css';
import * as css from './OpenCloseButton.css';

export function OpenCloseButton({
    isOpen = false,
    iconColor = colorVars.blueGray600,
    iconBorderColor = colorVars.blueGray300,
}:{
    isOpen:boolean;
    iconColor?:string;
    iconBorderColor?:string;
}) {
    return (
        <button
            className={css.actionButton}
            style={assignInlineVars({
                [css.iconColor]: iconColor,
                [css.iconBorderColor]: iconBorderColor,
            })}
        >
            <svg
                width="24"
                height="24"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                {isOpen
                    ? (
                        <path
                            d="M21.5 12.25C21.5 12.4489 21.421 12.6397 21.2803 12.7803C21.1397 12.921 20.9489 13 20.75 13H4.25C4.05109 13 3.86032 12.921 3.71967 12.7803C3.57902 12.6397 3.5 12.4489 3.5 12.25C3.5 12.0511 3.57902 11.8603 3.71967 11.7197C3.86032 11.579 4.05109 11.5 4.25 11.5H20.75C20.9489 11.5 21.1397 11.579 21.2803 11.7197C21.421 11.8603 21.5 12.0511 21.5 12.25Z"
                            fill="currentColor"
                        />
                    )
                    : (
                        <path
                            d="M21.5 12.5C21.5 12.6989 21.421 12.8897 21.2803 13.0303C21.1397 13.171 20.9489 13.25 20.75 13.25H13.25V20.75C13.25 20.9489 13.171 21.1397 13.0303 21.2803C12.8897 21.421 12.6989 21.5 12.5 21.5C12.3011 21.5 12.1103 21.421 11.9697 21.2803C11.829 21.1397 11.75 20.9489 11.75 20.75V13.25H4.25C4.05109 13.25 3.86032 13.171 3.71967 13.0303C3.57902 12.8897 3.5 12.6989 3.5 12.5C3.5 12.3011 3.57902 12.1103 3.71967 11.9697C3.86032 11.829 4.05109 11.75 4.25 11.75H11.75V4.25C11.75 4.05109 11.829 3.86032 11.9697 3.71967C12.1103 3.57902 12.3011 3.5 12.5 3.5C12.6989 3.5 12.8897 3.57902 13.0303 3.71967C13.171 3.86032 13.25 4.05109 13.25 4.25V11.75H20.75C20.9489 11.75 21.1397 11.829 21.2803 11.9697C21.421 12.1103 21.5 12.3011 21.5 12.5Z"
                            fill="currentColor"
                        />
                    )}
            </svg>
        </button>
    );
}
