// cspell: words ndarray coeffs extrinsics

/**
 * A map of camera names to corresponding IDs for quick reference.
 */
export interface CameraNameToIDMap {
    [key:string]:string;
}

/**
 * Intrinsic parameters defining the internal characteristics of a camera.
 */
export interface Intrinsics {
    fx:number; // Focal length in pixels along the x-axis
    fy:number; // Focal length in pixels along the y-axis
    cx:number; // X coordinate of the principal point4
    cy:number; // Y coordinate of the principal point
    width:number; // Image width in pixels
    height:number; // Image height in pixels
    distortion_model:string; // Distortion model type, typically 'opencv'
    distortion_coeffs:DistortionCoefficients; // Coefficients for the distortion model
}

/**
 * Distortion coefficients used to describe the camera lens distortion.
 */
export interface DistortionCoefficients {
    __ndarray__:number[]; // Array of distortion coefficients
}

/**
 * Extrinsic parameters defining a camera's position and orientation in space.
 */
export interface Extrinsic {
    __ndarray__:number[][]; // A 4x4 transformation matrix in row-major order
}

/**
 * Cameras' positions and orientations in a world coordinate system.
 */
export interface CamerasInWorld {
    __ndarray__:number[][]; // A 4x4 transformation matrix in row-major order
}

/**
 * Represents both translation and rotation in 3D space for a camera.
 */
export interface TranslationRotation {
    translation:number[]; // XYZ coordinates in space
    rotation_euler_xyz:number[]; // Euler angles (in radians) for rotation about each principal axis
}

/**
 * Detailed configuration for a camera system retrieved from a server.
 * Includes identification, mapping, intrinsic parameters, extrinsic parameters,
 * and specific camera positions in a global reference frame.
 */
export interface CameraConfig {
    /** Array of camera IDs used in the system. */
    camera_ids:string[];

    /** Mapping of camera IDs to human-readable names. */
    camera_id_to_name:CameraNameToIDMap;

    /** Intrinsic parameters of each camera indexed by camera ID. */
    intrinsics:{ [key:string]:Intrinsics };

    /** Extrinsic parameters defining the camera's position and orientation in space, indexed by camera ID. */
    extrinsics:{ [key:string]:Extrinsic };

    /** Positions and orientations of cameras in a world coordinate system, indexed by camera ID. */
    cameras_in_world:{ [key:string]:CamerasInWorld };

    /** Special configuration for the 'face_on' camera in the global frame. */
    face_on_in_global_frame:TranslationRotation;

    /** Optional property to apply 2x binning for intrinsics calculation. */
    apply_2x_binning_intrinsics?:unknown;
}

/**
 * Simplified camera configuration format where properties are accessed
 * via human-readable camera names rather than numeric IDs.
 */
export interface SimplifiedCameraConfig {
    /** Intrinsic parameters of each camera indexed by human-readable name. */
    intrinsics:{ [name:string]:Intrinsics };

    /** Extrinsic parameters of each camera indexed by human-readable name. */
    extrinsics:{ [name:string]:Extrinsic };

    /** Cameras' positions and orientations in a world coordinate system, indexed by human-readable name. */
    cameras_in_world:{ [name:string]:CamerasInWorld };

    camera_ids:string[];

    camera_id_to_name:Record<number, string>;
}

export const cameraAngles = [
    'face_on',
    'down_the_line',
    'trail_front',
    'back',
] as const;

export type CameraAngles = (typeof cameraAngles)[number];
