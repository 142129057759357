export function CircularClose({
    width = 24,
    height,
    color = '#3B82F6',
}:{
    width?:number;
    height?:number;
    color?:string;
}) {
    const w = width;
    const h = height ?? width;

    return (
        <svg
            viewBox="0 0 24 24"
            width={w}
            height={h}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.25 2.5A9.75 9.75 0 1 0 22 12.25a9.76 9.76 0 0 0-9.75-9.75Zm3.53 12.22a.75.75 0 1 1-1.06 1.06l-2.47-2.47-2.47 2.47a.75.75 0 1 1-1.06-1.06l2.47-2.47-2.47-2.47a.75.75 0 0 1 1.06-1.06l2.47 2.47 2.47-2.47a.751.751 0 0 1 1.06 1.06l-2.47 2.47 2.47 2.47Z"
                fill={color}
            />
        </svg>
    );
}
