import { L10n, snake_case } from './l10n.types';
import { NullableProperty, RequiredProperty } from './type-utils';
import { FlexAlignment, FlexDirection, FlexSpacing } from './ui.types';

/**
 * GET /v1/ui-nodes
 * returns an UINodesAndRelations with all the nodes and relations
 */
export interface UINodeTree {
    readonly nodes:AnyUINode[];
    readonly relations:UINodeRelation[];
    readonly categories:Category[];
}

export type AnyUINode
    = UIParameter
    | UIGraphLine
    | UIGraph
    | UIModule
    | UISwingFoundations
    | UIActivityNavigation
    | UIPage
    | UIGrid
    | UIRow
    | UIColumn;

export type UINodeType = AnyUINode['type'];

export type UINodeID = AnyUINode['id'];

export type MetadataOf<T extends AnyUINode | UINodeType>
    = T extends AnyUINode
        ? T['metadata']
        : Extract<AnyUINode, { type:T }>['metadata'];

/**
 * GET /v1/ui-nodes/layouts
 * returns a list of the user's Layouts
 */
export interface Layout {
    readonly id:number;
    readonly owner_user_id:string;
    readonly name:string;
    readonly customizations:LayoutCustomization[];
}

export type Unit
    = 'm'
    | 'm/s'
    | 'm/s^2'
    | 'rad'
    | 'rad/s'
    | 'rad/s^2'
    | 'ratio';

export type Axis
    = 'x'
    | 'y'
    | 'z'
    | 'w';
    
export const ALL_AXES:Axis[] = ['x', 'y', 'z', 'w'];

export type CategoryType
    = 'metric'
    | 'body_part'
    | 'item_part'
    | 'graph_type'
    | 'swing_foundation_group';

export type CategoryID<T extends CategoryType> = `${T}.${snake_case}`;

export type AnyCategoryID = CategoryID<CategoryType>;

export type Empty = Record<never, never>;

export interface Categories {
    readonly metric?:CategoryID<'metric'>;
    readonly graph_type?:CategoryID<'graph_type'>;
    readonly swing_foundation_group?:CategoryID<'swing_foundation_group'>;
    readonly body_parts?:CategoryID<'body_part'>[];
    readonly item_parts?:CategoryID<'item_part'>[];
}

/**
 * GET /v1/ui-nodes/categories
 * returns a list of all available ui-node categories
 */
export interface Category<T extends CategoryType = CategoryType> extends HasName {
    readonly id:CategoryID<T>;
    readonly display_order:number;
}

export type ParameterCategories = Pick<RequiredProperty<Categories, 'metric'>, 'metric' | 'swing_foundation_group' | 'body_parts' | 'item_parts'>;
export type GraphLineCategories = Pick<RequiredProperty<Categories, 'metric'>, 'metric' | 'body_parts' | 'item_parts'>;
export type GraphCategories = Pick<RequiredProperty<Categories, 'graph_type'>, 'graph_type'>;
export type UINode<
    TType extends snake_case = snake_case,
    TMetadata extends object = Empty,
    TProperties extends object = Empty
> = {
    readonly id:`${TType}.${snake_case}`;
    readonly type:TType;
    readonly show_on:Device[]; // overridable in LayoutCustomization
    readonly categories:Categories;
    readonly metadata:{
        // overridable in LayoutCustomization
        readonly [P in keyof TMetadata]?:TMetadata[P];
    }
} & HasName & HasShortName & TProperties;

export type UIModule = UINode<'module', {title?:string}, Empty>;
export type UISwingFoundations = UINode<'swing_foundations', Empty, Empty>;
export type UIParameter = UINode<
    'parameter',
    {
        hide_position_in_name?:boolean;
    },
    {
        readonly parameter:Parameter;
        readonly categories:ParameterCategories;
    }
>;

export type UIGraphLine = UINode<
    'graph_line',
    {
        readonly color:string;
        readonly line_thickness:number;
        readonly show_units_on_left_y_axis:boolean;
        readonly show_units_on_right_y_axis:boolean;
    },
    {
        readonly time_series:TimeSeries;
        readonly axis:Axis|null;
        readonly categories:GraphLineCategories;
    }
>;
export type UIGraph = UINode<
    'graph',
    Empty,
    {
        readonly categories:GraphCategories;
    }
>;

export type UIActivityNavigation = UINode<
    'activity_navigation',
    Empty,
    Empty
>;

export const DEFAULT_KEY_PARAMETER_ID = 'pelvis_sway_p7';

export type UIPage = UINode<
	'page',
	{
		/** The number of milliseconds until transitioning to the next page */
		readonly duration:number;
	},
	Empty
>;

export type UIGrid = UINode<
    'grid',
    Empty,
    Empty
>;

export type UIRow = UINode<
    'row',
    {
        spacing?:FlexSpacing;
        alignment?:FlexAlignment;
        direction?:FlexDirection;
    },
    Empty
>;

export type UIColumn = UINode<
    'column',
    {
        spacing?:FlexSpacing;
        alignment?:FlexAlignment;
        direction?:FlexDirection;
    },
    Empty
>;

export interface UINodeRelation {
    readonly parent_ui_node_id:UINodeID;
    readonly child_ui_node_id:UINodeID;

    /** The order in which the given child is displayed within the given parent */
    readonly child_display_order:number;

    /** {} = Do not override any of the child's metadata */
    readonly child_metadata:object;

    /** null = Do not override on what device the child is visible */
    readonly show_child_on:Device[]|null;
}
export type LayoutCustomization = NullableProperty<UINodeRelation, 'parent_ui_node_id' | 'child_display_order'>;

export interface Parameter {
    readonly id:snake_case;
    readonly unit:Unit;
    readonly axis:Axis|null;
    readonly time_series_id:snake_case|null;
}

export interface TimeSeries {
    readonly id:snake_case;
    readonly unit:Unit;
}

export interface HasName {

    /** This is always populated by the API if available.
     * It uses the `locale` query parameter to pick a locale
     * but defaults to the `Accept-Language` header
     * or `'en-US'` if neither are present */
    readonly name:L10n|null;
}

export interface HasShortName {
    /** This is always populated by the API if available.
     * It uses the `locale` query parameter to pick a locale
     * but defaults to the `Accept-Language` header
     * or `'en-US'` if neither are present */
    readonly short_name:L10n|null;
}

export type Device = 'kiosk' | 'floor';
export const ALL_DEVICES:Device[] = ['kiosk', 'floor'];

export function isUINodeType<T extends UINode>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    node:any,
    type:UINode['type'],
):node is T {
    return node && node.type === type;
}
