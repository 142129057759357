export function Check({
    width = 24,
    height,
}:{
    width?:number;
    height?:number;
}) {
    const w = width;
    const h = height ?? width;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={w}
            height={h}
            viewBox="0 0 256 256"
        >
            <path
                fill="#475569"
                d="m229.66 77.66-128 128a8 8 0 0 1-11.32 0l-56-56a8 8 0 0 1 11.32-11.32L96 188.69 218.34 66.34a8 8 0 0 1 11.32 11.32Z"
            />
        </svg>
    );
}
