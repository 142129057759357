import { create } from 'zustand';

import { subscribeWithSelector } from 'zustand/middleware';
import useWebSocket, { type SendMessage } from 'react-use-websocket';
import type { WebSocketMessage } from 'react-use-websocket/dist/lib/types';
import { SocketPayload } from './syncSocketStore.types';
import { debounce } from '../../utils/debounce';

export const useSyncSocket = () => {
    const setSendMessage = useSyncSocketStore(
        (state) => state.actions.setSenderFn,
    );
    const onMessage = useSyncSocketStore((state) => state.actions.onMessage);
    const setIsConnected = useSyncSocketStore(
        (state) => state.actions.setConnected,
    );

    const onOpen = () => {
        setIsConnected(true);
        setSendMessage(sendJsonMessage);
    };

    const onClose = () => setIsConnected(false);

    const messageHandler = (message:MessageEvent) => {
        if(message.type === 'message' && typeof onMessage === 'function') {
            try {
                const data = JSON.parse(message.data) as SocketPayload;
                onMessage(data);
            } catch(e) {
                console.error('WebSocket data parsing error:', e);
                return;
            }
        }
    };

    const debouncedMessage = debounce(messageHandler, 100);

    const { sendJsonMessage } = useWebSocket(
        import.meta.env['VITE_PUBLIC_WEBSOCKET_SYNC_SERVER'],
        {
            onOpen: onOpen,
            onClose: onClose,
            onMessage: (message:MessageEvent) => {
                debouncedMessage(message);
            },

            filter: (message) => {
                const status = JSON.parse(message.data).type;

                if(status === 'BEAST_STATUS') {
                    return false; // Not forwarding BEAST_STATUS messages for NOW.
                }

                return true;
            },
            // Should always reconnect
            shouldReconnect: () => true, // return mounted.current === false
            reconnectAttempts: 1000, // ~ 30 minute reconnection window
            reconnectInterval: 2000, // Try reconnecting every two secs
        },
    );
};

type SyncSocketStore = {
    senderFn:SendMessage | null;
    isConnected:boolean;
    payload:SocketPayload | null;
    actions:{
        setSenderFn:(fn:SendMessage) => void;
        setConnected:(connected:boolean) => void;
        sendSyncMessage:(payload:SocketPayload) => void;
        onMessage:(payload:SocketPayload) => void;
    };
};

export const useSyncSocketStore = create<SyncSocketStore>()(
    subscribeWithSelector((set, get) => ({
        senderFn: null,
        reciverFn: () => void 0,
        isConnected: false,
        payload: null,
        actions: {
            setSenderFn: (fn) => {
                set({ senderFn: fn });
            },

            setConnected: (connected) => set({ isConnected: connected }),
            sendSyncMessage: (payload) => {
                const senderFn = get().senderFn;
                if(typeof senderFn === 'function') {
                    senderFn(payload as unknown as WebSocketMessage);
                }
            },
            onMessage: (payload) => {
                set({ payload });
            },
        },
    })),
);
