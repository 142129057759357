import _ from 'lodash';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import { typography } from '../../../styles/typography.css';
import { LegendPayload } from '../types';

import * as css from '../DynamicGraph.css';

export const renderLegend = (payload?:LegendPayload[], filterCallback?:(dataKey:string) => void) => (
    <ul className={css.legendLayout}>
        {!_.isEmpty(payload) &&
            _.map(payload, (entry, index) => {
                // Render custom label if it was provided
                const value = entry.payload?.label || entry.value;

                return (
                    <li
                        key={`${entry.value}_${index}`}
                        className={css.legend({ inactive: entry.inactive })}
                        onClick={() => {
                            if(typeof filterCallback === 'function') {
                                filterCallback(entry.dataKey);
                            }
                        }}
                    >
                        <span
                            className={typography({
                                size: 'h4',
                                color: 'gray',
                                case: 'uppercase',
                                weight: 'bold',
                            })}
                        >
                            {value}
                        </span>
                        <div
                            className={css.legendLine({ dotted: false })}
                            style={assignInlineVars({
                                [css.legendLineColor]: entry.color,
                            })}
                        />
                    </li>
                );
            })
        }
    </ul>
);
