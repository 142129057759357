import * as THREE from 'three';
import {
    Fragment,
    Suspense,
    useLayoutEffect,
} from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { Box, Flex } from '@react-three/flex';
import { Line, OrthographicCamera, Plane } from '@react-three/drei';
import useMeasure from 'react-use-measure';
import { useVideoTexture } from '../../utils/hooks/useVideoTexture';

import { useGlobalStore, useSelectedAnalysis } from '../../state/globalStore';
import {
    type CameraAngles,
    SimplifiedCameraConfig,
} from '../../utils/types/camera';
import { computePoints } from './utils';
import { type Analysis, type IVideo } from '@common';
import { colorVars } from '../../styles/colors.css';
import { ANNOTATION_LINE_THICKNESS } from './consts';
import _ from 'lodash';

type Line = {
    startX:number;
    startY:number;
    endX:number;
    endY:number;
};

type SceneProps = {
    videos:Analysis['data']['videos'];
    cameraConfig:SimplifiedCameraConfig;
    scale?:number;
    lines?:Line[];
    cameraAngle?:CameraAngles;
    skeletonOverlay?:boolean;
    floorFrame?:number;
};

type SkeletonProps = {
    cameraConfig:SimplifiedCameraConfig;
    cameraAngle?:CameraAngles;
    skeletonOverlay?:boolean;
};

function DrawBones({
    cameraAngle,
    cameraConfig,
}:{
    cameraAngle:CameraAngles;
    cameraConfig:SimplifiedCameraConfig;
}) {
    const currentFrameIndex = useGlobalStore((state) => state.currentFrame);

    const activeSwingAnalysis = useSelectedAnalysis();

    if(!activeSwingAnalysis) {
        return null;
    }

    const frames = activeSwingAnalysis.data.analysis?.frames;
    const bones = activeSwingAnalysis.data.analysis?.bones;

    if(!Array.isArray(frames)) {
        console.error('No skeleton frames.');
        return null;
    }

    if(!Array.isArray(bones)) {
        console.error('No skeleton bones.');
        return null;
    }


    const segmentationFrames = _.values(activeSwingAnalysis.data.analysis?.segmentation);
    const quickAnalysisFrameIndex = _.findIndex(segmentationFrames, frame => frame === currentFrameIndex);
    const frameIndex = frames.length <= 10
        ? quickAnalysisFrameIndex
        : currentFrameIndex;

    const skeletonDict = frames[frameIndex] as { [key:number]:number[] };

    if(!skeletonDict) {
        return null;
    }

    const skeletonPoints = _.values(skeletonDict);
    const skeletonKeys = _.keys(skeletonDict);

    const skeletonPoints2D = computePoints(skeletonPoints, cameraAngle, cameraConfig);

    const skeletonDict2D:{ [key:string]:number[] } = {};

    for(let i = 0; i < skeletonKeys.length; i++) {
        const key = skeletonKeys[i];
        const point2D = skeletonPoints2D[i];
        skeletonDict2D[key] = point2D;
    }

    return (
        <group>
            {_.map(bones, (bone) => {
                const startPointIndex = Array.isArray(bone)
                    ? bone[0]
                    : bone.start_point_id;
                const endPointIndex = Array.isArray(bone)
                    ? bone[1]
                    : bone.end_point_id;

                const startPoint = skeletonDict2D[startPointIndex] ?? null;
                const endPoint = skeletonDict2D[endPointIndex] ?? null;


                if(startPoint === null) {
                    console.log('null');
                    // Startpoint out of range...
                    console.warn('Bone startPoint out of range:', bone.start_point_id);
                    return null;
                }

                if(endPoint === null) {
                    // Endpoint out of range...
                    console.warn('Bone endPoint out of range:', bone.end_point_id);
                    return null;
                }


                return (
                    <Fragment key={`bone_${startPointIndex}_${endPointIndex}`}>
                        <mesh position={[endPoint?.[0], endPoint?.[1], -0.00001]}>
                            <meshBasicMaterial color={'orange'} side={THREE.DoubleSide} />
                            <circleGeometry attach="geometry" args={[15, 10]} />
                        </mesh>
                        <Line
                            color={0xffffff}
                            lineWidth={ANNOTATION_LINE_THICKNESS}
                            points={[
                                new THREE.Vector3(
                                    startPoint?.[0],
                                    startPoint?.[1],
                                    startPoint?.[2],
                                ),
                                new THREE.Vector3(endPoint?.[0], endPoint?.[1], endPoint?.[2]),
                            ]}
                        />
                    </Fragment>
                );
            })}
        </group>
    );
}

function SkeletonRenderer({
    cameraAngle = 'face_on',
    cameraConfig,
    skeletonOverlay = false,
}:SkeletonProps) {
    const forceHideSkeleton = useGlobalStore((state) => state.forceHideSkeleton);

    const activeSwingAnalysis = useSelectedAnalysis();


    if(!activeSwingAnalysis?.id) {
        return null;
    }

    if(forceHideSkeleton) {
        return null;
    }

    const { width, height } = cameraConfig.intrinsics[cameraAngle];

    return (
        <group
            rotation={[Math.PI, 0, 0]}
            position={[-width / 2, height / 2, 0]}
            renderOrder={0}
        >
            {skeletonOverlay && (
                <DrawBones cameraAngle={cameraAngle} cameraConfig={cameraConfig} />
            )}
        </group>
    );
}

function Scene({
    videos,
    scale = 1,
    cameraAngle = 'face_on',
    cameraConfig,
    skeletonOverlay = false,
    floorFrame,
}:SceneProps) {
    const { height: videoHeight, width: videoWidth } =
        cameraConfig.intrinsics[cameraAngle];

    const videoTextureUrl = _.find(videos, (video) => video.name === cameraAngle)?.url ?? '';

    const videoTexture = useVideoTexture(videoTextureUrl as string, {
        // Pause video
        start: false,
        preload: 'auto',
        crossOrigin: 'anonymous',
        floorFrame,
    });

    useLayoutEffect(() => {
        const totalFrames = videos?.[0]?.metadata.totalFrames;

        const unsubscribe = useGlobalStore.subscribe(
            (state) => state.currentFrame,
            (latestFrame) => {
                if(!videoTexture.source?.dataReady) {
                    console.error('VideoTexture not ready');
                    return;
                }

                if(!totalFrames) {
                    console.error('No frames');
                    return;
                }

                const duration = videoTexture.source.data.duration;

                // Playing all the way through `duration` can fail.
                // `duration` might not be accurate...
                const maxTime = duration - 0.05;

                // Calculate the currentTime for the video based on the latestFrame
                const currentTime = Math.min(
                    (latestFrame / totalFrames) * duration,
                    maxTime,
                );

                // Seek to `currentTime` of the video
                videoTexture.source.data.currentTime = currentTime;
            },
        );

        if(floorFrame) {
            const currentTime = (floorFrame / (totalFrames ?? 1)) * videoTexture.source.data.duration;
            videoTexture.source.data.currentTime = currentTime;
        }

        return () => {
            unsubscribe();
        };
    }, [videoTexture, videos, floorFrame]);

    if(!videoTexture?.source?.dataReady) {
        console.error('VideoTexture not ready');
        return null;
    }

    return (
        <group scale={scale}>
            <Plane
                args={[videoWidth, videoHeight, 1, 1]}
                position={[0, 0, -1000]}
                renderOrder={1}
            >
                <meshBasicMaterial map={videoTexture} transparent />
            </Plane>

            <SkeletonRenderer
                cameraAngle={cameraAngle}
                cameraConfig={cameraConfig}
                skeletonOverlay={skeletonOverlay}
            />
        </group>
    );
}

type RendererProps = {
    videos:IVideo[];
    scale:number;
    activeCameraAngles:CameraAngles[];
    cameraConfig:SimplifiedCameraConfig;
    skeletonOverlay?:boolean;
    floorFrame?:number;
};

function Renderer({
    videos,
    activeCameraAngles,
    scale = 1,
    cameraConfig,
    skeletonOverlay = false,
    floorFrame,
}:RendererProps) {
    const { height: canvasHeight, width: canvasWidth } = useThree(
        (state) => state.viewport,
    );

    return (
        <Suspense fallback={null}>
            <Flex
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="space-evenly"
                alignItems="flex-start"
                height={canvasHeight}
                width={canvasWidth}
                position={[-canvasWidth / 2, canvasHeight / 2, -1000]}
                plane="xy"
                flexShrink={0}
                flexGrow={0}
            >
                {_.map(activeCameraAngles, (cameraAngle) => (
                    <Box centerAnchor renderOrder={1} key={cameraAngle}>
                        <Scene
                            skeletonOverlay={skeletonOverlay}
                            videos={videos}
                            cameraAngle={cameraAngle}
                            cameraConfig={cameraConfig}
                            scale={scale}
                            floorFrame={floorFrame}
                        />
                    </Box>
                ))}
            </Flex>
        </Suspense>
    );
}

type VideoWithSkeletonProps = {
    videos:Analysis['data']['videos'] | null;
    layout:'grid' | 'stacked';
    cameraConfig:SimplifiedCameraConfig;
    skeletonOverlay:boolean;
    floorFrame?:number;
    cameraAngles?:CameraAngles[];
};

const VIDEO_DIMENSIONS = {
    width: 510,
    height: 428,
    sourceWidth: 2464,
    sourceHeight: 2064,
};

function VideoWithSkeleton({
    videos,
    layout = 'grid',
    cameraConfig,
    skeletonOverlay = false,
    floorFrame,
    cameraAngles,
}:VideoWithSkeletonProps) {
    const [ref, bounds] = useMeasure();
    
    const activeCameraAngles = cameraAngles ?? ['face_on', 'down_the_line'];

    const videosPerRow =
        layout === 'grid'
            ? 2
            : 1;

    const totalWidthNeeded = VIDEO_DIMENSIONS.sourceWidth * videosPerRow;
    const scale = bounds.width / totalWidthNeeded;
    const heightRatio
        = (VIDEO_DIMENSIONS.sourceHeight / VIDEO_DIMENSIONS.sourceWidth)
        * (layout === 'grid'
            ? 1
            : activeCameraAngles.length
        );

    if(!videos) {
        return (
            <div
                ref={ref}
                style={{
                    backgroundColor: floorFrame
                        ? colorVars.blueGray800
                        : colorVars.blueGray200,
                    width: '100%',
                    height: bounds.width * heightRatio,
                }}
            />
        );
    }

    return (
        <Canvas
            ref={ref}
            flat
            gl={{ antialias: true }}
            dpr={[1, 2]}
            shadows={false}
            style={{
                width: floorFrame
                    ? '150%'
                    : '100%',
                height: bounds.width * heightRatio,
                transform: floorFrame
                    ? 'translateX(-16.666%)'
                    : undefined,
                backgroundColor: floorFrame
                    ? colorVars.blueGray800
                    : colorVars.blueGray200,
            }}
        >
            <OrthographicCamera makeDefault far={100000} near={0.00001} zoom={1}>
                <Renderer
                    skeletonOverlay={skeletonOverlay}
                    videos={videos}
                    scale={scale}
                    cameraConfig={cameraConfig}
                    activeCameraAngles={activeCameraAngles as CameraAngles[]}
                    floorFrame={floorFrame}
                />
            </OrthographicCamera>
        </Canvas>
    );
}

export default VideoWithSkeleton;
