import _ from 'lodash';

import { Analysis, Nil } from '@common';
import { calculateFrameRatios } from '../../../utils/calculateFrameRatios';
import { colors } from '@common/ui';

/**
 * Returns: frame ratios, total no. of frames
 */
export const getSwingFramesInfo = (analysis?:Analysis|Nil) => {

    // Extract relevant data
    const segmentation = analysis?.data?.analysis?.segmentation ?? null;
    const totalFrames = analysis?.data?.videos?.[0]?.metadata?.totalFrames ?? null;

    // Check if segmentation and totalFrames are available before proceeding
    const swingSegmentation = segmentation
        ? Object.values(segmentation)
        : null;
    
    // Initialize frameRatios to null and calculate only if data is available
    const frameRatios = swingSegmentation && totalFrames
        ? calculateFrameRatios(swingSegmentation, totalFrames)
        : null;


    // Create a map with numeric keys (1, 2, ..., 10) from the segmentation
    const numericSegmentationMap = segmentation
        ? Object.entries(segmentation)
            .reduce((acc, [key, value]) => {
                const numKey = parseInt(key.replace('P', ''), 10); // Convert 'P1' to 1, 'P2' to 2, etc.
                acc[numKey] = value; // Assign the corresponding frame value
                return acc;
            }, {} as { [key:number]:number | null }) // Use a map with number keys
        : null;
        

    // Initialize availablePPositions to null and calculate only if segmentation is available
    const availablePPositions = segmentation
        ? Object.entries(segmentation)
            .filter(([_, value]) => value !== null)  // Filter out entries with null values
            .map(([key]) => parseInt(key.slice(1), 10)) // Remove the 'P' from the key and convert it to an integer
        : null;

    return {
        frameRatios,
        totalFrames,
        availablePPositions,
        segmentation,
        numericSegmentationMap
    };
};

export const findNextPPosition = (
    commonPPositions:number[],
    currentScrubberRatio:number,
    activeFrameRatios:{[key:string]:number;},
    direction:'up' | 'down'
):number | null => {
    if(!commonPPositions.length) return null;

    if(direction === 'up') {
        // Find the first P position with a ratio strictly greater than the scrubber ratio
        const nextPPosition = _.find(commonPPositions, (p) => activeFrameRatios[p - 1] > currentScrubberRatio);
        return nextPPosition || null;
    } else {
        // Find the last P position with a ratio strictly less than the scrubber ratio
        const prevPPosition = _.findLast(commonPPositions, (p) => activeFrameRatios[p - 1] < currentScrubberRatio);
        return prevPPosition || null;
    }
};

/**
 * Function to generate position ticks for active and comparison swings
 */
export const generatePositionTicks = (
    activeSwingFrameRatios:{ [key:string]:number },
    comparisonSwingFrameRatios:{ [key:string]:number } | null
) => {
    // Generate active swing ticks
    const activeTicks = _.values(activeSwingFrameRatios).map((val, i) => {
        if(val === -1) return null; // Invalid position

        const position = parseInt(_.keys(activeSwingFrameRatios)[i]) + 1;
        const isImpact = position === 7; // Mark special position for active swing

        return (
            <div
                key={`active-${val + i}`}
                style={{
                    width: isImpact
                        ? 2
                        : 1,
                    height: '100%',
                    position: 'absolute',
                    left: `${val * 100}%`,
                    background: colors.blue[500],
                    zIndex: 1,
                }}
            />
        );
    });

    const comparisonTicks = comparisonSwingFrameRatios
        ? _.values(comparisonSwingFrameRatios).map((val, i) => {
            if(val === -1) return null;

            const position = parseInt(_.keys(comparisonSwingFrameRatios)[i]) + 1;
            const isImpact = position === 7; // Mark special position for comparison swing

            return (
                <div
                    key={`comparison-${val + i}`}
                    style={{
                        width: isImpact
                            ? 2
                            : 1,
                        height: '100%',
                        position: 'absolute',
                        left: `${val * 100}%`,
                        borderLeft: `2px dotted ${colors.orange[500]}`, // Dotted line instead of background
                        zIndex: 0,
                    }}
                />
            );
        })
        : null; // Handle null case for comparisonSwingFrameRatios

    return (
        <>
            {activeTicks}
            {comparisonTicks}
        </>
    );
};
