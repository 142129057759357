import {
    AnyUINode,
    Corridor,
    Device,
    Empty,
    Layout,
    MetadataOf,
    Mutable,
    Swing,
    UINodeTree,
    UINodeType
} from '@common'; // <-- that name GIFLENS-https://media0.giphy.com/media/sYVgIV5N0EutwxVfDh/200.gif
import { SwingPosition } from '@common';
import { Observable } from 'rxjs';

export interface UIComponentProps<
    TNode extends AnyUINode = AnyUINode
> {
    readonly uiNodeTree:UINodeTree;
    readonly currentDevice:Device;
    readonly currentNode:TNode;
    readonly layout?:Layout;
    readonly metadataOverride?:MetadataOf<TNode>;
    readonly values$:Observable<UIComponentValues>;
}

export interface UIComponentImplementationProps<
    T extends UINodeType = UINodeType
> {
    readonly node:Extract<AnyUINode, { id:`${T}.${string}`, type:T }>;
    readonly children:UIComponentProps[];
    readonly currentDevice:Device;
    readonly uiNodeTree:UINodeTree;
    readonly values$:Observable<UIComponentValues>;
}


export interface UIComponentValues {
    readonly allSwings?:Swing[];
    readonly swing?:Swing;
    readonly comparisonSwing?:Swing;
    readonly swingPosition?:SwingPosition;
    readonly corridorPerDevice?:{ [device in Device]:Corridor };
    readonly setValues?:(valuesToSet:Partial<Mutable<UIComponentValues>>) => void;
}

export type ImplementationOf<
    T extends UINodeType,
    TExtraProps = Empty,
> = (props:UIComponentImplementationProps<T>&TExtraProps) => JSX.Element;


export function isPropsForType<TNode extends AnyUINode>(
    props:UIComponentProps,
    type:TNode['type']
):props is UIComponentProps & { currentNode:TNode, metadataOverride:TNode['metadata'] } {
    return props.currentNode?.type === type;
}
