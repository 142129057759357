import _ from 'lodash';
import { Label, ReferenceLine } from 'recharts';
import { CAMERA_FPS } from '../../../utils/consts';
import { Segmentation } from '@common';

const style = {
    color: '#94A3B8',
    WebkitFontSmoothing: 'antialiased',
    fontSize: 10,
    fontWeight: 200,
    letterSpacing: '0.05em',
    fontFamily: 'Inter',
};

const renderFullAnalysisXAxis = (segmentation:Segmentation, axisKey:string) => {
    // Filter out null positions
    const validPositions = _.entries(segmentation)
        .filter(([_, value]) => value !== null)
        .map(([key, value]) => ({ label: key, value: value as number }));

    return _.map(validPositions, ({ label, value }) => {
        const frameDifference = value - segmentation['P7'];
        const milliseconds = Math.round((frameDifference / CAMERA_FPS) * 1000);
        const sign = milliseconds >= 1
            ? '+'
            : '';

        return (
            <ReferenceLine key={label} x={value} strokeDasharray="3 3" yAxisId={axisKey}>
                <Label value={label} offset={10} position="top" style={style} />
                <Label value={`${sign}${milliseconds}ms`} offset={10} position="bottom" style={style} />
            </ReferenceLine>
        );
    });
};

const renderQuickAnalysisXAxis = (segmentation:Segmentation, activePosition:number, axisKey:string, isInteractive:boolean) => {
    return _.keys(segmentation).map((key, index) => {
        const isNull = segmentation[key as keyof Segmentation] === null;
        const isActive = index + 1 === activePosition;
        const isHighlighted = isActive && isInteractive;

        return (
            <ReferenceLine
                key={key}
                yAxisId={axisKey}
                x={index}
                stroke="#3B82F6"
                strokeDasharray={
                    isHighlighted
                        ? '0 0'
                        : '3 3'
                }
                opacity={
                    isHighlighted
                        ? 1
                        : 0.2
                }
            >
                <Label
                    value={key}
                    position="top"
                    offset={10}
                    style={{
                        ...style,
                        opacity: isNull
                            ? 0.25
                            : 1,
                    }}
                />
            </ReferenceLine>
        );
    });
};

export const renderXAxis = ({ isQuickAnalysis, segmentation, activePosition, axisKey, isInteractive }:{ isQuickAnalysis:boolean; segmentation:Segmentation; activePosition:number; axisKey:string, isInteractive:boolean }) => {
    if(isQuickAnalysis) {
        return renderQuickAnalysisXAxis(segmentation, activePosition, axisKey, isInteractive);
    }

    return renderFullAnalysisXAxis(segmentation, axisKey);
};
