export function CaretRight({
    color = '#475569',
}:{
    color?:string;
}
) {
    return (
        <svg
            viewBox="0 0 25 25"
            height={24}
            width={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m17.281 13.03-7.5 7.5a.751.751 0 0 1-1.061-1.06l6.97-6.97-6.97-6.97A.75.75 0 1 1 9.78 4.47l7.5 7.5a.75.75 0 0 1 0 1.06Z"
                fill={color}
            />
        </svg>
    );
}
