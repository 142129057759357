import React, { CSSProperties, ReactNode } from 'react';
import * as css from './HStack.css';
import classNames from 'classnames';
import _ from 'lodash';
import { FlexAlignment, FlexDirection, FlexSpacing } from '@common';

/*
 * The HStack Component
 * ====================
 *
 * The HStack (Horizontal Stack) component is designed to layout its child components
 * horizontally with a specified gap between each child. This is especially useful for
 * creating consistent horizontal spacing in your UI without having to individually manage
 * the margins of each element.
 *
 * ASCII Art Illustration
 * ----------------------
 *
 * With HStack and a spacing of 10px:
 *
 * [Component 1] → 10px → [Component 2] → 10px → [Component 3]
 *
 * The gap between the components is controlled by the `spacing` prop.
 *
 * Example Usage
 * -------------
 *
 * Here's how you might use the HStack component in your application to layout three buttons
 * with a 20px gap between each button:
 *
 * <HStack spacing={20}>
 *   <button>Button 1</button>
 *   <button>Button 2</button>
 *   <button>Button 3</button>
 * </HStack>
 *
 */

interface HStackProps extends React.HTMLAttributes<HTMLDivElement> {
    children:ReactNode;
    /** shorthand for alignment: 'center' */
    center?:boolean;
    spacing?:FlexSpacing; // The gap between elements
    alignment?:FlexAlignment;
    direction?:FlexDirection;
}

function HStack({
    children,
    center,
    spacing,
    alignment,
    direction,
    style,
    className,
    ...props
}:HStackProps) {
    // Merge dynamicStyle with any styles passed in through props
    const mergedStyles:CSSProperties = {
        ...style, // Apply props style first, so dynamic styles can override if necessary
        gap: _.isFinite(spacing) // is a number?
            ? spacing + 'px'
            : spacing ?? '10px',
        alignItems: center
            ? 'center'
            : alignment ?? undefined,
        flexDirection: direction === 'reverse'
            ? 'row-reverse'
            : undefined,
    };
    return (
        <div className={classNames([css.container, className])} style={mergedStyles} {...props}>
            {children}
        </div>
    );
}

export default HStack;
