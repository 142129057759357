
//! WHY IS "Icon" NOT IN THE NAME!?
//! GIFLENS-https://media3.giphy.com/media/Q4ScVMm5oBP44/200.gif
export function Pencil({
    width = 24,
    height,
    color = '#3B82F6',
}:{
    width?:number;
    height?:number;
    color?:string;
}) {
    const w = width;
    const h = height ?? width;

    return (
        <svg
            width={w}
            height={h}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m21.31 6.879-4.188-4.19a1.5 1.5 0 0 0-2.122 0L3.44 14.25A1.487 1.487 0 0 0 3 15.31v4.19A1.5 1.5 0 0 0 4.5 21h4.19a1.485 1.485 0 0 0 1.06-.44L21.31 9a1.5 1.5 0 0 0 0-2.121ZM4.81 15l8.47-8.47 1.564 1.565-8.469 8.469L4.81 15Zm-.31 1.81 2.69 2.69H4.5v-2.69ZM9 19.19l-1.565-1.565 8.47-8.47 1.564 1.565L9 19.19Z"
                fill={color}
            />
        </svg>
    );
}
