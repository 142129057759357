export const formatDate = (date:string) => {
    return date.split('T')[0].replace(/-/g, '/');
};

export const formatDateFromEpoch = (epoch:number | string) => {
    const date = new Date(Number(epoch));
    return date.toLocaleDateString('en-GB'); // 'en-GB' gives "DD/MM/YYYY" format
};

export const formatTime = (date:string) => {
    const timeSplit = date.split('T')[1].split(':');
    return `${timeSplit[0]}:${timeSplit[1]}`;
};